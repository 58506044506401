import React, { useState } from 'react';

type ShowNotification = React.Dispatch<React.SetStateAction<boolean>>;
type CountProviderProps = { children: React.ReactNode };

const NotificationContext = React.createContext<
  { notification: boolean; showNotification: ShowNotification } | undefined
>(undefined);

function NotificationProvider({ children }: CountProviderProps) {
  const [notification, showNotification] = useState<boolean>(false);
  const value = { notification, showNotification };
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}

function useNotificationContext() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
}

export { NotificationProvider, useNotificationContext };
