import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useReneMutation, useValidation, usePrevious } from '../../../../../../hooks';
import { useErrorContext } from '../../../../../../context/error-context';
import { useUpdatePopUpContext } from '../../../../../../context/update-message-context';
import { validations } from './validations';
import { ApolloError } from '@apollo/client';
import { UPSERT_OWNABLE_ASSET_MUTATION } from '../../../../../../global/gql/mutations';
import { OwnableAssetData, AttributesData, Dispatcher, Refetch } from '../../../../../../global/interfaces';
import { compareAttributeArrays, handleError } from '../../../../../../utils';
import Icon from '../../../../../../components/Icon/Icon';
import Modal from '../../../../../../components/modal/modal';
import Attribute from './attribute/attribute';
import NewAttributeModal from '../../../../../../components/modal/new-attribute-modal/new-attribute-modal';

import './attributes.scss';

const Attributes = ({
  attributes,
  updatedAt,
  ownableAssetId,
  isUserAllowedToUpsert,
  setOpenMobileMenu,
  refetch,
}: {
  attributes: AttributesData[] | undefined;
  updatedAt: string;
  ownableAssetId: string;
  isUserAllowedToUpsert: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
}) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [attributeList, setAttributeList] = useState<AttributesData[] | []>([]);
  const [prevAttributesList, setPrevAttributesList] = useState<AttributesData[] | []>();
  const [updated, setUpdated] = useState<string>(updatedAt);

  const lockUpdate = useRef<boolean>(false);

  const [upsertOwnableAsset] = useReneMutation(UPSERT_OWNABLE_ASSET_MUTATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const { setError } = useErrorContext();
  const previousAttributesState = usePrevious(attributes);

  if (attributes && !prevAttributesList && attributes !== prevAttributesList) {
    setPrevAttributesList(attributes);
    setAttributeList(attributes);
  }

  const hasAttributesChanged = useMemo(
    () => !compareAttributeArrays(attributeList, previousAttributesState),
    [attributeList, previousAttributesState],
  );

  const handleSave = useCallback(() => {
    if (isFormInvalid({ attributes: attributeList }) || !hasAttributesChanged) return;

    lockUpdate.current = true;

    const attributesToSave = attributeList.map((attribute) => {
      const { __typename, ...newObject } = attribute;
      return newObject;
    });
    let variables: { attributes: AttributesData[]; ownableAssetId: string; updatedAt: string } = {
      attributes: attributesToSave,
      ownableAssetId,
      updatedAt: updated,
    };

    upsertOwnableAsset({ variables })
      .then(({ data, error }: { data: any; error: ApolloError }) => {
        if (error) throw error;
        setUpdated(data.UpsertOwnableAsset.updatedAt);
        if (data) showUpdatePopUpMessage('Changes saved successfully');
      })
      .catch((err: ApolloError) => {
        handleError(err, setError);
      })
      .finally(() => {
        lockUpdate.current = false;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isFormInvalid,
    attributeList,
    hasAttributesChanged,
    ownableAssetId,
    updatedAt,
    upsertOwnableAsset,
    showUpdatePopUpMessage,
    setError,
  ]);

  useEffect(() => {
    // check if form is valid after attribute deletion, since it wont reset/update validation errors
    if (isFormInvalid({ attributes: attributeList })) return;

    let autosaveTimeout: NodeJS.Timeout | undefined;
    if (!lockUpdate.current) {
      autosaveTimeout = setTimeout(() => {
        handleSave();
      }, 700);
    } else {
      clearTimeout(autosaveTimeout);
    }
    return () => {
      // TODO: add logic for update when switching tabs/pages [PTL-337]
      clearTimeout(autosaveTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeList]);

  return (
    <div className="attributes">
      <div className="attributes__heading">
        <div>
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
          <h1 className="rainbow-btn-text">ATTRIBUTE</h1>
        </div>
        {isUserAllowedToUpsert && (
          <div>
            <button className="primary-btn" onClick={() => setIsModalOpen(true)}>
              <Icon name="plus" />
              Add
            </button>
          </div>
        )}
      </div>
      <div className="attributes__main">
        {attributeList?.length
          ? attributeList?.map((attribute, i) => (
              <Attribute
                key={attribute.traitType}
                attribute={attribute}
                setAttributeList={setAttributeList}
                isUserAllowedToUpsert={isUserAllowedToUpsert}
                error={errors?.attributes[i]}
              />
            ))
          : null}
      </div>
      <Modal isOpen={isModalOpen}>
        <NewAttributeModal
          setCloseModal={setIsModalOpen}
          addAttribute={setAttributeList}
          attributeList={attributeList}
        />
      </Modal>
    </div>
  );
};

export default Attributes;
