import { useEffect, useRef, useState } from 'react';
import { Dispatcher, UserData } from '../../../../../global/interfaces';
import { useMutation } from '@apollo/client';
import { CONNECT_NFT, INITIATE_NFT_CONNECT } from '../../../../../global/gql/mutations';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider } from 'ethers';
import { GET_USER_NFTS } from '../../../../../global/gql/queries';
import { shortenAddress } from '../../../../../utils';
import { useLazyReneQuery } from '../../../../../hooks';
import { SiweMessage } from 'siwe';
import Icon from '../../../../../components/Icon/Icon';
import Modal from '../../../../../components/modal/modal';
import StakingModal from '../../../../../components/modal/staking-modal/staking-modal';

import epic from './images/epic.png';
import borderless from './images/borderless.png';
import legendary from './images/legendary.png';
import LoadingFallback from '../../../../../components/loading-fallback/loading-fallback';
import './nft-utility.scss';

const tier = {
  EPIC: {
    image: epic,
    className: 'nft-utility__nft-staking_nfts_nft_heading--epic',
  },
  BORDERLESS: {
    image: borderless,
    className: 'nft-utility__nft-staking_nfts_nft_heading--borderless',
  },
  LEGENDARY: {
    image: legendary,
    className: 'nft-utility__nft-staking_nfts_nft_heading--legendary',
  },
};

const ConnectWallet = ({ openWallet }: { openWallet: (options?: any | undefined) => Promise<void> }) => {
  return (
    <div className="nft-utility__wallet-connect">
      <h2>Activate Founder's Key NFT Benefits</h2>
      <p>
        Connect your wallet & stake your ReneVerse Founder's Key NFT to access perks like Airdrop Multipliers, increased
        revenue share, and more upcoming features.
      </p>
      <button className="primary-btn" onClick={() => openWallet()}>
        Connect Wallet
      </button>
    </div>
  );
};

const Nft = ({
  nft,
  setNft,
  someNftAlreadyStaked,
}: {
  nft: {
    nftId: number;
    rarity: 'EPIC' | 'BORDERLESS' | 'LEGENDARY';
    multiplier: string;
    isStaked: boolean;
  };
  someNftAlreadyStaked: boolean;
  setNft: Dispatcher<{ nftId: number; action: 'proceed' | 'stop' } | undefined>;
}) => {
  return (
    <div className="nft-utility__nft-staking_nfts_nft">
      <div className="nft-utility__nft-staking_nfts_nft_heading">
        <img src={tier[nft.rarity].image} alt="nft tier" />
        <div>
          <p>Rarity</p>
          <h3 className={tier[nft.rarity].className}>{nft.rarity}</h3>
        </div>
      </div>
      <div className="nft-utility__nft-staking_nfts_nft_multi">
        <p>Rewards Multiplier</p>
        <p>{nft.multiplier}</p>
      </div>
      {nft.isStaked ? (
        <div className="nft-utility__nft-staking_nfts_nft_staked">
          <p>Key Staked</p>
          <Icon name="circle-check" />
        </div>
      ) : null}
      <button
        className="primary-btn"
        onClick={() => setNft({ nftId: nft.nftId, action: nft.isStaked ? 'stop' : 'proceed' })}
        disabled={!nft.isStaked && someNftAlreadyStaked}
      >
        {nft.isStaked ? 'Stop Staking' : 'Stake Key'}
      </button>
    </div>
  );
};

const NftStaking = ({ address, chainId }: { address: `0x${string}` | undefined; chainId: number | undefined }) => {
  const wasCalled = useRef(false);
  const { disconnect } = useDisconnect();
  const [initialAddress] = useState(address);
  const { walletProvider } = useWeb3ModalProvider();
  const [nft, setNft] = useState<{ nftId: number; action: 'proceed' | 'stop' } | undefined>();
  const [loading, setLoading] = useState(false);
  const [getNfts, { data: userNfts, refetch }] = useLazyReneQuery<{ User: UserData }>(GET_USER_NFTS, {
    fetchPolicy: 'network-only',
  });
  const [initiateNftConnect, { data }] = useMutation<{ InitiateConnectNft: { nonce: string } }>(INITIATE_NFT_CONNECT);
  const [connectNft, { data: isConnected }] = useMutation(CONNECT_NFT);

  if (address !== initialAddress) {
    disconnect();
  }

  useEffect(() => {
    const handleSigning = async () => {
      if (data?.InitiateConnectNft?.nonce && walletProvider && !userNfts && !isConnected) {
        try {
          setLoading(true);
          const ethersProvider = new BrowserProvider(walletProvider);
          const signer = await ethersProvider.getSigner();
          const siweMessage = new SiweMessage({
            domain: window.location.host,
            address,
            statement: 'Sign in with Ethereum to the app.',
            nonce: data.InitiateConnectNft.nonce,
            uri: window.location.origin,
            scheme: window.location.protocol.slice(0, -1),
            version: '1',
            chainId: chainId,
          });
          const message = siweMessage.prepareMessage();
          const signature = await signer.signMessage(message);
          connectNft({
            variables: {
              message,
              signature,
            },
          }).then(() => {
            getNfts().then(() => {
              setLoading(false);
            });
          });
        } catch (error) {
          if (error instanceof Error) {
            if (error.message.includes('user rejected action')) {
              disconnect();
            }
          } else {
            console.log('An unknown error occurred', error);
          }
        }
      }
    };

    handleSigning();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.InitiateConnectNft?.nonce, walletProvider]);

  useEffect(() => {
    if (wasCalled.current) return;
    wasCalled.current = true;
    if (address && !data) {
      initiateNftConnect();
    }
  }, [address, data, initiateNftConnect]);

  return (
    <div className="nft-utility__nft-staking">
      <div className="nft-utility__nft-staking_heading">
        <p>
          <Icon name="wallet" />
          {shortenAddress(address || '')}
        </p>
        <button className="secondary-btn" onClick={() => disconnect()}>
          Disconnect
        </button>
      </div>
      <p>
        You can only stake one Founder's Key at a time. A Founder's Key can be stacked with a Point Booster for an
        increased Point Multiplier. <br />
        <br /> A multiplier is active as long as your NFT is staked, and all Points earned with a multiplier will be
        properly accounted for up until the point the NFT is unstaked. <br /> <br /> Disclaimer: If you unstake your NFT
        before ReneVerse receives information about a completed Quest from other services such as X, you may not receive
        the multiplier for those Points.
      </p>
      <div className="nft-utility__nft-staking_nfts">
        {loading ? <LoadingFallback /> : null}
        {userNfts && !userNfts.User.nfts?.length ? (
          <div className="nft-utility__nft-staking_nfts--no-key">
            <Icon name="bucket" size={80} />
            <p>No Founder's Key Found</p>
          </div>
        ) : null}
        <div className="nft-utility__nft-staking_nfts_list">
          {userNfts && userNfts.User.nfts.length
            ? userNfts.User.nfts.map((nft) => (
                <Nft someNftAlreadyStaked={!!userNfts.User?.stakedNft} key={nft.nftId} nft={nft} setNft={setNft} />
              ))
            : null}
        </div>
      </div>
      <Modal isOpen={!!nft}>
        <StakingModal
          nftId={nft?.nftId as number}
          action={nft?.action as 'proceed' | 'stop'}
          refetch={refetch}
          closeModal={() => setNft(undefined)}
        />
      </Modal>
    </div>
  );
};
const NftUtility = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  return (
    <div className="nft-utility">
      {!isConnected ? (
        <ConnectWallet openWallet={open} />
      ) : (
        <NftStaking key={address} chainId={chainId} address={address} />
      )}
    </div>
  );
};

export default NftUtility;
