import ReactQuill from 'react-quill';
import { Dispatcher } from '../../global/interfaces';

import './reach-text.scss';
import 'react-quill/dist/quill.snow.css';

const ReachText = ({
  reachText,
  error,
  readOnly = false,
  setReachText,
}: {
  reachText: string;
  error: string;
  readOnly?: boolean;
  setReachText: Dispatcher<string>;
}) => {
  return (
    <div className="reach-text">
      <label>On Interaction Message</label>
      <ReactQuill theme="snow" value={reachText} onChange={setReachText} readOnly={readOnly} />
      <p className="error-msg">{error}</p>
    </div>
  );
};

export default ReachText;
