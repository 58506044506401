import { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useErrorContext } from '../../../context/error-context';
import { handleError } from '../../../utils';
import { GAME_AUTHORIZATION_SUBSCRIPTION } from '../../../global/gql/subscriptions';
import { Dispatcher, Event, GameAuthorization } from '../../../global/interfaces';
import { GAME_AUTHORIZATION_QUERY } from '../../../global/gql/queries';
import { useSubscriptionClient } from '../../../hooks/useSubscriptionsClient';
import { useReneQuery } from '../../../hooks/useReneQuery';

interface Props {
  userId: string | undefined;
  setGameAuthorizedNotificationNumber: Dispatcher<number>;
  setSelectedGameForAction: Dispatcher<{ authId: string; gameId: string } | undefined>;
}

interface UnAuthorizedGames {
  authId: string;
  gameId: string;
  name: string;
}

const GameAuthorizationNotification: React.FC<Props> = ({
  userId,
  setSelectedGameForAction,
  setGameAuthorizedNotificationNumber,
}) => {
  const { setError } = useErrorContext();
  const { subscriptionClient, setConnectionInit } = useSubscriptionClient();
  const [authorizationGames, setAuthorizationGames] = useState<UnAuthorizedGames[] | []>([]);

  useReneQuery(GAME_AUTHORIZATION_QUERY, {
    onCompleted: (data: { GameConnectAuth: GameAuthorization[] }) => {
      const authorizedGames = data?.GameConnectAuth.reduce(
        (acc: { authId: string; gameId: string; name: string }[], game) => {
          if (!game.authorized) {
            acc.push({
              authId: game.authId,
              gameId: game.gameId,
              name: game.name,
            });
          }
          return acc;
        },
        [],
      );
      if (authorizedGames) setAuthorizationGames(authorizedGames);
    },
  });

  useEffect(() => {
    if (!window.gameAuthorizationSubscription && userId && subscriptionClient) {
      window.gameAuthorizationSubscription = subscriptionClient
        .subscribe({
          query: GAME_AUTHORIZATION_SUBSCRIPTION,
          variables: {
            userId,
          },
        })
        .subscribe({
          next({ data }: { data: { OnGameConnectAuthUpdate: GameAuthorization } }) {
            setAuthorizationGames((prev) => [
              ...prev,
              {
                authId: data.OnGameConnectAuthUpdate.authId,
                gameId: data.OnGameConnectAuthUpdate.gameId,
                name: data.OnGameConnectAuthUpdate.name,
              },
            ]);
          },
          error(err) {
            if (err.errors?.some((e: ApolloError) => e.message === 'Connection closed')) {
              console.warn('Authorization subscription connection closed unexpectedly, reconnecting...');
              setConnectionInit(true);
            } else {
              handleError(err, setError, 'Authorization subscription error');
            }
          },
        });
    }
  }, [setError, setConnectionInit, subscriptionClient, userId]);

  useEffect(() => {
    setGameAuthorizedNotificationNumber(authorizationGames.length);
  }, [authorizationGames.length, setGameAuthorizedNotificationNumber]);

  const handleSelectGameToAuthorize = (e: Event['Div']) => {
    const selectedGameIndex = authorizationGames.findIndex((proj) => proj.authId === e.currentTarget.id);
    setAuthorizationGames(authorizationGames.filter((game) => game.authId !== e.currentTarget.id));
    setSelectedGameForAction(authorizationGames[selectedGameIndex]);
  };

  return (
    <>
      {authorizationGames.map((authorizationGame) => (
        <div
          key={authorizationGame.authId}
          id={authorizationGame.authId}
          className="game_authorization_notifications"
          onClick={handleSelectGameToAuthorize}
        >
          <button type="button">
            <p>
              <span className="rainbow-btn-text">{`${authorizationGame.name}`}</span> authorization requested
            </p>
          </button>
        </div>
      ))}
    </>
  );
};

export default GameAuthorizationNotification;
