import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ADD_COLLECTION_TO_GAME_MUTATION, UPSERT_COLLECTION_MUTATION } from '../../../global/gql/mutations';
import { GET_ORG_BRANDS } from '../../../global/gql/queries';
import { Brand, CollectionData, Event, OrganizationData } from '../../../global/interfaces';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { validations } from './validations';
import { BRANDS, COLLECTIONS, ORG, SETTINGS } from '../../../global/routes';
import { AssetType, CrossGameState } from '../../../global/consts';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import FileUpload from '../../file-upload/file-upload';
// import CrossGameActions from '../../cross-game-actions/cross-game-actions';
import placeholder from '../../../global/images/avatar.webp';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import './new-collection-modal.scss';

interface Props {
  gameId?: string;
  type: AssetType;
  callback?: () => void;
  setCloseModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const NewCollectionModal: React.FC<Props> = ({ gameId, type, callback, setCloseModal }) => {
  const uploadFile = useFileUpload();
  const navigate = useNavigate();
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<{
    name: string;
    description: string;
    file?: File | undefined;
    status: CrossGameState | '';
    type: AssetType;
    brandId: string;
  }>({
    name: '',
    description: '',
    file: undefined,
    status: '',
    type: type,
    brandId: '',
  });
  const location = useLocation();

  const { data: brands, loading: loadingBrands } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_BRANDS);

  const [addCollectionToGame] = useReneMutation(ADD_COLLECTION_TO_GAME_MUTATION);

  const [upsertCollection] = useReneMutation(UPSERT_COLLECTION_MUTATION, {
    onCompleted(data: { UpsertCollection: CollectionData }) {
      if (form?.file) {
        uploadFile(data.UpsertCollection.image.uploadUrl, form.file).finally(() => {
          if (gameId) {
            addCollectionToGame({
              variables: {
                gameId,
                collectionId: data.UpsertCollection.collectionId,
              },
            }).then(() => {
              handleFinish(data.UpsertCollection.collectionId);
            });
          } else {
            handleFinish(data.UpsertCollection.collectionId);
          }
        });
      }
    },
  });

  const handleFinish = (collectionId: string) => {
    if (callback) {
      callback();
    }
    if (location.pathname === '/org') {
      navigate(`${COLLECTIONS}/${form.type}/${collectionId}`);
    }
    setLoading(false);
    handleModal();
  };

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // const handleTypeChange = (e: Event['Button']) => {
  //   const type = e.currentTarget.name as AssetType;
  //   setForm((prev) => ({ ...prev, type }));
  // };

  // const handleStatusChange = (e: Event['Button']) => {
  //   const target = e.currentTarget.name as CrossGameState;
  //   setForm((prev) => ({ ...prev, status: target }));
  // };

  const handleUpsertCollection = () => {
    if (isFormInvalid(form)) return;
    setLoading(true);
    let variables: {
      brandId?: string;
      name: string;
      description: string;
      type: string;
      image: { extension: string | undefined };
    } = {
      name: form?.name,
      description: form?.description,
      // crossGameState: form?.status,
      type: form.type.toUpperCase(),
      image: {
        extension: form?.file?.type.split('/')[1],
      },
    };

    variables =
      form.type === AssetType.BRANDED
        ? { ...variables, brandId: brands?.Organization.brands.items[parseInt(form.brandId)].brandId }
        : variables;

    upsertCollection({
      variables,
    });
  };

  const brandedItems = () => {
    if (form.type === AssetType.OWNABLE) return;
    if (loadingBrands) return <LoadingFallback />;
    return brands?.Organization.brands.items?.length ? (
      <div className="new-collection-modal__type_brand">
        <Select
          label="Brand"
          className="new-collection-modal__type_brand_select"
          value={form.brandId}
          placeholder="Select brand"
          options={{ ...brands?.Organization.brands.items }}
          changeHandler={(index) => {
            setForm((prev) => ({ ...prev, brandId: index }));
          }}
          showSelectedValueFn={(val) => val.name}
          showListValueFn={(val: Brand) => (
            <div key={val.brandId} className="new-collection-modal__type_brand_selected">
              <img src={val.image.url || placeholder} alt="brand" />
              <p>{val.name}</p>
            </div>
          )}
        />
        <p className="error-msg">{errors?.brandId}</p>
      </div>
    ) : (
      <p className="new-collection-modal__type_brand_no-brand">
        Please <Link to={`/${ORG}/${SETTINGS}/${BRANDS}`}>create</Link> a brand.
      </p>
    );
  };

  return (
    <div className="new-collection-modal">
      <div className="new-collection-modal__heading">
        <h2>New {gameId ? ' Game ' : ''} Collection</h2>
        <button type="button" onClick={handleModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter collection name"
        handleInput={handleFormChange}
        value={form?.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form?.description}
        handleInput={handleFormChange}
        placeholder="Enter collection description"
        showCounter
        errorMessage={errors?.description}
        maxLength={100}
      />
      <FileUpload
        setFiles={setFile}
        description="Upload Collection Picture"
        errorMessage={errors?.file}
        handleChange={handleFileChange}
      />
      {/* <CrossGameActions status={form.status} setStatus={handleStatusChange} errorMessage={errors?.status} /> */}
      {/* <div className="new-collection-modal__type">
        <label>Collection type</label>
        <div>
          {type && type === AssetType.BRANDED ? null : (
            <button
              name={AssetType.OWNABLE}
              className={`secondary-btn ${form.type === AssetType.OWNABLE ? 'selected' : ''}`}
              onClick={handleTypeChange}
            >
              <p>{AssetType.OWNABLE}</p>
            </button>
          )}
          {type && type === AssetType.OWNABLE ? null : (
            <button
              name={AssetType.BRANDED}
              className={`secondary-btn ${form.type === AssetType.BRANDED ? 'selected' : ''}`}
              onClick={handleTypeChange}
            >
              <p>{AssetType.BRANDED}</p>
            </button>
          )}
        </div>
      </div> */}
      {brandedItems()}
      <div className="new-collection-modal__actions">
        <button type="button" className="secondary-btn" onClick={handleModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleUpsertCollection} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewCollectionModal;
