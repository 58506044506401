import { GameData } from '../../global/interfaces';
import { CDN_CHAIN_LOGOS } from '../../global/routes';
import Icon from '../Icon/Icon';
import Tooltip from '../tooltip/tooltip';
import './chain-logo.scss';

const ChainLogo = ({ chain }: { chain: GameData['chain'] | undefined | null }) => {
  if (chain === undefined) {
    return null;
  }
  return (
    <Tooltip value={chain === null ? 'Game is not on blockchain' : chain.name}>
      <div className="chain-logo">
        <p>Chain</p>
        {chain !== null ? (
          <img src={`${CDN_CHAIN_LOGOS}/${chain.chainSymbol?.toLowerCase()}.svg`} alt="chain" />
        ) : (
          <Icon name="web2" />
        )}
      </div>
    </Tooltip>
  );
};

export default ChainLogo;
