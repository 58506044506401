import { useState } from 'react';
import { AssetData } from '../../global/interfaces';
import { TransactionItemData } from '../../global/interfaces';
import AssetSearchList from '../asset/asset-search-list/asset-search-list';
import Icon from '../Icon/Icon';
import TransactionsList from '../transactions-list/transactions-list';
import './wallet.scss';

const Wallet = ({
  assets,
  gameId,
  walletAddress,
  transactions,
}: {
  gameId?: string;
  assets: AssetData[];
  walletAddress: string;
  transactions: TransactionItemData[];
}) => {
  const [subMenu, setSubMenu] = useState('assets');

  const renderSelectedMenuItem = () => {
    switch (subMenu) {
      case 'assets':
        return <AssetSearchList assetList={assets} gameId={gameId} />;
      case 'activity':
        return <TransactionsList transactions={transactions} />;
      default:
        break;
    }
  };

  const copyAddress = async () => {
    await navigator.clipboard.writeText(walletAddress);
  };

  return (
    <div className="wallet">
      <div className="wallet__address">
        <div className="wallet__address_hex">
          <p>Address</p>
          <div>
            <p>{walletAddress}</p>
            <button className="tertiary-btn" type="button" onClick={copyAddress}>
              <Icon name="copy" size={24} />
            </button>
          </div>
        </div>
      </div>
      <div className="wallet__navigation_tabs">
        <button
          type="button"
          className={`${subMenu === 'assets' ? 'active' : ''}`}
          onClick={() => setSubMenu('assets')}
        >
          <div>
            <p>Assets</p>
          </div>
        </button>
        <button
          type="button"
          className={`${subMenu === 'activity' ? 'active' : ''}`}
          onClick={() => setSubMenu('activity')}
        >
          <div>
            <p>Activity</p>
          </div>
        </button>
      </div>
      <section className="wallet__main">{renderSelectedMenuItem()}</section>
    </div>
  );
};

export default Wallet;
