import { isAfter, isToday, differenceInYears, addYears, isBefore, isPast } from 'date-fns';
import { commonValidations } from '../../../../global/validations';
import { CampaignGoal } from '../../../../global/consts';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  file: (form: { [k: string]: any }, key: 'file') => {
    if (!form.campaignImage) {
      return commonValidations.fileImage()(form, key);
    }
  },
  age: commonValidations.nonEmptyList(),
  gender: commonValidations.nonEmptyList(),
  campaignGoal: commonValidations.requiredTrimmed(),
  startDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    if (form.isEdit) return;
    const startDate = new Date(field);
    const endDate = new Date(form.endDate);
    const maxFutureDate = addYears(new Date(), 5);
    if (isAfter(startDate, endDate)) return 'Start date cannot be after the end date';
    if (!isToday(startDate) && !isAfter(startDate, new Date())) return 'The date cannot be in the past';
    if (!isBefore(startDate, maxFutureDate)) return 'Start date cannot be more then 5 years in the future';
  },
  endDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    const endDate = new Date(field);
    if (!isToday(endDate) && !isAfter(endDate, new Date())) return 'The End date needs to be in the future';
    if (differenceInYears(endDate, new Date(form.startDate)) > 0) return 'Date interval cannot be more than a year';
  },
  editStartDate: (value: string) => {
    if (isPast(new Date(value))) return 'The Start date needs to be in the future';
  },
  targetedRegion: commonValidations.requiredTrimmed(),
  targetedGenres: commonValidations.nonEmptyList(),
  brand: (form: { campaignGoal: CampaignGoal }, _key: string, brand: string) => {
    if (form.campaignGoal === CampaignGoal.BRAND_AWARENESS && !brand) return 'This field is required';
  },
};
