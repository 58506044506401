import { AssetData, Event } from '../../../global/interfaces';
import AssetCard from '../asset-card/asset-card';
import './assets-list.scss';

interface Props {
  assetsList: AssetData[];
  handleSelectAsset: (e: Event['Button']) => void;
}

const AssetsList: React.FC<Props> = ({ assetsList, handleSelectAsset }) => {
  if (!assetsList?.length) return null;
  return (
    <div className="assets-list">
      {assetsList.map((asset: AssetData) => {
        return (
          <button key={asset?.assetId} id={asset.assetId} type="button" onClick={handleSelectAsset}>
            <AssetCard image={asset?.metadata.image} title={asset?.metadata.name} />
          </button>
        );
      })}
    </div>
  );
};

export default AssetsList;
