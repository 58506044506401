import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyReneQuery } from '../../../../../hooks';
import { GET_OWNABLE_ASSETS_QUERY } from '../../../../../global/gql/queries';
import { OwnableAssetData, Dispatcher, Event, TransactionItemData } from '../../../../../global/interfaces';
import { useNotificationContext } from '../../../../../context/notification-context';
import Icon from '../../../../../components/Icon/Icon';
import Modal from '../../../../../components/modal/modal';
import AssetCard from '../../../../../components/asset/asset-card/asset-card';
import AssetModal from '../../../../../components/modal/asset-modal/asset-modal';
import LoadingFallback from '../../../../../components/loading-fallback/loading-fallback';

import avatar from '../../../../../global/images/avatar.webp';

import './ownable-asset-owners-tab.scss';

const OwnableAssetOwnersTab = ({ setOpenMobileMenu }: { setOpenMobileMenu: Dispatcher<boolean> }) => {
  const params = useParams();
  const { notification, showNotification } = useNotificationContext();
  const [isAssetsModalOpen, setAssetsModalOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string>();

  const [getOwnableAsset, { data, loading }] = useLazyReneQuery<{
    OwnableAssets: { items: OwnableAssetData[] };
  }>(GET_OWNABLE_ASSETS_QUERY, { fetchPolicy: 'network-only' });

  const getAssets = useCallback(() => {
    getOwnableAsset({ variables: { ownableAssetId: params.assetId, status: 'SUCCESS', type: 'MINT' } });
  }, [getOwnableAsset, params.assetId]);

  useEffect(() => {
    getAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (notification) {
      getAssets();
      showNotification(false);
    }
  }, [notification, showNotification, getAssets]);

  const handleAssetSelect = (e: Event['Button']) => {
    setSelectedAsset(e.currentTarget.name);
    setAssetsModalOpen(true);
  };

  const mintedTransactions: TransactionItemData[] | undefined = data?.OwnableAssets?.items[0]?.transactions?.items;

  return (
    <div className="ownable-asset-owners-tab">
      <div className="ownable-asset-owners-tab__heading">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h1 className="rainbow-btn-text">ASSETS</h1>
      </div>
      {!loading ? (
        <div className="ownable-asset-owners-tab__main">
          {mintedTransactions?.map((transaction, i) => (
            <button key={transaction?.asset.assetId} name={transaction?.asset.assetId} onClick={handleAssetSelect}>
              <AssetCard image={transaction?.asset?.metadata.image} title={transaction?.asset?.metadata.name}>
                <div className="user">
                  <img src={transaction.receiver.imageUrl || avatar} alt="user" />
                  <p>{transaction.receiver.name}</p>
                </div>
              </AssetCard>
            </button>
          ))}
        </div>
      ) : (
        <LoadingFallback />
      )}
      <Modal isOpen={isAssetsModalOpen}>
        <AssetModal assetId={selectedAsset as string} closeModal={() => setAssetsModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OwnableAssetOwnersTab;
