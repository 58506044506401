import { useState, useEffect } from 'react';
import { useReneMutation } from '../../../hooks';
import { ApolloError } from '@apollo/client';
import { handleError } from '../../../utils';
import { useErrorContext } from '../../../context/error-context';
import { READ_NOTIFICATION_MUTATION } from '../../../global/gql/mutations';
import { GENERAL_NOTIFICATION_SUBSCRIPTION } from '../../../global/gql/subscriptions';
import { Dispatcher, GeneralNotificationData, UserData } from '../../../global/interfaces';
import { useSubscriptionClient } from '../../../hooks/useSubscriptionsClient';
import { useNotificationContext } from '../../../context/notification-context';
import { NotificationType, TransactionType } from '../../../global/consts';
import Icon from '../../Icon/Icon';

import './general-notifications.scss';

const notificationType = {
  ERROR: {
    className: '_error',
    label: 'error',
  },
  INFO: {
    className: '_success',
    label: 'success',
  },
};

interface Props {
  user: UserData | undefined;
  setAssetMintedId: Dispatcher<string | undefined>;
  setNotificationId: Dispatcher<string | undefined>;
  setGeneralNotificationNumber: Dispatcher<number>;
}

interface OnGeneralNotification {
  data: {
    OnGeneralNotification: GeneralNotificationData;
  };
}

const GeneralNotification: React.FC<Props> = ({
  user,
  setNotificationId,
  setAssetMintedId,
  setGeneralNotificationNumber,
}) => {
  const { subscriptionClient, setConnectionInit } = useSubscriptionClient();
  const { setError } = useErrorContext();
  const { showNotification } = useNotificationContext();
  const [generalNotifications, setGeneralNotifications] = useState<GeneralNotificationData[] | []>([]);
  const [prevNotification, setPrevNotification] = useState<GeneralNotificationData[] | []>([]);

  const [readNotificationMutation] = useReneMutation(READ_NOTIFICATION_MUTATION);

  const getMintedAssetData = (data: { key: string; value: string }[], key: string) => {
    return data.find((item) => item.key === key)?.value;
  };

  if (user?.notifications && user.notifications !== prevNotification) {
    setGeneralNotifications(user.notifications);
    setPrevNotification(user.notifications);
  }

  useEffect(() => {
    if (!window.generalNotificationsSubscription && user && user.userId && subscriptionClient) {
      window.generalNotificationsSubscription = subscriptionClient
        .subscribe({
          query: GENERAL_NOTIFICATION_SUBSCRIPTION,
          variables: {
            userId: user.userId,
          },
        })
        .subscribe({
          next(notification: OnGeneralNotification) {
            setGeneralNotifications((prev) => {
              return [...prev, notification.data.OnGeneralNotification];
            });
            showNotification(true);
          },
          error(err) {
            if (err.errors?.some((e: ApolloError) => e.message === 'Connection closed')) {
              console.warn('General notification subscription connection closed unexpectedly, reconnecting...');
              setConnectionInit(true);
            } else {
              handleError(err, setError, 'General notification subscription error');
            }
          },
        });
    }
  }, [setError, setConnectionInit, showNotification, subscriptionClient, user]);

  useEffect(() => {
    setGeneralNotificationNumber(generalNotifications.length);
  }, [setGeneralNotificationNumber, generalNotifications.length]);

  const isAssetTransferToCurrentUser = (transactionType: string, receiverId: string) => {
    return transactionType === TransactionType.TRANSFER && receiverId === user?.userId;
  };

  const openNotification = (
    type: NotificationType,
    transactionType: string,
    assetId: string,
    requestId: string,
    notificationId: string,
    receiverId: string,
  ) => {
    if (
      type === NotificationType.INFO &&
      (isAssetTransferToCurrentUser(transactionType, receiverId) || transactionType === TransactionType.MINT)
    ) {
      setAssetMintedId(assetId);
      setNotificationId(notificationId);
    }
    readNotificationMutation({
      variables: {
        notificationId,
      },
    });
    setGeneralNotifications(
      generalNotifications.filter((notification) => getMintedAssetData(notification.meta, 'requestId') !== requestId),
    );
  };
  return (
    <>
      {generalNotifications.map((notification, i) => {
        const meta = notification.meta.reduce(
          (acc, current) => {
            if (
              current.key === 'assetId' ||
              current.key === 'transactionType' ||
              current.key === 'requestId' ||
              current.key === 'receiverUserId'
            ) {
              acc[current.key] = current.value;
            }
            return acc;
          },
          { assetId: '', transactionType: '', requestId: '', receiverUserId: '' },
        );

        return (
          <button
            className="general_notifications"
            onClick={() =>
              openNotification(
                notification.type,
                meta.transactionType,
                meta.assetId,
                meta.requestId,
                notification.notificationId,
                meta.receiverUserId,
              )
            }
            key={i}
          >
            <div className="general_notifications__message">
              <div>
                <Icon name="transfer" size={24} />
                <p>{notification?.message}</p>
              </div>
            </div>
            <div className={`general_notifications__status${notificationType[notification.type].className}`}>
              <p>{notificationType[notification.type].label}</p>
            </div>
          </button>
        );
      })}
    </>
  );
};

export default GeneralNotification;
