export const validations = {
  attributes: (form: { [key: string]: any }, key: string) => {
    const attrErrors: string[] = form?.[key].map((attr: any, index: number) => {
      const { displayType, values } = attr;
      const [from, to, value] = values;
      const attrValidations: any = {
        value: {
          number: () => {
            if (!from) return 'From field is required';
            if (!to) return 'To field is required';
            if (value && (+value < +from || +value > +to)) return 'Default value must be between from and to';
          },
          date: () => {
            if (!from) return 'From field is required';
            if (!to) return 'To field is required';
            if (value && (value < from || value > to)) return 'Default value must be between from and to';
          },
          string: () => {
            if (!values.length) return 'You must add at least one value';
          },
          boost_percentage: () => {
            if (from === 'NaN') return 'Field is required';
          },
        },
      };

      return attrValidations.value[displayType]();
    });

    return attrErrors.some((err) => err) ? attrErrors : undefined;
  },
};
