import { useState, useEffect } from 'react';

export function useDebouncedValue(data: any, interval = 2000) {
  const [value, setValue] = useState(data);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handler = setTimeout(() => {
        setValue(data);
      }, interval);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [data, interval]);

  return value;
}
