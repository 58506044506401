import { commonValidations } from '../../../../global/validations';

export const validations = {
  recipient: (form: { [key: string]: any }, key: string) => {
    if (!form[key] && !form.gameRecipient) {
      return 'This field is required';
    }
  },
  name: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  assetsToMint: (form: { [key: string]: any }, key: string) => {
    if (form?.[key] === undefined) return 'This field is required';
    if (form?.[key] === 0) return 'You must provide valid number of assets to mint';
    if (form[key] > 20) return "Can't mint more than 20 assets";
    if (form[key] > form.maxMint) return `Can't mint more than ${form.maxMint} assets`;
  },
  attributes: (form: { [key: string]: any }, key: any) => {
    if (!form?.[key].length) return 'You must add at least one attribute to be able to mint';

    const attrErrors: string[] = form?.[key].map((attr: any) => {
      const { displayType, values } = attr;
      const [from, to] = values;

      const attrValidations: any = {
        value: {
          number: () => {
            if (!from || !to) return 'This field is required';
            if (+from > +to) return 'Please provide valid values';
          },
          date: () => {
            if (!from || !to) return 'This field is required';
            if (from > to) return 'Please provide valid values';
          },
          string: () => {
            if (!values.length) return 'This field is required';
          },
          boost_percentage: () => {},
        },
      };

      return attrValidations.value[displayType]();
    });

    return attrErrors.some((err) => err) ? attrErrors : undefined;
  },
};
