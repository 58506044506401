import { useUpdatePopUpContext } from '../../../context/update-message-context';
import Icon from '../../Icon/Icon';
import './update-message.scss';

const UpdateMessage = () => {
  const { updatePopUpMessage, showUpdatePopUpMessage } = useUpdatePopUpContext();

  if (!updatePopUpMessage) return null;
  return (
    <div className="update-message">
      <div>
        <Icon name="circle-check" />
        <p>{updatePopUpMessage}</p>
      </div>
      <button onClick={() => showUpdatePopUpMessage('')}>
        <Icon name="close" />
      </button>
    </div>
  );
};

export default UpdateMessage;
