import { useState } from 'react';
import { useFileUpload, useReneMutation, useValidation } from '../../../hooks';
import { UPSERT_BRAND_MUTATION } from '../../../global/gql/mutations';
import { validations } from './validations';
import { Brand, Event, OrganizationData, Refetch } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import FileUpload from '../../file-upload/file-upload';
import './new-brand-modal.scss';

const NewBrandModal = ({
  refetch,
  setCloseModal,
}: {
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | undefined
  >;
  setCloseModal: () => void;
}) => {
  const uploadFile = useFileUpload();

  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{
    name: string;
    description: string;
    file?: File | undefined;
    website: string;
  }>({
    name: '',
    description: '',
    file: undefined,
    website: '',
  });

  const [addBrand] = useReneMutation(UPSERT_BRAND_MUTATION, {
    onCompleted(data: { UpsertBrand: Brand }) {
      if (form?.file) {
        uploadFile(data.UpsertBrand.image.uploadUrl, form.file).finally(() => {
          refetch();
          setLoading(false);
          setCloseModal();
        });
      }
    },
  });

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const handleAddingNewBrand = () => {
    if (isFormInvalid(form)) return;
    const variables = {
      name: form.name.trim(),
      description: form.description.trim(),
      image: { extension: form?.file?.type.split('/')[1] },
      website: form.website,
    };
    setLoading(true);
    addBrand({ variables });
  };

  return (
    <div className="new-brand-modal">
      <div className="new-brand-modal__heading">
        <h2>New Brand</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter brand name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter brand description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <FileUpload
        setFiles={setFile}
        description="Upload Brand Picture"
        errorMessage={errors?.file}
        handleChange={handleFileChange}
      />
      <Input
        label="Website"
        name="website"
        placeholder="Enter website url"
        handleInput={handleFormChange}
        value={form.website}
        errorMessage={errors?.website}
      />
      <div className="new-brand-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewBrand}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewBrandModal;
