import { commonValidations } from '../../../../global/validations';

export const initiateValidations = {
  email: commonValidations.email(),
};

export const setPasswordValidations = {
  newPassword: commonValidations.password(),
  confirmPassword: (form: { [k: string]: any }, key: string) => {
    if (!form[key]) return 'This field is required';
    if (form[key] !== form.newPassword) return "The passwords don't match";
  },
  otp: commonValidations.requiredTrimmed(),
};
