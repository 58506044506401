import { useState } from 'react';
import { useReneMutation, useValidation } from '../../../../hooks';
import { UPSERT_ORG_MUTATION } from '../../../../global/gql/mutations';
import { Dispatcher, Event, OrganizationData, Refetch } from '../../../../global/interfaces';
import { validations } from './validations';
import { useFileUpload } from '../../../../hooks/useFileUpload';
import { orgTypeOptions } from '../../../../global/consts';
import Icon from '../../../../components/Icon/Icon';
import Input from '../../../../components/input/input';
import Modal from '../../../../components/modal/modal';
import Select from '../../../../components/select/select';
import Spinner from '../../../../components/spinner/spinner';
import Textarea from '../../../../components/textarea/textarea';
import EditableImage from '../../../../components/editable-image/editable-image';

import './organization-settings.scss';

const orgType = {
  GENERAL: orgTypeOptions[0],
  GAME: orgTypeOptions[1],
  ADVERTISEMENT: orgTypeOptions[2],
  CREATOR: orgTypeOptions[3],
};

const initialState = {
  name: '',
  description: '',
  imageUrl: '',
  url: '',
  twitter: '',
  discord: '',
  type: '',
};
interface Props {
  organization: OrganizationData | undefined;
  allowEditOrg: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
  refetch: Refetch<{
    Organization: OrganizationData;
  }>;
}

interface Variables {
  name: string;
  type?: string;
  isActive: boolean;
  orgId: string;
  updatedAt: string;
  data: {
    description: string;
    discord: string;
    twitter: string;
    url: string;
  };
  image?: {
    extension: string;
    fileId: string;
  };
}

const OrganizationSettings: React.FC<Props> = ({ organization, allowEditOrg, refetch, setOpenMobileMenu }) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [form, setForm] = useState(initialState);
  const [confirmModal, setConfirmModal] = useState(false);
  const [previousData, setPreviousData] = useState<OrganizationData>();
  const [file, setFile] = useState<File>();

  const [upsertOrg, { loading }] = useReneMutation(UPSERT_ORG_MUTATION, {
    onCompleted(data: { UpsertOrganization: OrganizationData }) {
      if (file) {
        uploadFile(data.UpsertOrganization.image.uploadUrl, file).finally(() => {
          refetch();
        });
      } else {
        refetch();
      }
    },
  });

  if (organization && organization !== previousData) {
    setPreviousData(organization);
    setForm({
      name: organization.name,
      description: organization.data.description,
      imageUrl: organization?.image?.url || '',
      url: organization.data.url || '',
      twitter: organization.data.twitter || '',
      discord: organization.data.discord || '',
      type: orgType[organization.type as keyof typeof orgType].title,
    });
  }

  //Allow user only to change org type to general. If already general, don't allow change
  const orgOptions = [orgTypeOptions[0]];

  const handleChangeForm = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleChangeType = () => {
    setForm((prev) => ({ ...prev, type: 'General' }));
    setConfirmModal(false);
  };

  const handleUpsertOrt = () => {
    if (isFormInvalid(form)) return;
    let variables: Variables = {
      name: form.name,
      isActive: true,
      orgId: organization?.orgId as string,
      updatedAt: organization?.updatedAt as string,
      data: {
        description: form.description,
        discord: form.discord,
        twitter: form.twitter,
        url: form.url,
      },
    };
    variables =
      orgType[previousData?.type as keyof typeof orgType].title !== form.type
        ? { ...variables, type: 'GENERAL' }
        : variables;
    variables =
      file && organization?.image.fileId
        ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: organization?.image.fileId } }
        : variables;

    upsertOrg({ variables });
  };

  return (
    <div className="organization_settings">
      <div className="organization_settings__title">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h3>Info</h3>
      </div>
      <EditableImage
        label="Picture"
        imageUrl={form.imageUrl}
        alt="organization"
        setFile={setFile}
        disabled={!allowEditOrg}
      />
      <Input
        label="Name"
        name="name"
        placeholder="Enter game name"
        handleInput={handleChangeForm}
        value={form.name}
        errorMessage={errors?.name}
        disabled={!allowEditOrg}
      />
      <Select
        label="Type"
        value={form.type}
        placeholder="Select organization type"
        options={orgOptions}
        changeHandler={() => setConfirmModal(true)}
        showListValueFn={(value) => (
          <div className="select__org-type">
            <p>{value.title}</p>
            <p>{value.description}</p>
          </div>
        )}
        errorMsg={errors?.type}
        disabled={previousData?.type === 'GENERAL' || loading}
        showError
      />
      <Textarea
        label="Description"
        name="description"
        handleInput={handleChangeForm}
        placeholder="Enter collection description"
        showCounter
        maxLength={100}
        value={form.description}
        errorMessage={errors?.description}
        disabled={!allowEditOrg}
      />
      <Input
        label="Url"
        name="url"
        placeholder="Enter your link"
        handleInput={handleChangeForm}
        value={form.url}
        disabled={!allowEditOrg}
      />
      <Input
        label="X"
        name="twitter"
        placeholder="Enter your X account link"
        handleInput={handleChangeForm}
        value={form.twitter}
        errorMessage={errors?.twitter}
        disabled={!allowEditOrg}
      />
      <Input
        label="Discord"
        name="discord"
        placeholder="Enter your Discord account link"
        handleInput={handleChangeForm}
        value={form.discord}
        errorMessage={errors?.discord}
        disabled={!allowEditOrg}
      />
      {allowEditOrg && (
        <button className="primary-btn center-btn" onClick={handleUpsertOrt}>
          {loading ? <Spinner size="sm" /> : 'Update Profile'}
        </button>
      )}
      <Modal isOpen={confirmModal}>
        <ConfirmOrgTypeChange onConfirm={handleChangeType} setCloseModal={() => setConfirmModal(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationSettings;

const ConfirmOrgTypeChange = ({ onConfirm, setCloseModal }: { onConfirm: () => void; setCloseModal: () => void }) => {
  return (
    <div className="confirm-org-type-change">
      <div className="confirm-org-type-change__heading">
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <h2>Change your organization type to General? You cannot switch back later!</h2>
      <div className="confirm-org-type-change__action">
        <button className="secondary-btn" onClick={setCloseModal}>
          Cancel
        </button>
        <button className="primary-btn" onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </div>
  );
};
