import { FC, useEffect, useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { ORG_CREDIT } from '../../../global/routes';
import { ApolloError } from '@apollo/client';
import { BULK_MINT_ASSET } from '../../../global/gql/mutations';
import { GET_GAMES_QUERY, GET_ORG_QUERY } from '../../../global/gql/queries';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import {
  OwnableAssetData,
  AttributesData,
  Dispatcher,
  Event,
  GamesData,
  OrganizationData,
  TransactionModalData,
  UserData,
} from '../../../global/interfaces';
import { validations } from './validations';
import User from '../../user/user';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Toggle from '../../toggle/toggle';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import { SearchUserWithDropdown } from '../../search-user/search-user';
import MetadataTemplateOptions from '../../metadata-template-options/metadata-template-options';
import {
  AttributeTypeDate,
  AttributeTypeNumber,
  AttributeTypePercentage,
  AttributeTypeText,
} from '../../attribute-types';
import placeholder from '../../../global/images/avatar.webp';

import './bulk-asset-mint-random-modal.scss';

interface Props {
  ownableAsset: OwnableAssetData;
  isMainChainActive: boolean | undefined;
  setIsBulkMintRandomModalOpen: Dispatcher<boolean>;
  setTransactionModal: Dispatcher<TransactionModalData>;
  isMintingAllowed?: boolean;
}

interface AttributesDataSend {
  displayType: 'string' | 'boost_percentage' | 'date' | 'number';
  traitType: string;
  values?: string[];
  value?: string;
  __typename?: string;
}

type AttributeTypeModes = 'add' | 'range' | 'value';

const BulkAssetMintRandomModal: FC<Props> = ({
  ownableAsset,
  isMainChainActive,
  setIsBulkMintRandomModalOpen,
  setTransactionModal,
  isMintingAllowed,
}) => {
  const params = useParams();
  const { errors, isFormInvalid } = useValidation(validations);
  const [form, setForm] = useState<{
    recipient?: UserData | undefined;
    name?: string;
    description?: string;
    imageNameTemplate?: string;
    animationNameTemplate?: string;
    assetsToMint: number;
    attributes?: AttributesData[];
    isTestNet: boolean;
  }>({
    recipient: undefined,
    name: ownableAsset?.metadataTemplates?.name || '',
    description: ownableAsset?.metadataTemplates?.description || '',
    imageNameTemplate: '',
    animationNameTemplate: '',
    assetsToMint: 1,
    attributes: ownableAsset?.attributes,
    isTestNet: true,
  });
  const [activeField, setActiveField] = useState<
    'description' | 'name' | 'imageNameTemplate' | 'animationNameTemplate' | undefined
  >();
  const [attributes, setAttributes] = useState<AttributesData[] | []>(ownableAsset?.attributes || []);
  const [gameRecipient, setGameRecipient] = useState<boolean>(false);

  const { data: org } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);
  const maxMint = org && org?.Organization.mintLimit - org?.Organization.mintCount;

  const [bulkMintAsset, { loading: bulkLoading }] = useReneMutation(BULK_MINT_ASSET, {
    onCompleted() {
      handleCloseModal();
      setTransactionModal({ show: true, success: true });
    },
    onError: (errors: ApolloError) => {
      setTransactionModal({ show: true, success: false, msg: errors?.message });
    },
  });

  const { data: selectedGame } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  useEffect(() => {
    setForm((prev) => ({ ...prev, attributes }));
  }, [attributes]);

  const attributeTraits = ownableAsset?.attributes?.map((attr) => attr.traitType);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const imageTplRef = useRef<HTMLInputElement>(null);
  const animationTplRef = useRef<HTMLInputElement>(null);

  const refs: {
    name: React.RefObject<HTMLInputElement>;
    description: React.RefObject<HTMLTextAreaElement>;
    imageNameTemplate: React.RefObject<HTMLInputElement>;
    animationNameTemplate: React.RefObject<HTMLInputElement>;
  } = {
    name: nameRef,
    description: descriptionRef,
    imageNameTemplate: imageTplRef,
    animationNameTemplate: animationTplRef,
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    const {
      target: { name, value },
    } = e;
    setForm((prev) => ({ ...prev, [name]: value }));
    if (value[value.length - 1] === '@') {
      setActiveField(name as 'description' | 'name' | 'imageNameTemplate' | 'animationNameTemplate');
    } else if (activeField) {
      setActiveField(undefined);
    }
  };

  const handleOptionClick = (option: string) => {
    if (activeField) {
      setForm((prev) => ({
        ...prev,
        [activeField]: form[activeField] + '${' + option + '}',
      }));
      refs[activeField].current?.focus();
      setActiveField(undefined);
    }
  };

  const setAssetRecipient = (user: UserData | undefined) => {
    setForm((prev) => ({ ...prev, recipient: user }));
  };

  const handleCloseModal = () => {
    setIsBulkMintRandomModalOpen(false);
  };

  const handleBulkMint = () => {
    if (isFormInvalid({ ...form, maxMint, gameRecipient })) return;

    const prepareAttributes = (attrs: AttributesData[]) => {
      const formatValues = {
        string: (values: string[]) => ({ values: values }),
        number: (values: string[]) => ({ values: [values[0], values[1]] }),
        date: (values: string[]) => ({ values: [values[0], values[1]] }),
        boost_percentage: (values: string[]) => ({ value: values[0] }),
      };

      return attrs.map((attr) => {
        const { values, displayType, traitType } = attr;
        return { displayType, traitType, ...formatValues[displayType](values) };
      });
    };

    const variables: {
      ownableAssetId: string;
      userId?: string;
      gameId?: string;
      size: number;
      name?: string;
      description?: string;
      imageFilename?: string;
      animationFilename?: string;
      attributes: AttributesDataSend[];
      isTestNet: boolean;
    } = {
      ownableAssetId: ownableAsset.ownableAssetId,
      userId: !gameRecipient ? form.recipient?.userId : undefined,
      gameId: gameRecipient ? params.gameId : undefined,
      size: form.assetsToMint,
      name: form.name?.trim(),
      description: form.description?.trim(),
      imageFilename: form.imageNameTemplate || undefined,
      animationFilename: form.animationNameTemplate || undefined,
      attributes: prepareAttributes(attributes),
      isTestNet: form.isTestNet,
    };
    bulkMintAsset({ variables });
  };

  const renderAttributeTypes = () => {
    const attributeTypeMap = {
      boost_percentage: AttributeTypePercentage,
      date: AttributeTypeDate,
      number: AttributeTypeNumber,
      string: AttributeTypeText,
    };

    let attributeComponent: { id: number; label: string; item: JSX.Element }[] = [];
    attributeComponent = attributes.map((attr, i) => {
      const Component = attributeTypeMap[attr.displayType];
      const props: {
        mode: AttributeTypeModes;
        key: string;
        attribute: any;
        setAttributeList: any;
        errorMessage: string;
      } = {
        mode: 'range',
        key: attr.traitType,
        attribute: attr,
        setAttributeList: setAttributes,
        errorMessage: errors?.attributes?.[i],
      };
      return { id: i, label: attr.traitType, item: <Component {...props} /> };
    });

    return (
      <>
        <div className="asset-attribute-title">
          <label>Asset Metadata Attribute Values</label>
        </div>
        <div className="asset-attribute-values">
          {attributes.length ? (
            attributeComponent.map(({ id, label, item }) => (
              <div key={id} className="asset-attribute-values__item">
                <label>{label}</label>
                <div className="asset-attribute-values__item_value">{item}</div>
              </div>
            ))
          ) : (
            <p className="error-msg">{errors?.attributes}</p>
          )}
        </div>
      </>
    );
  };

  const renderPlayerRecipient = () => {
    return (
      <>
        {!form.recipient ? (
          <SearchUserWithDropdown
            gameId={params.gameId}
            setSelectedUser={setAssetRecipient}
            errorMessage={errors?.recipient}
          />
        ) : (
          <div className="bulk-asset-mint-random-modal__content_recipient_recipient">
            <User user={form.recipient} />
            <button type="button" onClick={() => setAssetRecipient(undefined)}>
              <Icon name="close" size={24} />
            </button>
          </div>
        )}
      </>
    );
  };

  const renderGameRecipient = () => (
    <div className="bulk-asset-mint-random-modal__content_recipient-game">
      <img src={selectedGame?.Games.items[0]?.image.url || placeholder} alt="selected game" />
      <div>{selectedGame?.Games.items[0]?.name}</div>
    </div>
  );

  const imageLabel = (
    <div className="icon-label">
      <Icon name="folders" size={24} />
      <p>Metadata Data Image/</p>
    </div>
  );

  const animationLabel = (
    <div className="icon-label">
      <Icon name="folders" size={24} />
      <p>Metadata Data Animation/</p>
    </div>
  );

  return (
    <div className="bulk-asset-mint-random-modal">
      <div className="bulk-asset-mint-random-modal__heading">
        <h2>Mint Bulk Randomized Assets</h2>
        <button type="button" onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="bulk-asset-mint-random-modal__content">
        <div className="bulk-asset-mint-random-modal__content_recipient">
          <div className="recipient-top-row">
            <label className="recipient-label">Bulk Asset Recipient</label>
            <div className="recipient-radio">
              <label>Player</label>
              <Toggle
                id="recipient"
                name="recipient"
                checked={Boolean(gameRecipient)}
                setValue={() => setGameRecipient(!gameRecipient)}
              />
              <label>Game</label>
            </div>
          </div>
          <div className="recipient-bottom-row">{!gameRecipient ? renderPlayerRecipient() : renderGameRecipient()}</div>
        </div>
        <div className="bulk-asset-mint-random-modal__content_title">
          <Input
            label="Asset Title"
            inputRef={nameRef}
            name="name"
            value={form?.name}
            handleInput={handleFormChange}
            errorMessage={errors?.name}
          />
          <>
            {activeField === 'name' ? (
              <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
            ) : null}
          </>
        </div>
        <div className="bulk-asset-mint-random-modal__content_description">
          <Textarea
            label="Asset Description"
            textAreaRef={descriptionRef}
            name="description"
            value={form?.description}
            handleInput={handleFormChange}
            errorMessage={errors?.description}
          />
          <>
            {activeField === 'description' ? (
              <MetadataTemplateOptions //TODO take care of text area cursor track
                handleOptionClick={handleOptionClick}
                filteredOptions={attributeTraits}
              />
            ) : null}
          </>
        </div>
        <div className="bulk-asset-mint-random-modal__content_media">
          <p>Bulk asset image</p>
          <>
            <Input
              label={imageLabel}
              labelPosition="left"
              name="imageNameTemplate"
              inputRef={imageTplRef}
              value={form.imageNameTemplate}
              handleInput={handleFormChange}
              errorMessage={errors?.imageNameTemplate}
            />
            <>
              {activeField === 'imageNameTemplate' ? (
                <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
              ) : null}
            </>
          </>
        </div>
        <div className="bulk-asset-mint-random-modal__content_media">
          <p>Bulk asset animation</p>
          <>
            <Input
              label={animationLabel}
              labelPosition="left"
              name="animationNameTemplate"
              inputRef={animationTplRef}
              value={form.animationNameTemplate}
              handleInput={handleFormChange}
              errorMessage={errors?.animationNameTemplate}
            />
            <>
              {activeField === 'animationNameTemplate' ? (
                <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
              ) : null}
            </>
          </>
        </div>
        <div>{renderAttributeTypes()}</div>
        <div className="minting-results">
          <div className="minting-results__input">
            <Input
              label="Number of assets to mint"
              name="numOfAssets"
              value={form.assetsToMint}
              handleInput={(e) => setForm((prev) => ({ ...prev, assetsToMint: +e.target.value }))}
              errorMessage={errors?.assetsToMint}
            />
          </div>
        </div>
        <div className="bulk-asset-mint-random-modal__content_testnet">
          <label>Test Net:</label>
          <Toggle
            checked={form.isTestNet}
            setValue={(e) => {
              setForm((prev) => ({ ...prev, isTestNet: e.target.checked }));
            }}
            disabled={!isMainChainActive}
          />
        </div>
      </div>
      <div className="bulk-asset-mint-random-modal__actions">
        {!isMintingAllowed && !form.isTestNet ? (
          <div>
            You can't mint due to insufficient funds. You can top up the credits{' '}
            <NavLink to={ORG_CREDIT} className={({ isActive }) => (isActive ? 'active-page' : '')}>
              here
            </NavLink>
          </div>
        ) : (
          ''
        )}
        <div>
          <button className="secondary-btn" onClick={handleCloseModal}>
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn"
            onClick={handleBulkMint}
            disabled={!isMintingAllowed && !form.isTestNet}
          >
            {bulkLoading ? <Spinner size="sm" /> : 'Mint'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkAssetMintRandomModal;
