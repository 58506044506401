import { AdType, URL_YOUTUBE_VALIDATION_REGEX } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  externalBannerUrl: (form: { [key: string]: string }, key: string, field: string) => {
    if (form.adType === AdType.VIDEO && !field) return 'This field is required';
    if (form[key] && !form[key].match(URL_YOUTUBE_VALIDATION_REGEX))
      return 'You must enter full valid URL from YouTube';
  },
};
