import { useState, useEffect } from 'react';
import { AttributesData, Dispatcher, Event } from '../../../global/interfaces';
import Input from '../../input/input';
import './attribute-type-date.scss';

const AttributeTypeDate = ({
  attribute,
  setAttributeList,
  mode = 'add',
  errorMessage,
  disabled = false,
}: {
  attribute: AttributesData;
  setAttributeList: Dispatcher<AttributesData[] | []>;
  mode?: 'add' | 'range' | 'value';
  errorMessage?: string;
  disabled?: boolean;
}) => {
  const [prevAttribute, setPrevAttribute] = useState<AttributesData>();
  const [newAttribute, setNewAttribute] = useState<{
    from: string;
    to: string;
    default: string;
  }>({
    from: '',
    to: '',
    default: '',
  });

  const setAttribute = (e: Event['Input']) => {
    setNewAttribute((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  if (attribute !== prevAttribute && !prevAttribute && attribute.values.length) {
    setPrevAttribute(attribute);
    setNewAttribute({ from: attribute.values[0], to: attribute.values[1], default: attribute.values[2] });
  }

  useEffect(() => {
    setAttributeList((prev) => {
      return prev.map((attr) => {
        if (attr.traitType === attribute.traitType) {
          return {
            ...attr,
            values: [`${newAttribute.from}`, `${newAttribute.to}`, `${newAttribute.default}`],
          };
        }
        return attr;
      });
    });
  }, [attribute.traitType, newAttribute, setAttributeList]);

  return (
    <>
      <div className="attribute_type_date">
        {['add', 'range'].includes(mode) && (
          <>
            <Input
              label="From"
              type="date"
              name="from"
              value={newAttribute.from}
              handleInput={setAttribute}
              hideErrorMessage
              placeholder="From"
              disabled={disabled}
            />
            <Input
              label="To"
              type="date"
              name="to"
              value={newAttribute.to}
              handleInput={setAttribute}
              hideErrorMessage
              placeholder="To"
              disabled={disabled}
            />
          </>
        )}
        {['add', 'value'].includes(mode) && (
          <Input
            label="Default"
            type="date"
            name="default"
            value={newAttribute.default}
            handleInput={setAttribute}
            hideErrorMessage
            placeholder="Default"
            disabled={disabled}
          />
        )}
      </div>
      <p className="error-msg">{errorMessage}</p>
    </>
  );
};

export default AttributeTypeDate;
