import { useState } from 'react';
import { TOKEN } from '../../../global/consts';
import { GAMES } from '../../../global/routes';
import { useNavigate } from 'react-router-dom';
import { REFRESH_TOKEN, REMOVE_USER_TO_ORG_MUTATION } from '../../../global/gql/mutations';
import { useReneMutation } from '../../../hooks';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import './leave-organization-modal.scss';

interface Props {
  orgId: string | undefined;
  userId: string | undefined;
  orgName: string | undefined;
  setCloseModal: () => void;
}

const LeaveOrganizationModal: React.FC<Props> = ({ orgId, userId, orgName, setCloseModal }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const [refreshToken] = useReneMutation(REFRESH_TOKEN, {
    onCompleted(data: { RefreshToken: { jwt: string } }) {
      if (data.RefreshToken.jwt) {
        localStorage.setItem(TOKEN, data.RefreshToken.jwt);
        setLoading(false);
        navigate(GAMES);
      }
    },
  });

  const [removeUserFromOrg] = useReneMutation(REMOVE_USER_TO_ORG_MUTATION, {
    variables: {
      orgId,
      userId,
    },
    onCompleted() {
      refreshToken();
    },
  });

  const handleLeaveOrg = () => {
    setLoading(true);
    removeUserFromOrg();
  };

  return (
    <div className="leave-organization-modal">
      <div className="leave-organization-modal__heading">
        <h2>Leave {orgName}?</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <p>Are you sure want to leave {orgName}? You can not undo this action</p>
      <div className="leave-organization-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleLeaveOrg} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Confirm'}
        </button>
      </div>
    </div>
  );
};

export default LeaveOrganizationModal;
