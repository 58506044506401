import { TransactionItemData } from '../../global/interfaces';
import Icon, { IconName } from '../Icon/Icon';

import avatar from '../../global/images/avatar.webp';

import './transactions-list.scss';

const transactionType = {
  SUCCESS: {
    className: '--success',
    MINT: {
      title: 'Asset Minted',
      icon: 'mint',
    },
    TRANSFER: {
      title: 'Asset Transferred',
      icon: 'transfer',
    },
  },
  FAILED: {
    className: '--failed',
    MINT: {
      title: 'Minting failed',
      icon: 'circle-cross',
    },
    TRANSFER: {
      title: 'Transfer failed',
      icon: 'circle-cross',
    },
  },
  PENDING: {
    className: '--pending',
    MINT: {
      title: 'Minting pending',
      icon: 'alert',
    },
    TRANSFER: {
      title: 'Transfer pending',
      icon: 'alert',
    },
  },
};

const Transaction = ({ transaction, isCredit }: { transaction: TransactionItemData; isCredit?: boolean }) => {
  const parseDate = new Date(transaction.date).toDateString();
  return (
    <div className="transactions-list__transaction">
      <div className="transactions-list__transaction_heading">
        <div className={'transaction' + transactionType[transaction.status].className}>
          <Icon name={transactionType[transaction.status][transaction.type].icon as IconName} size={24} />
          <p>{transactionType[transaction.status][transaction.type].title}</p>
        </div>
        {!isCredit ? null : <p className="transactions-list__transaction_heading_date"> {parseDate}</p>}
      </div>
      <div className="transactions-list__transaction_main">
        {isCredit && (
          <div className="transactions-list__transaction_main_credit_charge">
            <p>{transaction.charge ? `${transaction?.charge?.value}$` : ''}</p>
          </div>
        )}
        <div className="transactions-list__transaction_main_asset">
          <img src={transaction?.asset?.metadata?.image || avatar} alt="asset" />
          <div>
            <h2>{transaction?.asset?.metadata?.name}</h2>
            {!isCredit && (
              <p>{transaction.charge ? `${parseFloat(transaction?.charge?.value).toLocaleString()}$` : ''}</p>
            )}
          </div>
        </div>
        <div className="transactions-list__transaction_main_users">
          <div>
            <p>From:</p>
            <div>
              <img src={transaction?.sender?.imageUrl || avatar} alt="sender" />
              <p>{transaction?.sender?.name}</p>
            </div>
          </div>
          <div>
            <p>To:</p>
            <div>
              <img src={transaction?.receiver?.imageUrl || avatar} alt="sender" />
              <p>{transaction?.receiver?.name}</p>
            </div>
          </div>
        </div>
        {!isCredit && (
          <div className="transactions-list__transaction_main_date">
            <p>{parseDate}</p>
          </div>
        )}
      </div>
    </div>
  );
};

const TransactionsList = ({ transactions, isCredit }: { transactions: TransactionItemData[]; isCredit?: boolean }) => {
  return (
    <div className="transactions-list">
      {transactions?.map((transaction, i) => (
        <Transaction key={i} transaction={transaction} isCredit={isCredit} />
      ))}
    </div>
  );
};

export default TransactionsList;
