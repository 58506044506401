import { Navigate, Outlet } from 'react-router';
import { isUserLoggedIn } from '../../utils';

interface Props {
  redirectTo: string;
  element?: React.ReactNode;
}

const AuthWrapper: React.FC<Props> = ({ redirectTo, element }) => {
  const isLoggedIn = isUserLoggedIn();

  if (!isLoggedIn) {
    return <Navigate to={redirectTo} replace />;
  }

  return (
    <>
      {element}
      <Outlet />
    </>
  );
};

export default AuthWrapper;
