import { AdType, allowedImageTypes, allowedVideoTypes } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const validations = {
  resolutionIab: commonValidations.requiredTrimmed(),
  file: (form: { [k: string]: any }, key: 'file', field: File) => {
    if (field && form.adType === AdType.BANNER && !allowedImageTypes.includes(form?.[key]?.type as string))
      return 'You must provide a valid image (jpg, png, webp) file';

    if (field && form.adType === AdType.VIDEO && !allowedVideoTypes.includes(form?.[key]?.type as string))
      return 'You must provide a valid video (mp4, webm) file';
  },
  maxWidth: (form: { [k: string]: any }, _key: 'maxWidth', field: number) => {
    if (form.maxHeight && !field) return 'Must be set as well';
  },
  maxHeight: (form: { [k: string]: any }, _key: 'maxHeight', field: number) => {
    if (form.maxWidth && !field) return 'Must be set as well';
  },
};
