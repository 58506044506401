import React from 'react';
import { GET_ASSETS_WITH_TRANSACTIONS_QUERY } from '../../../global/gql/queries';
import { AssetData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import placeholder from '../../../global/images/avatar.webp';

import './asset-modal.scss';

interface Props {
  assetId: string;
  notificationId?: string;
  children?: JSX.Element;
  closeModal: () => void;
}

const AssetModal: React.FC<Props> = ({ closeModal, children, assetId, notificationId }) => {
  const { data, loading } = useReneQuery<{ Assets: { items: AssetData[] } }>(GET_ASSETS_WITH_TRANSACTIONS_QUERY, {
    variables: {
      assetId,
      limit: 1,
      status: 'PENDING',
    },
  });

  const handleModal = () => {
    closeModal();
  };

  return (
    <div className="asset-modal">
      <button type="button" onClick={handleModal}>
        <Icon name="close" size={24} />
      </button>
      {loading ? (
        <Spinner size="lg" color="white" />
      ) : (
        <>
          <div className="asset-modal__info">
            <div>
              <div>
                <img src={data?.Assets.items[0]?.metadata?.image || placeholder} alt="asset" />
              </div>
              <div>
                <h2>{data?.Assets.items[0]?.metadata?.name}</h2>
                <div className="asset-modal__info_tooltip">{data?.Assets.items[0]?.metadata?.name}</div>
                <p>{data?.Assets.items[0]?.metadata?.description}</p>
              </div>
            </div>
            <p>{data?.Assets.items[0]?.metadata?.description}</p>
          </div>
          <div className="asset-modal__properties">
            <h2>Properties</h2>
            <div>
              {data?.Assets.items[0]?.metadata?.attributes.map((attribute) => (
                <div key={attribute.traitType}>
                  <h3>{attribute.traitType}</h3>
                  <div className="asset-modal__properties_tooltip">{attribute.traitType}</div>
                  <h4>{attribute.value}</h4>
                  <div className="asset-modal__properties_tooltip">{attribute.value}</div>
                  <p>25% have this trait</p>
                </div>
              ))}
            </div>
          </div>
          {data && children && React.cloneElement(children, { asset: data.Assets.items[0] })}
        </>
      )}
    </div>
  );
};

export default AssetModal;
