import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SearchUser } from '../../../../components/search-user/search-user';
import { useReneQuery } from '../../../../hooks';
import { GET_GAMES_QUERY } from '../../../../global/gql/queries';
import { GamesData, UserData } from '../../../../global/interfaces';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import Modal from '../../../../components/modal/modal';
import PlayerModal from '../../../../components/modal/player-modal/player-modal';
import PlayerCard from '../../../../components/player-card/player-card';

import './organization-game-players.scss';

const OrganizationGamePlayers = () => {
  const params = useParams();
  const [searchData, setSearchData] = useState<UserData[]>();
  const [loading, setLoading] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<UserData | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data, loading: gamesLoading } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const players = searchData ? searchData : data?.Games.items[0]?.players.items;

  const handleSelectPlayer = (id: string) => {
    if (!players?.length) return;
    const findUser = players.find((player: UserData) => player.userId === id);
    setSelectedPlayer(findUser);
    setIsModalOpen(true);
  };

  return (
    <div className="organization-game-players">
      <SearchUser setSearchData={setSearchData} setLoading={setLoading} gameId={params.gameId} />
      {gamesLoading || loading ? (
        <LoadingFallback />
      ) : (
        <>
          <div className="organization-game-players__heading">
            <h2>
              {`${players?.length}
              ${players?.length === 1 ? 'player' : 'players'}`}
            </h2>
          </div>
          <div className="organization-game-players__main">
            {players?.map((user: UserData) => (
              <PlayerCard key={user.userId} user={user} handleSelectPlayer={handleSelectPlayer} />
            ))}
          </div>
        </>
      )}
      {selectedPlayer && (
        <Modal isOpen={isModalOpen}>
          <PlayerModal gameId={params.gameId as string} player={selectedPlayer} setCloseModal={setIsModalOpen} />
        </Modal>
      )}
    </div>
  );
};

export default OrganizationGamePlayers;
