import { useState } from 'react';
import {
  TRACK_AUTH_EVENT_MUTATIONS,
  UPSERT_BRANDED_ASSET_MUTATION,
  UPSERT_OWNABLE_ASSET_MUTATION,
} from '../../../global/gql/mutations';
import { OwnableAssetData, Event, Refetch, UserData, BrandedAssetData } from '../../../global/interfaces';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { validations } from './validations';
import { AssetTypeId, EventTypes } from '../../../global/consts';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { useProdEffect } from '../../../hooks/useProdEffect';
import { useFileUpload } from '../../../hooks/useFileUpload';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';

import './game-engine-files-modal.scss';
import { extractExtensionAndName } from '../../../utils';

interface Props {
  id: string;
  type: AssetTypeId;
  uploadedFiles?: any;
  refetch: Refetch<
    | {
        OwnableAssets: {
          items: OwnableAssetData[];
        };
      }
    | { BrandedAssets: { items: BrandedAssetData[] } }
  >;
  setCloseModal: () => void;
}

const query = {
  ownableAssetId: UPSERT_OWNABLE_ASSET_MUTATION,
  brandedAssetId: UPSERT_BRANDED_ASSET_MUTATION,
};

const GameEngineFilesModal: React.FC<Props> = ({ id, type, uploadedFiles, refetch, setCloseModal }) => {
  const uploadFile = useFileUpload();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS, {
    onError() {},
  });

  const [upsertAsset] = useReneMutation(query[type], {
    onCompleted(data: { UpsertOwnableAsset: OwnableAssetData } | { UpsertBrandedAsset: BrandedAssetData }) {
      const promises: Promise<Response | void>[] = [];
      const gameEngineFiles =
        (data as { UpsertOwnableAsset: OwnableAssetData })?.UpsertOwnableAsset?.gameEngineFiles ||
        (data as { UpsertBrandedAsset: BrandedAssetData })?.UpsertBrandedAsset?.gameEngineFiles;
      if (files) {
        const filesForUpload = gameEngineFiles.slice(0 - files?.length);
        filesForUpload.forEach((gameFile, i) => {
          if (files) {
            promises.push(uploadFile(gameFile.uploadUrl, files[i]));
          }
        });
        Promise.all(promises).then(() => {
          setCloseModal();
          setLoading(false);
          refetch({ [type]: id });
          handleUploadCompleteEventTracker();
        });
      }
    },
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_STARTED,
        },
      });
    }
  }, []);

  const handleUploadCompleteEventTracker = () => {
    if (process.env.REACT_APP_RENE_ENVIRONMENT === 'prd' && user && files && type === AssetTypeId.ownableAssetId) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          ownableAssetId: id,
          gameEngineFiles: Object.values(files).map((file) => file.name),
          eventType: EventTypes.OWNABLE_ASSET_GAME_ENGINE_FILES_UPLOAD_COMPLETED,
        },
      });
    }
  };

  const handleUploadFiles = () => {
    if (isFormInvalid({ files })) return;
    const variables: {
      ownableAssetId?: string;
      brandedAssetId?: string;
      gameEngineFiles: { extension: string; name: string }[];
    } = {
      [type]: id,
      gameEngineFiles: Object.values(files).map((file) => ({
        extension: extractExtensionAndName(file.name)[2],
        name: file.name,
      })),
    };
    setLoading(true);
    upsertAsset({ variables });
  };

  const uploadedFileNames: string[] = uploadedFiles?.map(({ name }: { name: string }) => name);

  const handleBeforeChange = (e: Event['Input']) => {
    const files = e.target.files || [];
    return !isFieldInvalid('files', { files, uploadedFileNames });
  };

  return (
    <div className="game-engine-files-modal">
      <div className="game-engine-files-modal__heading">
        <h2>New Engine Files</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <FileUpload setFiles={setFiles} handleBeforeChange={handleBeforeChange} errorMessage={errors?.files} />
      <div className="game-engine-files-modal__files">
        {files &&
          Object.values(files).map((file, i) => (
            <div key={i}>
              <div>
                <Icon name="file" />
                <p>{file.name}</p>
              </div>
              <p>{(file.size / (1024 * 1024)).toFixed(2)} Mb</p>
            </div>
          ))}
      </div>
      <div className="game-engine-files-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleUploadFiles} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Upload'}
        </button>
      </div>
    </div>
  );
};

export default GameEngineFilesModal;
