import avatar from '../../../global/images/avatar.webp';

import './asset-card.scss';

interface Props {
  title: string;
  image: string;
  price?: number;
  children?: React.ReactNode | React.ReactNode[];
}

const AssetCard: React.FC<Props> = ({ title, image, price, children }) => {
  return (
    <div className="asset-card">
      <img src={image || avatar} alt="game poster" />
      <div className="asset-card__info">
        <p>{title}</p>
        {price && <p className="rainbow-btn-text">{price} $</p>}
      </div>
      {children}
    </div>
  );
};

export default AssetCard;
