import { useState } from 'react';
import { Dispatcher, GameData, GamesData } from '../../../../global/interfaces';
import { useReneQuery } from '../../../../hooks';
import { GET_ORG_GAMES_QUERY } from '../../../../global/gql/queries';
import GameWallet from '../../../../components/game-wallet/game-wallet';
import SmallDashboardBar from '../../../../components/small-dashboard-bar/small-dashboard-bar';
import Icon from '../../../../components/Icon/Icon';
import ChainLogo from '../../../../components/chain-logo/chain-logo';
import Statistics from '../../../../components/statistics/statistics';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';

import './org-wallets.scss';

const OrgWallets = ({ setOpenMobileMenu }: { setOpenMobileMenu: Dispatcher<boolean> }) => {
  const [selectedGame, setSelectedGame] = useState<GameData | undefined>();

  const { data: games, loading } = useReneQuery<{ Games: GamesData }>(GET_ORG_GAMES_QUERY);

  const handleSelectGameWallet = (gameId: string) => {
    const selectedGame = games?.Games.items.find((game) => game.gameId === gameId);
    setSelectedGame(selectedGame);
  };

  if (selectedGame) {
    return (
      <div className="org-wallets__selected">
        <button type="button" onClick={() => setSelectedGame(undefined)}>
          <Icon name="chevron-left" size={16} />
          <p>All Wallets</p>
        </button>
        <GameWallet game={selectedGame} />
      </div>
    );
  }

  return (
    <div className="org-wallets">
      <div className="org-wallets__heading">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h2>Wallets</h2>
      </div>
      <div className="org-wallets__main">
        {games?.Games.items?.map((game) => (
          <button key={game.gameId} onClick={() => handleSelectGameWallet(game.gameId)}>
            <SmallDashboardBar
              id={game.gameId}
              name={game.name}
              imageUrl={game?.image?.url}
              description={game?.data?.description}
            >
              <div className="org-wallets__main_stats">
                <ChainLogo chain={game.chain} />
                <Statistics data={{ assets: game?.stats?.walletAssets }} />
              </div>
            </SmallDashboardBar>
          </button>
        ))}
        {loading ? <LoadingFallback /> : null}
      </div>
    </div>
  );
};

export default OrgWallets;
