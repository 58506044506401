import { Link } from 'react-router-dom';
import { OwnableAssetData } from '../../../global/interfaces';
import OrgAssetCard from '../org-asset-card/org-asset-card';
import placeholder from '../../../global/images/asset-placeholder.png';

import './ownable-asset-list.scss';

interface Props {
  ownableAssetList: OwnableAssetData[] | undefined;
}

const OwnableAssetList: React.FC<Props> = ({ ownableAssetList }) => {
  if (!ownableAssetList || !ownableAssetList.length) return null;
  return (
    <div className="ownable-asset-list">
      {ownableAssetList.map((ownableAsset: OwnableAssetData) => {
        return (
          <Link key={ownableAsset?.ownableAssetId} to={`${ownableAsset.ownableAssetId}/files`}>
            <OrgAssetCard image={ownableAsset?.image?.url || placeholder} title={ownableAsset?.name} />
          </Link>
        );
      })}
    </div>
  );
};

export default OwnableAssetList;
