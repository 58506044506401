import { useCallback, useState, useEffect, Fragment, useMemo } from 'react';
import { useNotificationContext } from '../../context/notification-context';
import { ASSETS } from '../../global/routes';
import { GAMER_GAME_SEARCH_QUERY, GET_GAMES_GAMER_QUERY, GET_USER_QUERY } from '../../global/gql/queries';
import { GameStatus } from '../../global/consts';
import { AssetData, Event, GameData, GamesData, UserData } from '../../global/interfaces';
import { useLazyReneQuery, useReneQuery } from '../../hooks';
import Icon from '../Icon/Icon';
import Modal from '../modal/modal';
import Search from '../search/search';
import Select from '../select/select';
import AssetModal from '../modal/asset-modal/asset-modal';
import BigGameBar from '../game/big-game-bar/big-game-bar';
import TransferButton from '../transfer-button/transfer-button';
import LoadingFallback from '../loading-fallback/loading-fallback';
import AssetTransferModal from '../modal/asset-transfer-modal/asset-transfer-modal';

import './connected-games.scss';

const ConnectedGames = () => {
  const { notification, showNotification } = useNotificationContext();

  const [gameSearchTerm, setSearchTerm] = useState('');
  const [isAssetsModalOpen, setAssetsModalOpen] = useState<boolean>(false);
  const [isAssetTransferModalOpen, setIsAssetTransferModalOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string>('');

  const { data } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const hasBalance = useMemo(() => {
    const { balance } = data?.User.balance || {};
    return !!balance && +balance > 0;
  }, [data?.User.balance]);

  const [search, { data: searchGames, loading: searchLoading }] = useLazyReneQuery<{ GameSearch: GamesData }>(
    GAMER_GAME_SEARCH_QUERY,
  );

  const {
    data: reneGames,
    loading: gamesLoading,
    refetch,
  } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY, {
    variables: {
      status: GameStatus.CONNECT_CONFIRMED,
    },
  });
  const showData = gameSearchTerm && searchGames ? searchGames.GameSearch : reneGames?.Games;

  useEffect(() => {
    if (gameSearchTerm) {
      search({ variables: { gameSearchTerm } });
    }
  }, [gameSearchTerm, search]);

  const handleSearch = useCallback((data: any) => {
    setSearchTerm(data);
  }, []);

  useEffect(() => {
    if (notification) {
      refetch();
      showNotification(false);
    } //TODO temporary fix to refresh the games
  }, [notification, refetch, showNotification]);

  const handleSelectAsset = (e: Event['Button']) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.currentTarget as HTMLButtonElement;
    setSelectedAsset(target.id as AssetData['assetId']);
    setAssetsModalOpen(true);
  };

  if (!showData?.items?.length) {
    return null;
  }

  return (
    <div className="connected_games">
      <div className="connected_games__actions">
        <Search callback={handleSearch} apiSearch />
        <Select
          className="organization_dashboard_page__main_actions_sort"
          value="Latest"
          options={['Latest']}
          changeHandler={() => {}}
        >
          <Icon name="arrows-sort" size={16} />
        </Select>
      </div>
      <Modal isOpen={isAssetsModalOpen}>
        <AssetModal closeModal={() => setAssetsModalOpen(false)} assetId={selectedAsset}>
          <TransferButton
            setIsAssetModalOpen={setAssetsModalOpen}
            setIsAssetTransferModalOpen={setIsAssetTransferModalOpen}
            isTransferAllowed={hasBalance}
          />
        </AssetModal>
      </Modal>
      <Modal isOpen={isAssetTransferModalOpen}>
        <AssetTransferModal setCloseModal={setIsAssetTransferModalOpen} assetId={selectedAsset} />
      </Modal>
      {gamesLoading || searchLoading ? (
        <LoadingFallback />
      ) : (
        <div className="connected_games__list">
          {showData?.items.map((game: GameData) => (
            <Fragment key={game.gameId}>
              <BigGameBar
                linkTo={`${game.gameId}/${ASSETS}`}
                description={game?.data?.description}
                name={game?.name}
                imageUrl={game.image?.url}
                assets={game?.assets?.items.slice(0, 4) as AssetData[]}
                handleSelectAsset={handleSelectAsset}
              />
              <div className="connected_games__list_divider" />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default ConnectedGames;
