import { commonValidations } from '../../../../global/validations';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  file: commonValidations.fileImage(),
  finite: (form: any, key: string) => {
    if (form.isFinite && form[key] === '') {
      return 'This field is required';
    }

    if (Number.parseInt(form[key]) > form.maxMint) {
      return `Value must be lower than ${form.maxMint}`;
    }

    if (Number.parseInt(form[key]) <= 0 && !form?.infinite) {
      return 'It must be positive value';
    }
  },
};
