import { Link } from 'react-router-dom';
import { AssetType } from '../../global/consts';
import { DragEvent } from 'react';
import { CollectionData } from '../../global/interfaces';

import './grid-bar.scss';

const GridBar = ({
  id,
  img,
  title,
  type,
  stats,
  linkTo,
  children,
  draggable,
  brandName,
  placeholderImg,
  onDragStart = () => {},
  onDragOver,
  onDrop = () => {},
}: {
  id?: string;
  linkTo?: string;
  img: string;
  type: AssetType;
  title: string;
  brandName?: string;
  stats: CollectionData['stats'];
  children?: JSX.Element;
  draggable?: boolean;
  placeholderImg: string;
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: DragEvent<HTMLDivElement>) => void;
}) => {
  const grid = () => (
    <>
      <div>
        <img src={img || placeholderImg} alt="collections" />
        {type === AssetType.BRANDED && <p>Branded</p>}
      </div>
      <div>
        {brandName && <p className="grid-bar__brand">{brandName}</p>}
        <p className="grid-bar__title"> {title}</p>
        <div className="grid-bar__stats">
          <p>{type === AssetType.BRANDED ? stats?.games : stats?.assets}</p>
          <p>{type === AssetType.BRANDED ? 'games' : 'assets'}</p>
        </div>
      </div>
    </>
  );

  return linkTo ? (
    <Link to={linkTo} className="grid-bar">
      {grid()}
    </Link>
  ) : (
    <div
      className="grid-bar"
      id={id}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      {grid()}
      {children}
    </div>
  );
};

export default GridBar;
