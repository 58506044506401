import { useLazyReneQuery, useReneQuery } from '../../../hooks';
import { AssetType } from '../../../global/consts';
import { CollectionData, CollectionsData, Event } from '../../../global/interfaces';
import { COLLECTIONS_SEARCH_QUERY, createCollectionsQuery } from '../../../global/gql/queries';
import { DragEvent, useCallback, useEffect, useState } from 'react';
import Icon from '../../Icon/Icon';
import Search from '../../search/search';
import Select from '../../select/select';
import GridBar from '../../grid-bar/grid-bar';
import LoadingFallback from '../../loading-fallback/loading-fallback';
import collPlaceholder from '../../../global/images/collection-placeholder.png';

import './collections-catalog-modal.scss';

const CollectionsCatalogModal = ({
  setCloseModal,
  onDragStart,
  onDragOver,
  onDrop,
}: {
  setCloseModal: () => void;
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void;
  onDragOver?: (e: DragEvent<HTMLDivElement>) => void;
  onDrop?: (e: DragEvent<HTMLDivElement> | Event['Button']) => void;
}) => {
  const [collectionSearchTerm, setSearchTerm] = useState('');

  const { data: collections, loading } = useReneQuery<{
    Collections: CollectionsData;
  }>(createCollectionsQuery(AssetType.BRANDED), {
    variables: {
      type: AssetType.BRANDED.toUpperCase(),
    },
  });

  const [search, { data: searchCollections, loading: searchLoading }] = useLazyReneQuery<{
    CollectionSearch: CollectionsData;
  }>(COLLECTIONS_SEARCH_QUERY);

  const showCollectionsData =
    collectionSearchTerm && searchCollections ? searchCollections.CollectionSearch : collections?.Collections;

  useEffect(() => {
    if (collectionSearchTerm) {
      search({ variables: { collectionSearchTerm, collectionType: 'BRANDED' } });
    }
  }, [collectionSearchTerm, search]);

  const handleSearch = useCallback((data: any) => {
    setSearchTerm(data);
  }, []);

  return (
    <div className="collections-catalog-modal">
      <div className="collections-catalog-modal__heading">
        <h2>Branded Collections Catalog</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="collections-catalog-modal__search">
        <Search callback={handleSearch} apiSearch />
        <Select
          className="collections-catalog-modal__search_sort"
          value="Latest"
          options={['Latest']}
          changeHandler={() => {}}
        >
          <Icon name="arrows-sort" size={16} />
        </Select>
      </div>
      <p>Drag & drop collection to your game</p>
      <div className="collections-catalog-modal__main">
        {!loading && !searchLoading ? (
          showCollectionsData?.items.map((collection: CollectionData) => (
            <GridBar
              id={collection?.collectionId as string}
              key={collection?.collectionId}
              img={collection?.image?.url}
              title={collection?.name}
              stats={collection?.stats}
              type={AssetType.BRANDED}
              brandName={collection?.brand?.name}
              placeholderImg={collPlaceholder}
              onDragOver={onDragOver}
              onDragStart={onDragStart}
              onDrop={onDrop}
              draggable
            >
              <button
                id={collection?.collectionId}
                className="collections-catalog-modal__main_add"
                onClick={(e) => onDrop && onDrop(e)}
              >
                <Icon name="plus" />
              </button>
            </GridBar>
          ))
        ) : (
          <LoadingFallback />
        )}
      </div>
    </div>
  );
};

export default CollectionsCatalogModal;
