import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { QRCode } from 'react-qrcode-logo';
import { useReneQuery } from '../../../../hooks';
import { GET_USER_REFERRAL_DATA } from '../../../../global/gql/queries';
import { Dispatcher, UserReferralData } from '../../../../global/interfaces';
import Icon from '../../../../components/Icon/Icon';

import placeholder from '../../../../global/images/avatar.webp';
import reneLogoQR from '../../../../global/images/rene-logo-qr.png';

import './referrals.scss';
import { getTimeAgo } from '../../../../utils';

const ReferralsDropdown = ({
  isCollapsed = true,
  header,
  children,
  setIsSectionCollapsed,
}: {
  isCollapsed?: boolean;
  header: JSX.Element | string;
  children: JSX.Element | string;
  setIsSectionCollapsed?: Dispatcher<boolean>;
}) => {
  const [collapsed, setCollapsed] = useState(isCollapsed);

  useEffect(() => {
    if (!isCollapsed) {
      setCollapsed(false);
    }
  }, [isCollapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    if (!isCollapsed && setIsSectionCollapsed) {
      setIsSectionCollapsed(true);
    }
  };

  return (
    <div className={`referrals__dropdown${collapsed ? ' referrals__dropdown--collapsed' : ''}`}>
      <div className="referrals__dropdown__header" onClick={toggleCollapsed}>
        <h5>{header}</h5>
        <Icon name="chevron-down" size={24} />
      </div>
      <div className="referrals__dropdown__content">{children}</div>
    </div>
  );
};

const Referrals = ({ setOpenMobileMenu }: { setOpenMobileMenu: Dispatcher<boolean> }) => {
  const [isCollapsed, setIsSectionCollapsed] = useState(true);
  const { data } = useReneQuery<{ User: UserReferralData }>(GET_USER_REFERRAL_DATA);

  const link = `app.reneverse.io/register/${data?.User?.referralCode}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(link);
  };

  const handleLearnMore = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
    setIsSectionCollapsed(false);
  };

  return (
    <div className="referrals">
      <div className="referrals__heading">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h1>Referrals</h1>
      </div>
      <div className="referrals__content">
        <div className="referrals__invite">
          <div className="referrals__invite__text">
            <div className="referrals__invite__icon">
              <Icon name="user-plus" size={40} />
            </div>
            <h3>Invite your friends to sign up to ReneVerse & Earn Rewards.</h3>
            <p>Share your unique referral link, or let your friends scan the QR code.</p>
            <div className="referrals__invite__card">
              <ul>
                <li>
                  Refer <strong>1 Person</strong> to receive +2,000 points for both you and your referral
                </li>
                <li>
                  Refer <strong>5 People</strong> - Get extra +3,000 Point Bonus
                </li>
                <li>
                  Refer <strong>10 People</strong> - Get extra +5,000 Point Bonus
                </li>
                <li>*Get an additional +2,000 points when your referral reaches 5,000 points!</li>
              </ul>
              <p>
                <strong>Top 50 Referrers</strong> will get a{' '}
                <strong className="referrals__invite__em">ReneVerse Founder's Key!</strong>
                (Measured by total number of referrals, not by total Points)
              </p>
            </div>
          </div>
          {data?.User.referralCode ? (
            <div className="referrals__invite__qr">
              <p>Tip: Save QR to your photo library for easy access</p>
              <div className="referrals__invite__link" onClick={handleCopyToClipboard}>
                {link}
                <Icon name="copy" size={24} />
              </div>
              <div className="referrals__invite__qr-code">
                <QRCode
                  value={link}
                  size={150}
                  quietZone={5}
                  eyeRadius={40}
                  eyeColor="#402da1"
                  logoImage={reneLogoQR}
                  logoHeight={30}
                  logoWidth={30}
                  logoPadding={2}
                />
              </div>
            </div>
          ) : null}
        </div>
        <section className="referrals__rewards">
          <h4>Active Referral Rewards</h4>
          <div className="referrals__rewards_reward">
            <div>
              <div>
                <h2>Earn Points Toward the $500K $RENE Airdrop!</h2>
                <button>{/* <Icon name="close" /> */}</button>
              </div>
              <p>Referred friends who join the Airdrop earn you extra Points. Check out the Airdrop to see how!</p>
              <Link to="https://reneverse.io/airdrop" target="_blank">
                Visit Airdrop
              </Link>
            </div>
            <div>
              <div>
                <h2>Referral Challenge</h2>
                <button>{/* <Icon name="close" /> */}</button>
              </div>
              <p>
                The top 50 users with the <span>most referrals</span> will earn a{' '}
                <span>ReneVerse Founder’s Key NFT</span>!
              </p>
              <button onClick={() => handleLearnMore()}>Learn More</button>
            </div>
          </div>
        </section>
        <section className="referrals__list">
          <h4>Referrals</h4>
          <div className="referrals__list_users">
            {data?.User?.referrals?.items.map((user) => (
              <div key={user.userId}>
                <img src={user?.image?.url || placeholder} alt="user" />
                <h5>
                  {user.data?.firstName} {user.data?.lastName}
                </h5>
                <p>{getTimeAgo(user.createdAt)}</p>
              </div>
            ))}
          </div>
        </section>
        <section>
          <h4>FAQ</h4>
          <ReferralsDropdown header="What is the ReneID Referral System?">
            The ReneID Referral Program allows you and your friends to earn Points & Bonuses. Once your friend signs up
            for ReneVerse using your invite link, you’ll both receive the Point Bonus + 50 NFT for the Top 50 Referrers.
          </ReferralsDropdown>
          <ReferralsDropdown header="How do I share my referral code?">
            <>
              In the ReneVerse Portal, open your ReneID dropdown menu (AKA your ReneVerse account), and navigate to
              “Referrals”:
              <ol>
                <li>
                  Here, you’ll see a Referral Link that you can copy to your clipboard and a QR Code that can be saved
                  to your mobile device.
                </li>
                <li>Copy the URL or QR code (while on your phone) and share it however you like!</li>
                <li>
                  Once your friend signs up using that URL or QR Code, your referral will be successful, earning both
                  you and your friend Points.
                  <ul>
                    <li>
                      Note: Your friend will know that he’s used the correct URL or QR Code if he/she sees an
                      “invitation message” that includes your ReneID profile.
                    </li>
                  </ul>
                </li>
              </ol>
            </>
          </ReferralsDropdown>
          <ReferralsDropdown header="I’ve referred a friend. How do I earn Points for this?">
            <>
              All ReneVerse users can earn Points by referring new users to sign up for their own ReneID. Upon referring
              a new user, the existing user who shared the referral code (i.e. “The Referrer”) will:
              <ol>
                <li>
                  Receive 2000 Points upon the successful sign-up/registration of every new user (i.e. “The Referee”)
                </li>
              </ol>
              Additionally, there are Point Bonuses that Referrers can receive for specific milestones.
              <ol>
                <li>Receive an additional 3000 Points upon the successful sign-up/registration of 5 total Referees.</li>
                <li>
                  Receive an additional 5000 Points upon the successful sign-up/registration of 10 total Referees.
                </li>
                <li>Receive an additional 2000 Points for each Referee that acquires 5000 Points in the Portal.</li>
              </ol>
              Similarly, new registrants (Referees) can earn 2000 Points upon successfully registering through the QR
              Code or Referral Link provided by his/her Referrer.
              <br />
              <br />
              <i>NOTES</i>
              <ul>
                <li>
                  To qualify for the referral bonus, the friend you refer must not have previously created a ReneVerse
                  account before the account was created with your invite link.
                </li>
                <li>
                  There is no limit to the number of friends you can refer, but there is a limit on the total amount of
                  referral bonuses you can receive.
                </li>
              </ul>
            </>
          </ReferralsDropdown>
          <ReferralsDropdown header="I’ve just signed up through a Referral URL/QR Code. How do I earn Points for this?">
            <>
              New users (Referees) who meet the following requirements will receive Points:
              <ol>
                <li>
                  Must complete the onboarding process on the ReneVerse Portal (app.reneverse.io) successfully, using a
                  valid referral link provided by a Referrer.
                </li>
                <li>Connect their X Account within “Profile” on the ReneID Settings page.</li>
                <li>Connect their Discord account within “Profile” on the ReneID Settings page.</li>
              </ol>
              As mentioned above, existing users (Referrers) will receive Points for their referral upon the successful
              registration of the new referred user (Referee).
            </>
          </ReferralsDropdown>
          <ReferralsDropdown
            isCollapsed={isCollapsed}
            setIsSectionCollapsed={setIsSectionCollapsed}
            header="How can I win the Founder’s Key NFT through Referrals?"
          >
            <>
              The top 3 ReneVerse users with the <b>most referrals</b> at the END of each Airdrop Period are entitled to
              a ReneVerse Founder’s Key NFT [hyperlink the NFT Page]. This leaderboard follows the same schedule as the
              $500K ReneVerse Pilot Airdrop and only 3 NFTs are up for grabs in each Period [18 in total]. The
              Tiers/Rarities of these NFTs will be determined at random. This is as follows:
              <ul>
                <li>Period 3 [May 6 – May 20] = 3 NFTs</li>
                <li>Period 4 [May 20 – June 3] = 3 NFTs</li>
                <li>Period 5 [June 3 – June 17] = 3 NFTs</li>
                <li>Period 6 [June 17 – July 1] = 3 NFTs</li>
                <li>Period 7 [July 1 – July 15] = 3 NFTs</li>
                <li>Period 8 [July 15 – July 29] = 3 NFTs</li>
              </ul>
              Referral Leaderboard winners in each Period will receive their NFT at the END of each Period and must
              connect a third-party wallet to receive the NFT from ReneVerse. This can be connected using the “Claim
              NFT” button (or type it in an open field). From there, ReneVerse will send the NFT directly to you.
            </>
          </ReferralsDropdown>
          <ReferralsDropdown header="When will I receive my Point Bonus?">
            After you have referred the required number of users & they complete the required registration steps, Points
            will be immediately allocated to your ReneID. The NFT Bonus for the top Referrers are distributed promptly
            at the end of each Airdrop Period.
          </ReferralsDropdown>
          <ReferralsDropdown header="How to keep track of my referral?">
            You can track who you have invited in the Referral Tab inside the ReneID Settings.
          </ReferralsDropdown>
          <ReferralsDropdown header="Why didn't I get my Referral Points & Bonuses?">
            <>
              You can always sign in to your account to check if your Points have been awarded to you. Here are some
              reasons why you didn’t get your Referral Points and/or Bonus:
              <ul>
                <li>
                  Your invitee (referee) must sign up directly through your unique link. Referrals are not captured if
                  the invited person signs up to the ReneVerse Portal without your link.{' '}
                </li>
                <li>
                  You can ensure that you’ve used the correct referral link by checking if there is a “Referrer” user
                  profile shown on your ReneVerse account registration page.
                </li>
              </ul>
              <i>NOTE:</i> If you sign up after the Airdrop has ended, there are no further rewards to be earned.
            </>
          </ReferralsDropdown>
        </section>
      </div>
    </div>
  );
};

export default Referrals;
