import Icon from '../Icon/Icon';
import './files-table.scss';

const FilesTable = ({ files }: { files: any }) => {
  return (
    <div className="files_table">
      <table>
        <thead>
          <tr>
            <th>File name</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file: any) => {
            return (
              <tr key={file.uploadUrl}>
                <td>{file.name}</td>
                <td>
                  <a href={file.url} target="_blank" rel="noreferrer" download={true}>
                    <Icon name="download" />
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FilesTable;
