import { Dispatcher, UserData } from '../../global/interfaces';
import avatar from '../../global/images/avatar.webp';

import './user.scss';

const User: React.FC<{
  user: UserData;
  setSelectedUser?: Dispatcher<UserData | undefined> | ((user: UserData) => void);
}> = ({ user, setSelectedUser }) => {
  if (!setSelectedUser)
    return (
      <div className="user">
        <img src={user.image?.url || avatar} alt="user" />
        <p>{`${user.data.firstName} ${user.data.lastName}`}</p>
      </div>
    );
  return (
    <button name={user.email} className="user select" type="button" onClick={() => setSelectedUser(user)}>
      <img src={user.image?.url || avatar} alt="user" />
      <p>{`${user.data.firstName} ${user.data.lastName}`}</p>
    </button>
  );
};

export default User;
