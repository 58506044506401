import { useReneQuery } from '../../../hooks';
import { GAMES, ORG, SETTINGS } from '../../../global/routes';
import { UserRole } from '../../../global/consts';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USER_BALANCE_AND_TRANSACTIONS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { MenuItems, UserData } from '../../../global/interfaces';
import Icon from '../../../components/Icon/Icon';
import Credit from '../../organization/organization-settings-page/credits/credit';
import AsideMenu from '../../../components/aside-menu/aside-menu';
import WalletSettings from './wallet-settings/wallet-settings';
import ProfileSettings from './profile-settings/profile-settings';
import PointsAndRewards from '../points-and-rewards/points-and-rewards';
import Referrals from './referrals/referrals';

import './profile-settings-page.scss';
import AsidePopupMenu from '../../../components/aside-popup-menu/aside-popup-menu';
import { useState } from 'react';

const menuItems: MenuItems = {
  profile: { name: 'profile', icon: 'user', label: 'Profile' },
  points: { name: 'points', icon: 'trophy', label: 'Points & Rewards' },
  wallet: { name: 'wallet', icon: 'wallet', label: 'Wallet' },
  credit: { name: 'credit', icon: 'credit', label: 'Credit' },
  referrals: { name: 'referrals', icon: 'referral', label: 'Referrals' }
};

const ProfileSettingsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data, refetch } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const handleMenuItemChange = (value: string) => {
    navigate(`/${SETTINGS}/${menuItems[value].name}`);
  };

  const renderSelectedMenuItem = (selectedMenuItem: string | undefined) => {
    switch (selectedMenuItem) {
      case 'profile':
        return <ProfileSettings user={data?.User} refetch={refetch} setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'points':
        return <PointsAndRewards setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'wallet':
        return <WalletSettings setOpenMobileMenu={setIsMobileMenuOpen} />;
      case 'credit':
        return (
          <Credit
            query={GET_USER_BALANCE_AND_TRANSACTIONS_QUERY}
            redirectPage="settings"
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );
      case 'referrals':
        return (
          <Referrals setOpenMobileMenu={setIsMobileMenuOpen} />
        );
      default:
        break;
    }
  };

  return (
    <div className="profile_settings_page">
      <div className="profile_settings_page__back">
        <button
          type="button"
          onClick={() => {
            if (data?.User.role === UserRole.GAMER) {
              navigate(`/${GAMES}`);
            } else {
              navigate(`/${ORG}`);
            }
          }}
        >
          <Icon name="chevron-left" size={16} />
          <p>Back</p>
        </button>
      </div>
      <div className="profile_settings_page__main">
        <AsidePopupMenu isMenuOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
          <AsideMenu
            items={menuItems}
            selectedItem={params.menuItem as string}
            handleItemSelect={handleMenuItemChange}
          />
        </AsidePopupMenu>
        <section className="profile_settings_page__main_section">{renderSelectedMenuItem(params.menuItem)}</section>
      </div>
    </div>
  );
};

export default ProfileSettingsPage;
