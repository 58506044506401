import { useState, useEffect } from 'react';
import { AttributesData, Dispatcher, Event } from '../../../global/interfaces';
import Input from '../../input/input';
import './attribute-type-percentage.scss';

const AttributeTypePercentage = ({
  attribute,
  setAttributeList,
  mode = 'value',
  errorMessage,
  disabled = false,
}: {
  attribute: AttributesData;
  setAttributeList: Dispatcher<AttributesData[] | []>;
  mode?: 'add' | 'range' | 'value';
  errorMessage?: string;
  disabled?: boolean;
}) => {
  const [prevPrevAttribute, setPrevAttribute] = useState<AttributesData>();
  const [percentage, setPercentage] = useState(50);

  if (attribute !== prevPrevAttribute && attribute.values[0]) {
    setPrevAttribute(attribute);
    setPercentage(parseInt(attribute.values[0]));
  }

  const handleSetPercentage = (e: Event['Input']) => {
    const num = parseInt(e.target.value);
    if (num > 100 || num < 0) return;
    setPercentage(num);
  };

  useEffect(() => {
    setAttributeList((prev) => {
      return prev.map((attr) => {
        if (attr.traitType === attribute.traitType) {
          return {
            ...attr,
            values: [`${percentage}`],
          };
        }
        return attr;
      });
    });
  }, [attribute.traitType, percentage, setAttributeList]);

  return (
    <>
      <div className="attribute_type_percentage">
        <div className={`attribute_type_percentage__round ${disabled ? 'disabled' : ''}`}>
          <Input type="number" value={percentage} handleInput={handleSetPercentage} disabled={disabled} />
          <p>%</p>
        </div>
        <div className="attribute_type_percentage__slider">
          <span>0%</span>
          <Input
            type="range"
            min={0}
            max={100}
            value={percentage}
            hideErrorMessage
            handleInput={handleSetPercentage}
            disabled={disabled}
          />
          <span>{isNaN(percentage) ? null : percentage}%</span>
        </div>
      </div>
      {errorMessage && <p className="error-msg">{errorMessage}</p>}
    </>
  );
};

export default AttributeTypePercentage;
