import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyReneQuery } from '../../hooks';
import { Content, Event, ContentSearchData } from '../../global/interfaces';
import { DocumentNode } from 'graphql';
import Icon from '../Icon/Icon';
import Search from '../search/search';
import Spinner from '../spinner/spinner';

import './search-content-values.scss';

const AddedValues = ({
  value,
  disabled,
  deleteTextAttribute,
}: {
  value: Content;
  disabled: boolean | undefined;
  deleteTextAttribute: (e: Event['Button']) => void;
}) => {
  return (
    <div className="search-values__main_value">
      <p>{value.name}</p>
      {!disabled && (
        <button id={value.taxonomyContentId} type="button" onClick={deleteTextAttribute} disabled={disabled}>
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};

export const SearchContentValues = ({
  query,
  label,
  values,
  errorMessage,
  disabled,
  deleteValueHandler,
  changeHandler,
}: {
  query: DocumentNode;
  label?: string;
  values: Content[];
  errorMessage?: string;
  disabled?: boolean;
  deleteValueHandler: (value: string) => void;
  changeHandler: (value: Content) => void;
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState('');
  const [alreadyExists, setAlreadyExists] = useState<string>('');
  const [addValue, setAddValue] = useState(false);

  const [search, { data, loading }] = useLazyReneQuery<{
    ContentSearch: ContentSearchData;
  }>(query);

  const showSearchData = searchTerm && data ? data.ContentSearch.items : [];

  useEffect(() => {
    const handleOuterClick = (event: { target: any }) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setAddValue(false);
      }
    };

    document.addEventListener('mousedown', handleOuterClick);
    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [inputRef]);

  useEffect(() => {
    if (searchTerm) {
      search({ variables: { contentSearchTerm: searchTerm } });
    }
  }, [searchTerm, search]);

  const handleSearch = useCallback((data: any) => {
    setSearchTerm(data);
  }, []);

  const handleAddGenre = (value: Content) => {
    const doesExist = values?.find((searchValue) => searchValue.name === value.name);
    if (doesExist) {
      return setAlreadyExists('This name already exists');
    }
    if (alreadyExists) {
      setAlreadyExists('');
    }
    if (searchRef.current) {
      searchRef.current.focus();
    }

    changeHandler(value);
    setSearchTerm('');
  };

  const deleteValue = (e: Event['Button']) => {
    const { id } = e.currentTarget;
    deleteValueHandler(id);
  };

  return (
    <div className="search-content-values">
      <div className="search-content-values__heading">{label ? <label>{label}</label> : null}</div>
      <div className="search-content-values__main">
        {values?.map((value) => (
          <AddedValues
            value={value}
            key={value.taxonomyContentId}
            deleteTextAttribute={deleteValue}
            disabled={disabled}
          />
        ))}
        <div className="search-content-values__main_input" ref={inputRef}>
          {addValue ? (
            <div className="search-content-values__main_input_search">
              {loading ? <Spinner size="sm" /> : null}
              <Search
                key={values?.length}
                ref={searchRef}
                callback={handleSearch}
                disabled={disabled}
                apiSearch
                autoFocus
              />
              {showSearchData.length ? (
                <div className="search-content-values__main_input_select">
                  {showSearchData.map((value: any, i: number) => (
                    <button key={i} onClick={() => handleAddGenre(value)}>
                      {value.name}
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          ) : (
            !disabled && (
              <button onClick={() => setAddValue(true)}>
                <Icon name="plus" size={16} /> <p>New value</p>
              </button>
            )
          )}
        </div>
      </div>
      <p className="search-content-values__error">{alreadyExists || errorMessage}</p>
    </div>
  );
};
