import { useEffect, useRef, useState } from 'react';
import { Event } from '../../global/interfaces';
import { handleKeyPressSubmit } from '../../utils';
import Icon from '../Icon/Icon';
import Input from '../input/input';

import './input-values.scss';

const AddedValues = ({
  value,
  disabled,
  deleteTextAttribute,
}: {
  value: string;
  disabled: boolean | undefined;
  deleteTextAttribute: (e: Event['Button']) => void;
}) => {
  return (
    <div className="input-values__main_value">
      <p>{value}</p>
      {!disabled && (
        <button name={value} type="button" onClick={deleteTextAttribute} disabled={disabled}>
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};

export const InputValues = ({
  label,
  values,
  errorMessage,
  disabled,
  deleteValueHandler,
  changeHandler,
}: {
  label?: string;
  values: string[];
  errorMessage?: string;
  disabled?: boolean;
  deleteValueHandler: (value: string) => void;
  changeHandler: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string>('');
  const [alreadyExists, setAlreadyExists] = useState<string>('');
  const [addValue, setAddValue] = useState(false);

  useEffect(() => {
    const handleOuterClick = (event: { target: any }) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setAddValue(false);
      }
    };

    document.addEventListener('mousedown', handleOuterClick);
    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [inputRef]);

  const handleAddAttribute = () => {
    const doesExist = values.find((attr) => attr === value);
    if (doesExist) {
      return setAlreadyExists('This attribute name already exists');
    }
    changeHandler(value);
    setValue('');
  };

  const deleteValue = (e: Event['Button']) => {
    const { name } = e.currentTarget;
    deleteValueHandler(name);
  };

  return (
    <>
      <div className="input-values__heading">{label ? <label>{label}</label> : null}</div>
      <div className="input-values__main">
        {values.map((value) => (
          <AddedValues value={value} key={value} deleteTextAttribute={deleteValue} disabled={disabled} />
        ))}
        {!disabled && (
          <div className="input-values__main_input" ref={inputRef}>
            {addValue ? (
              <Input
                type="text"
                value={value}
                handleInput={(e) => {
                  if (alreadyExists) setAlreadyExists('');
                  setValue(e.target.value);
                }}
                onKeyDown={(e) => handleKeyPressSubmit(e, handleAddAttribute)}
                hideErrorMessage
                disabled={disabled}
                autoFocus
              />
            ) : (
              <button onClick={() => setAddValue(true)}>
                <Icon name="plus" size={16} /> <p>New value</p>
              </button>
            )}
          </div>
        )}
      </div>
      <p className="input-values__error">{alreadyExists || errorMessage}</p>
    </>
  );
};
