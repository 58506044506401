import { Dispatcher, UserData } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import './transfer-org-ownership-modal.scss';

const TransferOrgOwnershipModal = ({
  member,
  orgName,
  setIsModalOpen,
}: {
  member: UserData | undefined;
  orgName: string | undefined;
  setIsModalOpen: Dispatcher<'remove' | 'transfer' | 'invite' | undefined>;
}) => {
  return (
    <div className="transfer-org-ownership-modal">
      <div className="transfer-org-ownership-modal__heading">
        <h2>Transfer ownership</h2>
        <button type="button" onClick={() => setIsModalOpen(undefined)}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="transfer-org-ownership-modal__main">
        <p>
          Are you sure want to make
          <span>{` ${member?.data.firstName} ${member?.data.lastName} `}</span>
          the owner of <span>{` ${orgName}`}</span>? You can not undo this action?
        </p>
      </div>
      <div className="transfer-org-ownership-modal__actions">
        <button type="button" className="secondary-btn" onClick={() => setIsModalOpen(undefined)} disabled={false}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={() => {}}>
          {false ? <Spinner size="sm" /> : 'Transfer'}
        </button>
      </div>
    </div>
  );
};

export default TransferOrgOwnershipModal;
