import { Link } from 'react-router-dom';
import { AssetData, Event } from '../../../global/interfaces';
import AssetsList from '../../asset/assets-list/assets-list';
import Icon from '../../Icon/Icon';
import placeholder from '../../../global/images/avatar.webp';

import './big-game-bar.scss';

interface BigGameBarProps {
  linkTo: string;
  name?: string;
  imageUrl?: string;
  description?: string;
  assets: AssetData[];
  handleSelectAsset: (e: Event['Button']) => void;
}

const BigGameBar: React.FC<BigGameBarProps> = ({ linkTo, name, imageUrl, description, assets, handleSelectAsset }) => {
  const gameBarContent = (): React.ReactElement => (
    <div className="big_game_bar">
      <div className="big_game_bar__image">
        <img src={imageUrl || placeholder} alt="game poster" />
      </div>
      <div className="big_game_bar__info">
        <div className="big_game_bar__info_about">
          <h2>{name}</h2>
          <h3>{description}</h3>
        </div>
        <AssetsList assetsList={assets} handleSelectAsset={handleSelectAsset} />
      </div>
      {assets?.length ? (
        <button className="secondary-btn big_game_bar__action">
          View all assets <Icon name="chevron-right" size={12} />
        </button>
      ) : null}
    </div>
  );
  return <Link to={linkTo}>{gameBarContent()}</Link>;
};

export default BigGameBar;
