import { useState } from 'react';
import { AssetData, Event, UserData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import { GET_USER_WITH_ASSETS_QUERY } from '../../../global/gql/queries';
import Icon from '../../Icon/Icon';
import Modal from '../modal';
import avatar from '../../../global/images/avatar.webp';
import AssetModal from '../asset-modal/asset-modal';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import './player-modal.scss';

interface Props {
  gameId: string;
  player: UserData;
  setCloseModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const PlayerModal: React.FC<Props> = ({ player, gameId, setCloseModal }) => {
  const [isAssetModalOpen, setIsAssetModalOpen] = useState<boolean>(false);
  const [selectedAsset, setSelectedAsset] = useState<string>('');

  const { data, loading } = useReneQuery<{ User: UserData } | undefined>(GET_USER_WITH_ASSETS_QUERY, {
    variables: { userId: player.userId, gameId },
  });

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };

  const handleSelectAsset = (e: Event['Button']) => {
    const target = e.currentTarget as HTMLButtonElement;
    setSelectedAsset(target.name as AssetData['assetId']);
    setIsAssetModalOpen(true);
  };

  return (
    <div className="player_modal">
      <button type="button" onClick={handleModal}>
        <Icon name="close" size={24} />
      </button>
      <div className="player_modal__bio">
        <div>
          <img src={player?.image?.url || avatar} alt="player" />
        </div>
        <div>
          <p>{`${player?.data?.firstName} ${player?.data?.lastName}`}</p>
          <p>{player?.email}</p>
          <a href={player?.externalAccounts?.discordId}>
            <Icon name="discord" />
          </a>
          <a href={player?.externalAccounts?.steamId}>
            <Icon name="steam" />
          </a>
        </div>
      </div>
      <div className="player_modal__assets">
        <h3>Assets</h3>
        <div className="player_modal__assets__list">
          {!loading && data ? (
            data?.User.assets.items.map((asset) => (
              <button
                key={asset.assetId}
                name={asset.assetId}
                className="player_modal__assets__list__asset"
                onClick={handleSelectAsset}
              >
                <img src={asset.metadata.image || avatar} alt="asset" />
              </button>
            ))
          ) : (
            <LoadingFallback />
          )}
        </div>
      </div>
      <Modal isOpen={isAssetModalOpen}>
        <AssetModal closeModal={() => setIsAssetModalOpen(false)} assetId={selectedAsset} />
      </Modal>
    </div>
  );
};

export default PlayerModal;
