import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatcher } from '../../global/interfaces';
import './catalog-popup.scss';

const CatalogPopup = ({
  children,
  isMenuOpen,
  setIsOpen,
}: {
  isMenuOpen: boolean;
  setIsOpen: Dispatcher<boolean>;
  children: React.ReactNode;
}) => {
  const params = useParams();

  useEffect(() => {
    setIsOpen(false);
  }, [params.menuItem, setIsOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isMenuOpen]);

  return (
    <aside className={`catalog-popup ${isMenuOpen ? 'catalog-popup--open' : ''}`}>
      <div className="catalog-popup__menu">{children}</div>
    </aside>
  );
};

export default CatalogPopup;
