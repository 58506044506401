import { commonValidations } from '../../../../global/validations';

export const validations = {
  firstName: commonValidations.name(),
  lastName: commonValidations.name(),
  username: commonValidations.username(),
  email: commonValidations.email(),
  password: commonValidations.password(),
  confirmNewPassword: (form: { [k: string]: any }, key: string) => {
    if (!form[key]) return 'This field is required';
    if (form[key] !== form.password) return "The passwords don't match";
  },
};
