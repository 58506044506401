import { forwardRef, useCallback, useEffect, useState } from 'react';
import { debounce } from '../../utils';
import Icon from '../Icon/Icon';
import './search.scss';

interface Props {
  callback: (param: any) => void;
  data?: any;
  prop?: string;
  placeholder?: string;
  apiSearch?: boolean;
  label?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const Search = forwardRef<HTMLInputElement, Props>(
  ({ callback, data, prop, autoFocus, placeholder = 'Search', apiSearch, label, disabled }, ref) => {
    const [searchTerm, setSearchTerm] = useState<string>('');

    const filterSearch = useCallback(
      (data: any, prop: string) => {
        const tokens = searchTerm
          .toLowerCase()
          .split(' ')
          .filter(function (token) {
            return token.trim() !== '';
          });
        if (tokens.length && data) {
          const searchTermRegex = new RegExp(tokens.join('|'), 'gim');
          const filteredData = data.filter(function (item: any) {
            return item[prop].match(searchTermRegex);
          });

          return filteredData;
        }
      },
      [searchTerm],
    );

    useEffect(() => {
      if (!apiSearch && data && prop) {
        callback(filterSearch(data, prop));
      }
    }, [callback, data, filterSearch, prop, searchTerm, apiSearch]);

    useEffect(() => {
      if (apiSearch) callback(searchTerm);
    }, [apiSearch, callback, searchTerm]);

    return (
      <div className="search-container">
        {label ? <label>{label}</label> : null}
        <div className="search-container__inner">
          <div className={`search-container__inner_search ${disabled ? 'disabled' : ''}`}>
            <Icon name="search" size={16} />
            <input
              ref={ref}
              type="text"
              placeholder={placeholder}
              onChange={(e) => debounce(() => setSearchTerm(e.target.value), 1000)()}
              disabled={disabled}
              autoFocus={autoFocus}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default Search;
