import { Link } from 'react-router-dom';
import { BrandedAssetData } from '../../../global/interfaces';
import OrgAssetCard from '../org-asset-card/org-asset-card';
import placeholder from '../../../global/images/asset-placeholder.png';
import './branded-asset-list.scss';

const BrandedAssetList = ({ brandedAssetList }: { brandedAssetList: BrandedAssetData[] | undefined }) => {
  if (!brandedAssetList || !brandedAssetList.length) return null;
  return (
    <div className="branded-asset-list">
      {brandedAssetList.map((brandedAsset: BrandedAssetData) => {
        return (
          <Link key={brandedAsset?.brandedAssetId} to={`${brandedAsset.brandedAssetId}/files`}>
            <OrgAssetCard image={brandedAsset?.image?.url || placeholder} title={brandedAsset?.name}>
              <div className="branded-asset-list__stats" key={brandedAsset.brandedAssetId}>
                <p>Branded</p>
                <div>
                  <img src={brandedAsset?.brand?.image?.url || placeholder} alt="brand" />
                  <p>{brandedAsset?.brand?.name}</p>
                </div>
                <div>
                  <p>Used in games:</p>
                  <p>{brandedAsset?.stats?.games}</p>
                </div>
              </div>
            </OrgAssetCard>
          </Link>
        );
      })}
    </div>
  );
};

export default BrandedAssetList;
