import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  OwnableAssetData,
  TransactionModalData,
  Event,
  Dispatcher,
  GamesData,
  OrganizationData,
  UserData,
  Refetch,
  CollectionsData,
} from '../../../global/interfaces';
import { OWNABLE_ASSETS, ORG, GAMES, COLLECTIONS } from '../../../global/routes';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { UPSERT_OWNABLE_ASSET_MUTATION } from '../../../global/gql/mutations';
import { GET_GAMES_QUERY, GET_ORG_QUERY, GET_USER_QUERY, createCollectionsQuery } from '../../../global/gql/queries';
import { isRoleAllowed } from '../../../utils';
import { validations } from './validations';
import { AssetType, UserRole } from '../../../global/consts';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import Modal from '../../modal/modal';
import placeholder from '../../../global/images/asset-placeholder.png';
import EditableImage from '../../editable-image/editable-image';
import LoadingFallback from '../../loading-fallback/loading-fallback';
import OwnableAssetState from '../../ownable-asset-state/ownable-asset-state';
import SingleAssetMintModal from '../../modal/single-asset-mint-modal/single-asset-mint-modal';
import TransactionInitiatedModal from '../../modal/transaction-initiated-modal/transaction-initiated-modal';
import BulkAssetMintFileModal from '../../modal/bulk-asset-mint-file-modal/bulk-asset-mint-file-modal';
import BulkAssetMintRandomModal from '../../modal/bulk-asset-mint-random-modal/bulk-asset-mint-random-modal';

import './ownable-asset-banner.scss';

interface Props {
  ownableAsset: OwnableAssetData | undefined;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
}

const OwnableAssetBanner: React.FC<Props> = ({ ownableAsset, refetch }) => {
  const params = useParams();
  const [isShrunken, setIsShrunken] = useState(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [isMintModalOpen, setIsMintModalOpen] = useState<boolean>(false);
  const [transactionModal, setTransactionModal] = useState<TransactionModalData>({
    show: false,
  });
  const [isBulkMintFileModalOpen, setIsBulkMintFileModalOpen] = useState<boolean>(false);
  const [isBulkMintRandomModalOpen, setIsBulkMintRandomModalOpen] = useState<boolean>(false);

  const { data: selectedGame } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const { data: org } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY, {
    fetchPolicy: 'network-only',
  });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const { data: collection } = useReneQuery<{
    Collections: CollectionsData;
  }>(createCollectionsQuery(AssetType.OWNABLE), {
    variables: {
      collectionId: params.collectionId,
    },
  });

  const allowUpsertOwnableAsset = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);

  const hasBalance = useMemo(() => {
    const { balance } = org?.Organization?.balance || {};
    return !!balance && +balance > 0;
  }, [org?.Organization?.balance]);

  // const bulkMintOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
  //   () => [
  //     {
  //       key: 0,
  //       label: 'Randomized',
  //       func: setIsBulkMintRandomModalOpen,
  //     },
  //     {
  //       key: 1,
  //       label: 'From File',
  //       func: setIsBulkMintFileModalOpen,
  //     },
  //   ],
  //   [],
  // );

  const ownableAssetOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Edit',
        icon: <Icon name="edit" />,
        func: setEdit,
      },
    ],
    [],
  );

  const handleOwnableAssetAction = useCallback(
    (index: number) => {
      ownableAssetOptions[index].func(true);
    },
    [ownableAssetOptions],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsShrunken(true);
      } else {
        setIsShrunken(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mintSingleAsset = () => {
    setIsMintModalOpen(true);
  };

  // const handleMintBulkSelect = useCallback(
  //   (index: number) => {
  //     bulkMintOptions[index].func(true);
  //   },
  //   [bulkMintOptions],
  // );

  const ownableAssetActions = () => (
    <div className="ownable-asset__banner_actions">
      {selectedGame?.Games.items[0]?.chain && (
        <button className="secondary-btn" onClick={mintSingleAsset} type="button">
          Mint Asset
        </button>
      )}
      {/* <div className="button-options">
        <Select
          value="Mint Bulk Assets"
          options={{ ...bulkMintOptions.map(({ label }) => label) }}
          changeHandler={handleMintBulkSelect}
        />
      </div> */}
      <Select
        className="ownable-asset__banner_actions_dots"
        value=""
        placeholder=""
        options={ownableAssetOptions}
        changeHandler={handleOwnableAssetAction}
        showListValueFn={(option) => {
          return (
            <>
              {option.icon} {option.label}
            </>
          );
        }}
      >
        <Icon name="dots" size={44} />
      </Select>
    </div>
  );

  return (
    <div className={`ownable-asset ${isShrunken && 'ownable-asset--shrunken'}`}>
      <div className="ownable-asset__breadcrumbs_mobile">
        <Link to={`/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}/${params.collectionId}/${OWNABLE_ASSETS}`}>
          <Icon name="chevron-left" />
          <p>Back</p>
        </Link>
      </div>
      <div className={`ownable-asset__banner${isShrunken ? '--shrunken' : '--full'}`}>
        <img src={ownableAsset?.image?.url || placeholder} alt="ownable asset" />
        <div>
          <p>{collection?.Collections.items[0].name} </p>
          <p>{ownableAsset?.name} </p>
        </div>
        {ownableAssetActions()}
      </div>
      <div className="ownable-asset__banner">
        <img src={ownableAsset?.image?.url || placeholder} alt="ownable asset" />
        {ownableAsset ? (
          <>
            <div className="ownable-asset__banner_heading">
              <p className="ownable-asset__banner_heading_game">{collection?.Collections.items[0].name}</p>
              <p className="ownable-asset__banner_heading_name">{ownableAsset.name}</p>
            </div>
            <OwnableAssetState
              derived={ownableAsset.parentOwnableAssetId}
              crossGameState={ownableAsset.crossGameState}
              inGames={ownableAsset?.games?.items.length}
              supply={ownableAsset?.data?.supply}
            />
            <div className="ownable-asset__banner_description">
              <p>{ownableAsset.data.description} </p>
            </div>
            {allowUpsertOwnableAsset && ownableAssetActions()}
          </>
        ) : (
          <LoadingFallback />
        )}
        {ownableAsset && (
          <>
            <Modal isOpen={isMintModalOpen}>
              <SingleAssetMintModal
                ownableAsset={ownableAsset}
                isMainChainActive={selectedGame?.Games.items[0].chain?.mainnet?.isActive}
                setIsMintModalOpen={setIsMintModalOpen}
                setTransactionModal={setTransactionModal}
                isMintingAllowed={hasBalance}
              />
            </Modal>
            <Modal isOpen={isBulkMintFileModalOpen}>
              <BulkAssetMintFileModal
                ownableAsset={ownableAsset}
                isMainChainActive={selectedGame?.Games.items[0].chain?.mainnet?.isActive}
                setIsBulkMintFileModalOpen={setIsBulkMintFileModalOpen}
                setTransactionModal={setTransactionModal}
                isMintingAllowed={hasBalance}
              />
            </Modal>
            <Modal isOpen={isBulkMintRandomModalOpen}>
              <BulkAssetMintRandomModal
                ownableAsset={ownableAsset}
                isMainChainActive={selectedGame?.Games.items[0].chain?.mainnet?.isActive}
                setIsBulkMintRandomModalOpen={setIsBulkMintRandomModalOpen}
                setTransactionModal={setTransactionModal}
                isMintingAllowed={hasBalance}
              />
            </Modal>
            <Modal isOpen={transactionModal.show}>
              <TransactionInitiatedModal
                text={transactionModal?.msg}
                type="minting"
                setCloseModal={setTransactionModal}
              />
            </Modal>
            <EditOwnableAssetModal
              ownableAsset={ownableAsset}
              isOpen={isEdit}
              closeModal={() => setEdit(false)}
              refetch={refetch}
            />
          </>
        )}
      </div>
    </div>
  );
};

const EditOwnableAssetModal = ({
  ownableAsset,
  isOpen,
  closeModal,
  refetch,
}: {
  ownableAsset: OwnableAssetData;
  isOpen: boolean;
  closeModal: () => void;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
}) => {
  const uploadImage = useFileUpload();

  const { errors, isFormInvalid } = useValidation(validations);

  const [file, setFile] = useState<File>();
  const [form, setForm] = useState<{ name: string; description: string; image: string }>({
    name: '',
    description: '',
    image: '',
  });
  const [prevOwnableAsset, setPrevOwnableAsset] = useState<OwnableAssetData | undefined>();

  const [upsertOwnableAsset, { loading: upsertLoading }] = useReneMutation(UPSERT_OWNABLE_ASSET_MUTATION, {
    onCompleted(data: { UpsertOwnableAsset: OwnableAssetData }) {
      if (file) {
        uploadImage(data.UpsertOwnableAsset.image.uploadUrl, file).then(() => {
          refetch();
          closeModal();
        });
      } else {
        refetch();
        closeModal();
      }
    },
  });

  if (ownableAsset && !prevOwnableAsset && ownableAsset !== prevOwnableAsset) {
    setForm((prev) => ({
      ...prev,
      name: ownableAsset.name,
      description: ownableAsset.data.description,
      image: ownableAsset.image.url,
    }));
    setPrevOwnableAsset(ownableAsset);
  }

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveEdit = () => {
    if (isFormInvalid(form)) return;
    if (!ownableAsset?.ownableAssetId) return;
    let variables: {
      name: string;
      ownableAssetId: string;
      updatedAt: string;
      image?: { extension: string; fileId: string };
      data?: { description?: string };
    } = {
      name: form.name,
      data: {
        description: form.description,
      },
      ownableAssetId: ownableAsset.ownableAssetId,
      updatedAt: ownableAsset.updatedAt,
    };

    variables = file
      ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: ownableAsset.image.fileId } }
      : variables;

    upsertOwnableAsset({ variables });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="ownable-asset-edit-modal">
        <div className="ownable-asset-edit-modal__heading">
          <h2>Edit Ownable Asset</h2>
          <button type="button" onClick={closeModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <Input
          label="Name"
          name="name"
          placeholder="Enter game name"
          handleInput={handleFormChange}
          value={form.name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          name="description"
          value={form.description}
          handleInput={handleFormChange}
          placeholder="Enter game description"
          showCounter
          maxLength={100}
          errorMessage={errors?.description}
        />
        <EditableImage imageUrl={form.image} alt="game" setFile={setFile} />
        <div className="ownable-asset-edit-modal__asset_actions">
          <button type="button" className="secondary-btn" onClick={closeModal} disabled={upsertLoading}>
            Cancel
          </button>
          <button type="button" className="primary-btn" onClick={handleSaveEdit} disabled={upsertLoading}>
            {upsertLoading ? <Spinner size="sm" /> : 'Update'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OwnableAssetBanner;
