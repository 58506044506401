import { commonValidations } from '../../../../global/validations';
import { AdType, allowedImageTypes } from '../../../../global/consts';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  adType: commonValidations.required(),
  banner: (form: { [k: string]: any }, _key: 'file', field: File) => {
    if (form.adType === AdType.BANNER && !field) return 'This field is required';
    if (form.adType === AdType.BANNER && !allowedImageTypes.includes(field.type as string))
      return 'You must provide a valid image (jpg, png, webp) file';
  },
  video: (form: { [k: string]: any }, _key: 'video', field: File) => {
    if (form.adType === AdType.VIDEO && !field) return 'This field is required';
    if (form.adType === AdType.VIDEO && field?.type !== 'video/mp4')
      return 'You must provide a video format (mp4) file';
  },
  reachText: commonValidations.requiredTrimmed(),
};
