import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { CollectionData, CollectionsData, UserData } from '../../../../global/interfaces';
import { useLazyReneQuery } from '../../../../hooks';
import { COLLECTIONS_SEARCH_QUERY, GET_USER_QUERY, createCollectionsQuery } from '../../../../global/gql/queries';
import { AssetType, UserRole } from '../../../../global/consts';
import { useReneQuery } from '../../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../../utils';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import Select from '../../../../components/select/select';
import Search from '../../../../components/search/search';
import GridBar from '../../../../components/grid-bar/grid-bar';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import NewCollectionModal from '../../../../components/modal/new-collection-modal/new-collection-modal';
import collPlaceholder from '../../../../global/images/collection-placeholder.png';

import './collections-page.scss';

const CollectionsPage = () => {
  const params = useParams();
  const [isNewCollectionModalOpen, setIsNewCollectionModalOpen] = useState<boolean>(false);
  const [collectionSearchTerm, setSearchTerm] = useState('');

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const {
    data: collectionsData,
    refetch: refetchCollections,
    loading: collectionsLoading,
  } = useReneQuery<{ Collections: CollectionsData }>(createCollectionsQuery(params?.type as AssetType), {
    variables: { type: params?.type?.toLocaleUpperCase() },
  });

  const [search, { data: searchCollections, loading: searchLoading }] = useLazyReneQuery<{
    CollectionSearch: CollectionsData;
  }>(COLLECTIONS_SEARCH_QUERY);

  useEffect(() => {
    if (collectionSearchTerm) {
      search({ variables: { collectionSearchTerm, collectionType: 'BRANDED' } });
    }
  }, [collectionSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const showCollectionsData =
    collectionSearchTerm && searchCollections ? searchCollections.CollectionSearch : collectionsData?.Collections;

  const allowedRole = params.type === AssetType.BRANDED ? [UserRole.CREATOR] : [UserRole.DEVELOPER];
  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, allowedRole);

  const collections = () => {
    return showCollectionsData?.items.map((collection: CollectionData) => (
      <GridBar
        key={collection.collectionId}
        linkTo={`${collection.collectionId}`}
        img={collection?.image?.url}
        title={collection?.name}
        stats={collection?.stats}
        type={AssetType.BRANDED}
        brandName={collection?.brand?.name}
        placeholderImg={collPlaceholder}
      />
    ));
  };

  return (
    <div className="collections-page">
      <div className="collections-page__heading">
        <div className="collections-page__heading_title">
          <h1>
            Asset Collections <span>{showCollectionsData?.items.length}</span>
          </h1>
          <div>
            <p>
              <span> {showCollectionsData?.items[0]?.stats?.assets}</span> Assets
            </p>
          </div>
        </div>
        {isUserAllowed && (
          <button
            type="button"
            className="primary-btn  collections-page__heading_new"
            onClick={() => setIsNewCollectionModalOpen(true)}
          >
            <Icon name="plus" size={24} />
            <p>New Collection</p>
          </button>
        )}
      </div>

      <div className="collections-page__content">
        <div className="collections-page__content_actions">
          <Search callback={handleSearch} apiSearch />
          <Select value="Latest" options={['Latest']} changeHandler={() => {}}>
            <Icon name="arrows-sort" size={16} />
          </Select>
        </div>
        <div className="collections-page__content_collections">
          {collectionsLoading || searchLoading ? <LoadingFallback /> : collections()}
        </div>
      </div>
      <Modal isOpen={isNewCollectionModalOpen}>
        <NewCollectionModal
          type={params.type as AssetType}
          callback={() => refetchCollections()}
          setCloseModal={() => setIsNewCollectionModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default CollectionsPage;
