import { Event } from '../../global/interfaces';
import './toggle.scss';

const Toggle: React.FC<{
  checked: boolean;
  setValue: (e: Event['Input']) => void;
  id?: string;
  name?: string;
  disabled?: boolean;
}> = ({ checked, setValue, id = 'toggle', name = 'toggle', disabled }) => {
  return (
    <div className="toggle">
      <input type="checkbox" id={id} checked={checked} onChange={setValue} name={name} disabled={disabled} />
      <label htmlFor={id}>Toggle</label>
    </div>
  );
};

export default Toggle;
