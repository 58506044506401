import { useCallback, useMemo, useState } from 'react';
import { AttributesData, Dispatcher, Event } from '../../../../../../../global/interfaces';
import { AttributeTypes } from '../../../../../../../global/consts';
import Icon from '../../../../../../../components/Icon/Icon';
import {
  AttributeTypeDate,
  AttributeTypeNumber,
  AttributeTypePercentage,
  AttributeTypeText,
} from '../../../../../../../components/attribute-types';
import Modal from '../../../../../../../components/modal/modal';
import DeleteModal from '../../../../../../../components/modal/delete-modal/delete-modal';

import './attribute.scss';
import Select from '../../../../../../../components/select/select';

interface Props {
  error?: any;
  attribute: AttributesData;
  isUserAllowedToUpsert: boolean;
  setAttributeList: Dispatcher<AttributesData[] | []>;
}

const Attribute: React.FC<Props> = ({ error, attribute, isUserAllowedToUpsert, setAttributeList }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpand, setExpand] = useState(false);
  const attributeConfig: {
    [K in AttributesData['displayType']]: JSX.Element;
  } = {
    string: (
      <AttributeTypeText
        attribute={attribute}
        setAttributeList={setAttributeList}
        errorMessage={error}
        disabled={!isUserAllowedToUpsert}
      />
    ),
    number: (
      <AttributeTypeNumber
        attribute={attribute}
        setAttributeList={setAttributeList}
        errorMessage={error}
        disabled={!isUserAllowedToUpsert}
      />
    ),
    boost_percentage: (
      <AttributeTypePercentage
        attribute={attribute}
        setAttributeList={setAttributeList}
        errorMessage={error}
        disabled={!isUserAllowedToUpsert}
      />
    ),
    date: (
      <AttributeTypeDate
        attribute={attribute}
        setAttributeList={setAttributeList}
        errorMessage={error}
        disabled={!isUserAllowedToUpsert}
      />
    ),
  };

  const ownableAssetOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Delete',
        icon: <Icon name="trash" />,
        func: setIsModalOpen,
      },
    ],
    [],
  );

  const handleOwnableAssetAction = useCallback(
    (index: number) => {
      ownableAssetOptions[index].func(true);
    },
    [ownableAssetOptions],
  );

  const deleteAttributeHandler = (e: Event['Button']) => {
    setAttributeList((prev) => {
      return prev.filter((attr) => attr.traitType !== attribute.traitType);
    });
  };

  const showValues = () => {
    if (attribute.displayType === 'boost_percentage') {
      return (
        <>
          <Icon name="elipse" size={4} />
          <p>{attribute.values[0]}%</p>
        </>
      );
    }

    if (attribute.displayType === 'string') {
      return (
        <p className="type-string">
          {attribute.values.map((value, i) => {
            if (i === attribute.values.length - 1) {
              return `${value}`;
            }
            return `${value}, `;
          })}
        </p>
      );
    }

    if (attribute.displayType === 'number') {
      return (
        <>
          <Icon name="elipse" size={4} />
          <p>{attribute.values[0]} </p>
          <span>-</span>
          <p> {attribute.values[1]}</p>
          {attribute.values[2] ? <p>default: {attribute.values[2]}</p> : null}
        </>
      );
    }

    if (attribute.displayType === 'date') {
      return (
        <>
          <Icon name="elipse" size={4} />
          <p>{attribute.values[0]} </p>
          <Icon name="elipse" size={4} />
          <p> {attribute.values[1]}</p>
          {attribute.values[2] ? <p>default: {attribute.values[2]}</p> : null}
        </>
      );
    }
  };

  return (
    <div className="attribute">
      <div className="attribute__heading">
        <div className="attribute__heading_left">
          <button onClick={() => setExpand(!isExpand)}>
            <Icon name={isExpand ? 'chevron-down' : 'chevron-right'} />
            <h3>{attribute.traitType}</h3>
          </button>
          <div>{showValues()}</div>
        </div>
        <div className="attribute__heading_right">
          <p>{AttributeTypes[attribute.displayType]}</p>
          {isUserAllowedToUpsert && (
            <Select
              className="attribute__heading_right_dots"
              value=""
              placeholder=""
              options={ownableAssetOptions}
              changeHandler={handleOwnableAssetAction}
              showListValueFn={(option) => {
                return (
                  <>
                    {option.icon} {option.label}
                  </>
                );
              }}
            >
              <Icon name="dots" />
            </Select>
          )}
        </div>
      </div>
      <div className={`attribute__main${isExpand ? '--expand' : ''}`}>{attributeConfig[attribute.displayType]}</div>
      <Modal isOpen={isModalOpen}>
        <DeleteModal text="this attribute" setIsModalOpen={setIsModalOpen} deleteHandler={deleteAttributeHandler} />
      </Modal>
    </div>
  );
};

export default Attribute;
