import { useState, useRef } from 'react';
import { OwnableAssetData, Dispatcher, Event, Refetch, TemplateMetadata } from '../../../../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../../../../hooks';
import { UPSERT_OWNABLE_ASSET_MUTATION } from '../../../../../../global/gql/mutations';
import { ApolloError } from '@apollo/client';
import { handleError } from '../../../../../../utils';
import { validations } from './validations';
import { WithAutosave } from '../../../../../../components/asset/ad-surface-list/with-autosave';
import { useErrorContext } from '../../../../../../context/error-context';
import { useUpdatePopUpContext } from '../../../../../../context/update-message-context';
import Icon from '../../../../../../components/Icon/Icon';
import Input from '../../../../../../components/input/input';
import Textarea from '../../../../../../components/textarea/textarea';
import TemplateSection from './template-section/template-section';
import MetadataTemplateOptions from '../../../../../../components/metadata-template-options/metadata-template-options';

import './template.scss';

const Template = ({
  ownableAsset,
  isUserAllowedToUpsert,
  setOpenMobileMenu,
  refetch,
}: {
  ownableAsset: OwnableAssetData | undefined;
  isUserAllowedToUpsert: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
}) => {
  const { setError } = useErrorContext();
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();
  const { errors, isFormInvalid } = useValidation(validations);
  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);
  const backgroundColorRef = useRef<HTMLInputElement>(null);

  const [activeField, setActiveField] = useState<'backgroundColor' | 'description' | 'name' | undefined>();
  const [prevOwnableAsset, setPrevOwnableAsset] = useState<OwnableAssetData>();
  const [ownableAssetForm, setOwnableAssetForm] = useState<TemplateMetadata>({
    backgroundColor: '',
    description: '',
    name: '',
  });
  const attributeTraits = ownableAsset?.attributes?.map((attr) => attr.traitType);

  const [upsertOwnableAsset] = useReneMutation(UPSERT_OWNABLE_ASSET_MUTATION, {
    onCompleted: () => {
      refetch();
    },
  });

  const refs: {
    name: React.RefObject<HTMLInputElement>;
    description: React.RefObject<HTMLTextAreaElement>;
    backgroundColor: React.RefObject<HTMLInputElement>;
  } = {
    name: nameRef,
    description: descriptionRef,
    backgroundColor: backgroundColorRef,
  };

  if (ownableAsset && !prevOwnableAsset) {
    setPrevOwnableAsset(ownableAsset);
    setOwnableAssetForm({
      name: ownableAsset?.metadataTemplates?.name || '',
      description: ownableAsset?.metadataTemplates?.description || '',
      backgroundColor: ownableAsset.metadataTemplates?.backgroundColor || '',
    });
  }

  const handleSave = () => {
    if (isFormInvalid(ownableAssetForm)) return;
    const variables = {
      ownableAssetId: ownableAsset?.ownableAssetId,
      updatedAt: ownableAsset?.updatedAt,
      metadataTemplates: {
        name: ownableAssetForm.name,
        description: ownableAssetForm.description,
      },
    };
    upsertOwnableAsset({ variables })
      .then(({ data, error }: { data: any; error: ApolloError }) => {
        if (error) throw error;
        if (data) showUpdatePopUpMessage('Changes saved successfully');
      })
      .catch((err: ApolloError) => {
        handleError(err, setError);
      });
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    setOwnableAssetForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.value[e.target.value.length - 1] === '@') {
      setActiveField(e.target.name as 'backgroundColor' | 'description' | 'name');
    } else if (activeField) {
      setActiveField(undefined);
    }
  };

  const handleOptionClick = (option: string) => {
    if (activeField) {
      setOwnableAssetForm((prev) => ({
        ...prev,
        [activeField]: ownableAssetForm[activeField] + '${' + option + '}',
      }));
      refs[activeField].current?.focus();
      setActiveField(undefined);
    }
  };

  return (
    <div className="template">
      <div className="template__heading">
        <div>
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
          <h1 className="rainbow-btn-text">TEMPLATE</h1>
        </div>
      </div>
      <div className="template__main">
        <TemplateSection title="Title">
          <Input
            inputRef={nameRef}
            type="text"
            name="name"
            value={ownableAssetForm?.name}
            handleInput={handleFormChange}
            errorMessage={errors?.name}
            disabled={!isUserAllowedToUpsert}
          />
          <>
            {activeField === 'name' ? (
              <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
            ) : null}
          </>
        </TemplateSection>
        <TemplateSection title="Description">
          <Textarea
            textAreaRef={descriptionRef}
            name="description"
            value={ownableAssetForm?.description}
            handleInput={handleFormChange}
            errorMessage={errors?.description}
            disabled={!isUserAllowedToUpsert}
          />
          <>
            {activeField === 'description' ? (
              <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
            ) : null}
          </>
        </TemplateSection>
      </div>
      {ownableAsset && <WithAutosave data={ownableAssetForm} handleSave={handleSave} />}
    </div>
  );
};
export default Template;
