export const validations = {
  files: (
    form: {
      files: File[];
      uploadedFileNames?: string[];
    },
    key: 'files',
  ) => {
    const files = Array.from(form?.[key] || []);
    if (!files.length) return 'This field is required';
    const uploadedFiles = form?.uploadedFileNames || [];

    let errorMessage = '';
    files.forEach(({ name }) => {
      if (uploadedFiles.includes(name)) errorMessage = 'The file(s) already exists';
    });

    return errorMessage;
  },
};
