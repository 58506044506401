import { useState } from 'react';
import { isPast } from 'date-fns';
import { useReneMutation } from '../../../../../hooks';
import { Dispatcher, GameData } from '../../../../../global/interfaces';
import { DOCS_LINK } from '../../../../../global/routes';
import {
  REMOVE_API_CREDENTIALS_MUTATION,
  GENERATE_API_CREDENTIALS_MUTATION,
} from '../../../../../global/gql/mutations';
import Icon from '../../../../../components/Icon/Icon';
import Modal from '../../../../../components/modal/modal';
import DeleteModal from '../../../../../components/modal/delete-modal/delete-modal';
import GenerateApiCredentialsModal from '../../../../../components/modal/generate-api-credentials-modal/generate-api-credentials-modal';

import './api-credentials.scss';

interface ApiKeys {
  apiKey: string;
  apiSecret: string;
  expiry: string;
  isTestNet: boolean;
}

const Keys = ({
  credentials,
  handleDeleteKeys,
}: {
  credentials: { apiKey: string; apiSecret: string; expiry: string; isTestNet: boolean };
  handleDeleteKeys: Dispatcher<string>;
}) => {
  const expiryDate = credentials.expiry && new Date(Number.parseInt(credentials.expiry)).toString();
  const isExpired = isPast(new Date(expiryDate));

  const copyAddress = async (key: string) => {
    await navigator.clipboard.writeText(key);
  };

  return (
    <>
      <div className="api-credentials__keys">
        <div className="api-credentials__keys_key">
          <div>
            <label>API Key</label>
            <div
              className={
                isExpired && expiryDate ? 'api-credentials__keys_key_expired' : 'api-credentials__keys_key_active'
              }
            >
              {isExpired && expiryDate ? 'Expired' : 'Active'}
            </div>
          </div>
          <div>
            <p>{credentials.apiKey}</p>
            <button className="tertiary-btn" type="button" onClick={() => copyAddress(credentials.apiKey)}>
              <Icon name="copy" size={24} />
            </button>
          </div>
        </div>
        {credentials.apiSecret && (
          <div className="api-credentials__keys_secret">
            <div>
              <label>API Secret</label>
            </div>
            <div>
              <p>**********************************</p>
              <button
                className="tertiary-btn"
                type="button"
                onClick={() => copyAddress(credentials.apiSecret as string)}
              >
                <Icon name="copy" size={24} />
              </button>
            </div>
          </div>
        )}
        <div className="api-credentials__keys_info">
          <div>
            {expiryDate ? (
              <p>
                {isExpired && expiryDate ? 'Expired' : 'Expiry'} on: {expiryDate}
              </p>
            ) : null}
          </div>
          <button type="button" onClick={() => handleDeleteKeys(credentials.apiKey)}>
            <Icon name="trash" size={16} />
            <p>Remove</p>
          </button>
        </div>
      </div>
    </>
  );
};

const ApiCredentials = ({
  game,
  isUserAllowedToUpsert,
}: {
  game: GameData | undefined;
  isUserAllowedToUpsert: boolean;
}) => {
  const [isCreateKeysModalOpen, setIsCreateKeyModalOpen] = useState(false);
  const [selectedKeysForDelete, setSelectedKeysForDelete] = useState('');
  const [prevKeys, setPrevKeys] = useState<ApiKeys[]>();
  const [keys, setKeys] = useState<ApiKeys[]>([]);

  if (game?.apiCredentials !== prevKeys && game?.apiCredentials.length) {
    setPrevKeys(game.apiCredentials);
    setKeys(game.apiCredentials);
  }

  const [generateApiKeys, { loading: loadingGenerateKeys }] = useReneMutation(GENERATE_API_CREDENTIALS_MUTATION, {
    onCompleted(data: {
      GenerateApiCredentials: { apiKey: string; apiSecret: string; expiry: string; isTestNet: boolean };
    }) {
      if (data) {
        setKeys((prev) => [...prev, data.GenerateApiCredentials]);
        setIsCreateKeyModalOpen(false);
      }
    },
  });

  const [removeApiKeys, { loading }] = useReneMutation(REMOVE_API_CREDENTIALS_MUTATION);

  const handleRemoveKeys = () => {
    if (!selectedKeysForDelete) return;
    removeApiKeys({
      variables: {
        apiKey: selectedKeysForDelete,
        gameId: game?.gameId,
      },
    }).then(({ data }: any) => {
      if (data) setKeys((prev) => prev.filter((key) => key.apiKey !== selectedKeysForDelete));
      setSelectedKeysForDelete('');
    });
  };

  const handleCloseDeleteModal = () => {
    setSelectedKeysForDelete('');
  };

  return (
    <div className="api-credentials">
      <div className="api-credentials__heading">
        <h2 className="rainbow-btn-text">API Credentials</h2>
        {isUserAllowedToUpsert && (
          <button className="secondary-btn" type="button" onClick={() => setIsCreateKeyModalOpen(true)}>
            Generate API Credentials
          </button>
        )}
      </div>
      <h3>
        This is list of API Credentials associated with <span>{game?.name}</span> game.
      </h3>
      {keys.length
        ? keys.map((keys) => <Keys key={keys.apiKey} credentials={keys} handleDeleteKeys={setSelectedKeysForDelete} />)
        : null}
      <p className="api-credentials__docs">
        For getting started guides and SDK download links, please check our{' '}
        <a href={DOCS_LINK} target="_blank" rel="noreferrer">
          Documentation
        </a>
      </p>
      <Modal isOpen={isCreateKeysModalOpen}>
        <GenerateApiCredentialsModal
          gameId={game?.gameId as string}
          chain={game?.chain}
          loading={loadingGenerateKeys}
          generateCred={generateApiKeys}
          setIsModalOpen={setIsCreateKeyModalOpen}
        />
      </Modal>
      <Modal isOpen={!!selectedKeysForDelete}>
        <DeleteModal
          text="api credentials"
          isLoading={loading}
          setIsModalOpen={handleCloseDeleteModal}
          deleteHandler={handleRemoveKeys}
        />
      </Modal>
    </div>
  );
};

export default ApiCredentials;
