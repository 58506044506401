import Icon from '../Icon/Icon';
import './no-files-uploaded.scss';

const NoFilesUploaded = () => {
  return (
    <div className="no_files_uploaded">
      <Icon name="bucket" />
      <p>No data</p>
    </div>
  );
};

export default NoFilesUploaded;
