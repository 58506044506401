import loaderGif from '../../global/images/loader.gif';
import './loading-fallback.scss';

const LoadingFallback = () => {
  return (
    <div className="loading_fallback_container">
      <img src={loaderGif} alt="loader" />
    </div>
  );
};

export default LoadingFallback;
