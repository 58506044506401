import { useState } from 'react';
import { DocumentNode } from 'graphql';
import { useLocation } from 'react-router-dom';
import { useReneQuery } from '../../../../hooks';
import { Dispatcher, OrganizationData, UserData } from '../../../../global/interfaces';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import TransactionsList from '../../../../components/transactions-list/transactions-list';
import TopUpCreditModal from '../../../../components/modal/top-up-credit-modal/top-up-credit-modal';
import PaymentHistoryModal from '../../../../components/modal/payment-history/payment-history-modal';
import './credit.scss';

const Credit = ({
  allowTopUp = true,
  query,
  redirectPage,
  setOpenMobileMenu,
}: {
  allowTopUp?: boolean;
  query: DocumentNode;
  redirectPage: string;
  setOpenMobileMenu?: Dispatcher<boolean>;
}) => {
  const location = useLocation();
  const [modalOpen, setModalOpen] = useState<'top-up' | 'payment-history'>();

  const { data, loading } = useReneQuery<{ User: UserData } | { Organization: OrganizationData }>(query, {
    fetchPolicy: 'network-only',
  });

  const mappedData = (data as { User: UserData })?.User || (data as { Organization: OrganizationData })?.Organization;

  const handleOpenModal = (name: 'top-up' | 'payment-history') => {
    setModalOpen(name);
  };

  return (
    <div className="credit">
      <div className="credit__heading">
        {setOpenMobileMenu ? (
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
        ) : null}
        <h1 className={setOpenMobileMenu ? '' : 'rainbow-btn-text'}>Credit</h1>
      </div>
      <div className="credit__main">
        <div className="credit__main_actions">
          <div className="credit__main_actions_buttons">
            <div>
              <p>Credit</p>
              <p>${mappedData?.balance?.balance || 0}</p>
            </div>
            {allowTopUp && (
              <div>
                <button className="primary-btn" onClick={() => handleOpenModal('top-up')}>
                  Top-up
                </button>
                <button className="secondary-btn" onClick={() => handleOpenModal('payment-history')}>
                  <Icon name="history" />
                  <p> Top-up history</p>
                </button>
              </div>
            )}
          </div>
          {location.hash && (
            <div
              className={`credit__main_actions_status ${
                location.hash === '#success'
                  ? 'credit__main_actions_status--success'
                  : 'credit__main_actions_status--fail'
              }`}
            >
              <Icon name={location.hash === '#success' ? 'circle-check' : 'alert'} />
              <p>{location.hash === '#success' ? 'Top up success' : 'Top up failed'}</p>
            </div>
          )}
        </div>
        <div className="credit__main_history">
          <h2 className="rainbow-btn-text">Credit usage</h2>
          {!loading ? (
            <TransactionsList transactions={mappedData?.transactions?.items || []} isCredit />
          ) : (
            <LoadingFallback />
          )}
        </div>
      </div>
      <Modal isOpen={modalOpen === 'top-up'}>
        <TopUpCreditModal setIsModalOpen={setModalOpen} redirectPage={redirectPage} />
      </Modal>
      <Modal isOpen={modalOpen === 'payment-history'}>
        <PaymentHistoryModal transactions={mappedData?.balance?.transactions?.items} setIsModalOpen={setModalOpen} />
      </Modal>
    </div>
  );
};

export default Credit;
