import { useState } from 'react';
import { validations } from './validations';
import { useFileUpload } from '../../../../../hooks/useFileUpload';
import { GET_CHAINS, GET_ORG_QUERY } from '../../../../../global/gql/queries';
import { ApolloQueryResult } from '@apollo/client';
import { UPSERT_GAME_MUTATION } from '../../../../../global/gql/mutations';
import { ChainsData, Event, GameData, GamesData, OrganizationData } from '../../../../../global/interfaces';
import { useReneMutation, useReneQuery, useValidation } from '../../../../../hooks';
import { createChainOptions } from '../../../../../utils';
import Input from '../../../../../components/input/input';
import Spinner from '../../../../../components/spinner/spinner';
import Textarea from '../../../../../components/textarea/textarea';
import EditableImage from '../../../../../components/editable-image/editable-image';
import Select from '../../../../../components/select/select';
import LoadingFallback from '../../../../../components/loading-fallback/loading-fallback';

import './general.scss';

interface Props {
  game: GameData | undefined;
  isUserAllowedToUpsert: boolean;
  refetchGames: (variables?: any) => Promise<ApolloQueryResult<{ Games: GamesData } | undefined>>;
}

const General: React.FC<Props> = ({ game, isUserAllowedToUpsert, refetchGames }) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [prevGame, setPrevGame] = useState<GameData>();
  const [file, setFile] = useState<File>();
  const [form, setForm] = useState({
    name: '',
    description: '',
    image: '',
    twitter: '',
    discord: '',
    blockchain: '',
  });

  const { refetch: refetchOrg } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);
  const { data: chains } = useReneQuery<{ Chains: ChainsData }>(GET_CHAINS);

  const chainOptions = createChainOptions(chains?.Chains);

  const [upsertGame, { loading }] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted(data: { UpsertGame: GameData }) {
      if (file) {
        uploadFile(data.UpsertGame.image.uploadUrl, file).then(() => {
          refetchOrg();
          refetchGames();
        });
      } else {
        refetchOrg();
        refetchGames();
      }
    },
  });

  if (game && game !== prevGame) {
    setPrevGame(game);
    setForm({
      name: game.name,
      description: game.data.description,
      image: game.image.url,
      twitter: game?.urls?.find((url) => url.type === 'twitter')?.url || '',
      discord: game?.urls?.find((url) => url.type === 'discord')?.url || '',
      blockchain: game?.chain?.name || 'None',
    });
  }

  const handleChangeForm = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelectChain = (option: string) => {
    setForm((prev) => ({ ...prev, blockchain: option }));
  };

  const handleUpdateGame = () => {
    if (isFormInvalid(form) || !game?.gameId) return;
    let variables: {
      gameId: string;
      name: string;
      description: string;
      isActive: boolean;
      image?: { extension: string; fileId: string };
      urls?: { type: string; url: string }[];
      updatedAt: string;
      chainSymbol?: string;
    } = {
      gameId: game.gameId,
      name: form.name,
      description: form.description,
      isActive: true,
      updatedAt: game.updatedAt,
    };

    const urls = [];
    if (form.discord) {
      urls.push({ type: 'discord', url: form.discord });
    }
    if (form.twitter) {
      urls.push({ type: 'twitter', url: form.twitter });
    }

    variables = file
      ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: game.image.fileId } }
      : variables;
    variables = urls.length ? { ...variables, urls } : variables;
    variables =
      form.blockchain && form.blockchain !== 'None'
        ? { ...variables, chainSymbol: chainOptions && chainOptions[form.blockchain].chainSymbol }
        : variables;
    upsertGame({ variables });
  };

  return (
    <div className="organization_game_settings_tab__main_general">
      <div>
        <h3 className="rainbow-btn-text">General Game Settings</h3>
      </div>
      <div className="organization_game_settings_tab__main_general_chain">
        {chainOptions ? (
          <Select
            label="Blockchain"
            value={form.blockchain}
            options={chainOptions}
            showListValueFn={(item) => item.name}
            changeHandler={handleSelectChain}
            errorMsg={errors?.blockchain}
            disabled={!!prevGame?.chain}
            showError
          />
        ) : (
          <LoadingFallback />
        )}
      </div>
      <Input
        name="name"
        label="Name"
        placeholder="Enter game name"
        handleInput={handleChangeForm}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        name="description"
        label="Description"
        value={form.description}
        handleInput={handleChangeForm}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <EditableImage imageUrl={form.image} alt="game" setFile={setFile} />
      <Input
        name="twitter"
        label="X"
        placeholder="Enter your X account link"
        handleInput={handleChangeForm}
        value={form.twitter}
        errorMessage={errors?.twitter}
      />
      <Input
        name="discord"
        label="Discord"
        placeholder="Enter your Discord account link"
        handleInput={handleChangeForm}
        value={form.discord}
        errorMessage={errors?.discord}
      />
      {isUserAllowedToUpsert && (
        <button className="primary-btn center-btn" type="button" onClick={handleUpdateGame}>
          {loading ? <Spinner size="sm" /> : 'Update Game'}
        </button>
      )}
    </div>
  );
};

export default General;
