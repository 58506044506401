import { useEffect, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
  OwnableAssetData,
  AttributesData,
  Dispatcher,
  Event,
  TransactionModalData,
  UserData,
} from '../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../hooks';
import { ORG_CREDIT } from '../../../global/routes';
import { MINT_ASSET_MUTATION } from '../../../global/gql/mutations';
import { validations } from './validations';
import {
  AttributeTypeDate,
  AttributeTypePercentage,
  AttributeTypeNumber,
  AttributeTypeText,
} from '../../attribute-types';
import { SearchUserWithDropdown } from '../../search-user/search-user';
import { NavLink, useParams } from 'react-router-dom';
import User from '../../user/user';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Toggle from '../../toggle/toggle';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import MetadataTemplateOptions from '../../metadata-template-options/metadata-template-options';
import placeholder from '../../../global/images/avatar.webp';

import './single-asset-mint-modal.scss';

interface Props {
  ownableAsset: OwnableAssetData;
  isMainChainActive: boolean | undefined;
  setIsMintModalOpen: Dispatcher<boolean>;
  setTransactionModal: Dispatcher<TransactionModalData>;
  isMintingAllowed?: boolean;
}

interface AttributesDataExt extends AttributesData {
  randomize?: boolean;
}

interface AttributesDataSend {
  displayType: 'string' | 'boost_percentage' | 'date' | 'number';
  traitType: string;
  values?: string[];
  value?: string;
  __typename?: string;
}

const extendAttributes = (attributes: AttributesDataExt[]) => {
  return attributes.map((attr) => ({ ...attr, randomize: false }));
};

const SingleAssetMintModal: React.FC<Props> = ({
  ownableAsset,
  isMainChainActive,
  setIsMintModalOpen,
  setTransactionModal,
  isMintingAllowed,
}) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const [form, setForm] = useState<{
    name?: string;
    description?: string;
    imageIndex?: string;
    animationIndex?: string;
    recipient?: UserData;
    attributes?: AttributesDataExt[];
    isTestNet: boolean;
  }>({
    name: ownableAsset?.metadataTemplates?.name || '',
    description: ownableAsset?.metadataTemplates?.description || '',
    imageIndex: '',
    animationIndex: '',
    attributes: [],
    isTestNet: true,
  });
  const params = useParams();
  const [activeField, setActiveField] = useState<'description' | 'name' | undefined>();
  const [allRandom, setAllRandom] = useState(false);
  const [random, setRandom] = useState(Array(ownableAsset?.attributes?.length).fill(false));
  const [randomRecipient, setRandomRecipient] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<AttributesDataExt[] | []>(
    ownableAsset?.attributes ? extendAttributes(ownableAsset.attributes) : [],
  );

  const [mintAsset, { loading }] = useReneMutation(MINT_ASSET_MUTATION, {
    onCompleted: () => {
      handleCloseModal();
      setTransactionModal({ show: true, success: true });
    },
    onError: (errors: ApolloError) => {
      setTransactionModal({ show: true, success: false, msg: errors?.message });
    },
  });

  useEffect(() => {
    setForm((prev) => ({ ...prev, attributes }));
  }, [attributes]);

  useEffect(() => {
    setAllRandom(random.every((r) => r === true));
  }, [random]);

  const handleAllRandom = (e: Event['Input']) => {
    const {
      target: { checked },
    } = e;
    setAllRandom(checked);
    setRandom((prev) => prev.map(() => checked));
    setAttributes((prev) => prev.map((attr) => ({ ...attr, randomize: checked })));
  };

  /* @ functionality start */
  const attributeTraits = ownableAsset?.attributes?.map((attr) => attr.traitType);

  const nameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const refs: {
    name: React.RefObject<HTMLInputElement>;
    description: React.RefObject<HTMLTextAreaElement>;
  } = {
    name: nameRef,
    description: descriptionRef,
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    const {
      target: { name, value },
    } = e;
    setForm((prev) => ({ ...prev, [name]: value }));
    if (value[value.length - 1] === '@') {
      setActiveField(name as 'description' | 'name');
    } else if (activeField) {
      setActiveField(undefined);
    }
  };

  const handleOptionClick = (option: string) => {
    if (activeField) {
      setForm((prev) => ({
        ...prev,
        [activeField]: form[activeField] + '${' + option + '}',
      }));
      refs[activeField].current?.focus();
      setActiveField(undefined);
    }
  };
  /* @ functionality end */

  const setAssetRecipient = (user: UserData | undefined) => {
    setForm((prev) => ({ ...prev, recipient: user }));
  };

  const handleCloseModal = () => {
    setIsMintModalOpen(false);
  };

  const handleMintAsset = () => {
    if (isFormInvalid({ ...form, randomRecipient })) return;

    const prepareAttributes = (attrs: AttributesDataExt[]) => {
      const formatValues = {
        string: (_: string[], selectedValue?: string) => ({ value: selectedValue }),
        number: (values: string[]) => ({ value: values[2] }),
        date: (values: string[]) => ({ value: values[2] }),
        boost_percentage: (values: string[]) => ({ value: values[0] }),
      };

      return attrs.map((attr) => {
        const { randomize, values, selectedValue, displayType, traitType } = attr;
        if (randomize) return { displayType, traitType };
        return { displayType, traitType, ...formatValues[displayType](values, selectedValue) };
      });
    };

    const variables: {
      ownableAssetId: string;
      gameId: string;
      userId?: string;
      name?: string;
      description?: string;
      imageFilename?: string;
      animationFilename?: string;
      attributes: AttributesDataSend[];
      isTestNet: boolean;
      randomRecipient: boolean | undefined;
    } = {
      ownableAssetId: ownableAsset.ownableAssetId,
      gameId: params.gameId as string,
      userId: form.recipient?.userId,
      randomRecipient: randomRecipient || undefined,
      name: form.name?.trim(),
      description: form.description?.trim(),
      attributes: prepareAttributes(attributes),
      isTestNet: form.isTestNet,
    };

    if (form.imageIndex) variables['imageFilename'] = ownableAsset?.files?.images[parseInt(form.imageIndex)].name;
    if (form.animationIndex)
      variables['animationFilename'] = ownableAsset?.files?.animations[parseInt(form.animationIndex)].name;

    mintAsset({ variables });
  };

  const renderAttributeTypes = () => {
    const attributeTypeMap = {
      boost_percentage: AttributeTypePercentage,
      date: AttributeTypeDate,
      number: AttributeTypeNumber,
      string: AttributeTypeText,
    };

    let attributeComponent: { id: number; label: string; type?: string; disabled?: boolean; item: JSX.Element }[] = [];
    attributeComponent = attributes.map((attr, i) => {
      const Component = attributeTypeMap[attr.displayType];
      const props: {
        mode: 'add' | 'range' | 'value';
        attribute: any;
        setAttributeList: any;
        errorMessage: string;
        disabled?: boolean;
      } = {
        mode: 'value',
        attribute: attr,
        setAttributeList: setAttributes,
        errorMessage: errors?.attributes?.[i],
        disabled: attr.randomize,
      };
      return {
        id: i,
        label: attr.traitType,
        type: attr.displayType,
        disabled: attr.randomize,
        item: <Component {...props} />,
      };
    });

    return (
      <>
        <div className="asset-attribute-title">
          <label>Asset Metadata Attribute Values</label>
          <div className="asset-attribute-title__radio">
            <Toggle id="all-random" checked={allRandom} setValue={handleAllRandom} />
            <label>All Random</label>
          </div>
        </div>
        <div className="asset-attribute-values">
          {attributes.length ? (
            attributeComponent.map(({ id, label, type, disabled = false, item }, attrIndex) => (
              <div key={id} className="asset-attribute-values__item">
                <label>{label}</label>
                <div
                  className={`asset-attribute-values__item_toggle ${
                    type !== 'boost_percentage' ? 'padding-bottom' : ''
                  }`}
                >
                  <Toggle
                    id={`random-${attrIndex}`}
                    checked={disabled}
                    setValue={(e) => {
                      setRandom((prev) => prev.map((val, index) => (index === attrIndex ? e.target.checked : val)));
                      setAttributes((prev) =>
                        prev.map((attr, index) => ({
                          ...attr,
                          randomize: index === attrIndex ? e.target.checked : attr.randomize,
                        })),
                      );
                    }}
                  />
                  <label>Random</label>
                </div>
                <div className="asset-attribute-values__item_value">{item}</div>
              </div>
            ))
          ) : (
            <p className="error-msg">{errors?.attributes}</p>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="single-asset-mint-modal">
      <div>
        <div className="single-asset-mint-modal__heading">
          <h2>Mint Asset</h2>
          <button type="button" onClick={handleCloseModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <div className="single-asset-mint-modal__content">
          <div className="single-asset-mint-modal__content_recipient">
            <div className="single-asset-mint-modal__content_recipient_top-row">
              <label className="recipient-label">Asset Recipient</label>
              <div className="recipient-radio">
                <Toggle
                  id="recipient"
                  name="recipient"
                  checked={Boolean(randomRecipient)}
                  setValue={() => {
                    setForm((prev) => ({ ...prev, recipient: undefined }));
                    setRandomRecipient(!randomRecipient);
                  }}
                />
                <label>Random</label>
              </div>
            </div>
            <div className="single-asset-mint-modal__content_recipient_bottom-row">
              {!form.recipient ? (
                <SearchUserWithDropdown
                  gameId={params.gameId}
                  setSelectedUser={setAssetRecipient}
                  disabled={randomRecipient}
                  errorMessage={errors?.recipient}
                />
              ) : (
                <div className="single-asset-mint-modal__content_recipient_selected">
                  <User user={form.recipient} />
                  <button type="button" onClick={() => setAssetRecipient(undefined)}>
                    <Icon name="close" size={24} />
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="single-asset-mint-modal__content_title">
            <Input
              label="Asset Title"
              inputRef={nameRef}
              name="name"
              value={form?.name}
              handleInput={handleFormChange}
              errorMessage={errors?.name}
            />
            <>
              {activeField === 'name' ? (
                <MetadataTemplateOptions handleOptionClick={handleOptionClick} filteredOptions={attributeTraits} />
              ) : null}
            </>
          </div>
          <div className="single-asset-mint-modal__content_description">
            <Textarea
              label="Asset Description"
              textAreaRef={descriptionRef}
              name="description"
              value={form?.description}
              handleInput={handleFormChange}
              errorMessage={errors?.description}
            />
            <>
              {activeField === 'description' ? (
                <MetadataTemplateOptions //TODO take care of text area cursor track
                  handleOptionClick={handleOptionClick}
                  filteredOptions={attributeTraits}
                />
              ) : null}
            </>
          </div>
          <div className="single-asset-mint-modal__content_media">
            <div>
              <Select
                label="Asset Image"
                className="media_select"
                value={form.imageIndex as string}
                placeholder="Select image"
                options={{ ...ownableAsset?.files?.images }}
                changeHandler={(index) => {
                  setForm((prev) => ({ ...prev, imageIndex: index }));
                }}
                showSelectedValueFn={(val) => val.name}
                showListValueFn={(val) => (
                  <div className="card" title={`${val.name}.${val.extension}`}>
                    <img src={val.url || placeholder} alt="" className="media_selected" />
                    <span>{`${val.name}.${val.extension}`}</span>
                  </div>
                )}
              />
              <p className="error-msg">{errors?.imageIndex}</p>
            </div>
            {form.imageIndex ? (
              <img src={ownableAsset?.files?.images[parseInt(form?.imageIndex)].url || placeholder} alt="" />
            ) : (
              <div className="no-media-placeholder">No Image</div>
            )}
          </div>
          <div className="single-asset-mint-modal__content_media">
            <div>
              <Select
                label="Asset Animation"
                className="media_select"
                value={form.animationIndex as string}
                placeholder="Select animation"
                options={{ ...ownableAsset?.files?.animations }}
                changeHandler={(index) => {
                  setForm((prev) => ({ ...prev, animationIndex: index }));
                }}
                showSelectedValueFn={(val) => val.name}
                showListValueFn={(val) => (
                  <div className="card" title={`${val.name}.${val.extension}`}>
                    <video key={val.url} className="media_selected">
                      <source src={val.url} />
                    </video>
                    <span>{`${val.name}.${val.extension}`}</span>
                  </div>
                )}
              />
              <p className="error-msg">{errors?.animationIndex}</p>
            </div>
            {form.animationIndex ? (
              <video key={ownableAsset?.files?.animations[parseInt(form?.animationIndex)].url} autoPlay muted>
                <source src={ownableAsset?.files?.animations[parseInt(form?.animationIndex)].url} />
              </video>
            ) : (
              <div className="no-media-placeholder">No Animation</div>
            )}
          </div>
          <div>{renderAttributeTypes()}</div>
          <div className="single-asset-mint-modal__content_testnet">
            <label>Test Net:</label>
            <Toggle
              checked={form.isTestNet}
              setValue={(e) => {
                setForm((prev) => ({ ...prev, isTestNet: e.target.checked }));
              }}
              disabled={!isMainChainActive}
            />
          </div>
        </div>
        <div className="single-asset-mint-modal__actions">
          {!isMintingAllowed && !form.isTestNet ? (
            <div>
              You can't mint due to insufficient funds. You can top up the credits{' '}
              <NavLink to={ORG_CREDIT} className={({ isActive }) => (isActive ? 'active-page' : '')}>
                here
              </NavLink>
            </div>
          ) : (
            ''
          )}
          <div>
            <button type="button" className="secondary-btn" onClick={handleCloseModal} disabled={loading}>
              Cancel
            </button>
            <button
              type="button"
              className="primary-btn"
              onClick={handleMintAsset}
              disabled={loading || (!isMintingAllowed && !form.isTestNet)}
            >
              {loading ? <Spinner size="sm" /> : 'Mint'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleAssetMintModal;
