import './radio-button.scss';

export const RadioButton = ({
  id,
  isSelected,
  label,
  value,
  name,
  setValue,
  disabled,
}: {
  id: string;
  isSelected?: boolean;
  label?: string;
  value: string | number | readonly string[] | undefined;
  name: string;
  setValue: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className={`radio-button ${disabled ? 'disabled' : ''}`}>
      <input
        type="radio"
        id={id}
        onChange={setValue}
        value={value}
        checked={isSelected}
        name={name}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
