import { cloneElement, useCallback, useEffect } from 'react';
import { usePrevious } from '../../../hooks';
import { compareObjects, debounce } from '../../../utils';
import { autosaveInterval } from '../../../global/consts';

export const WithAutosave = ({
  data,
  handleSave,
  children,
  props = {},
}: {
  data: any;
  handleSave?: any;
  children?: React.ReactElement;
  props?: { [key: string]: any };
}) => {
  const previousState = usePrevious(data);

  const saveWithDebounce = useCallback(
    (newData: any) => {
      if (previousState) debounce(handleSave(newData));
    },
    [handleSave, previousState],
  );

  useEffect(() => {
    const autosaveTimeout = setTimeout(() => {
      const hasAttributesChanged = !compareObjects(data, previousState);
      if (hasAttributesChanged) {
        saveWithDebounce(data);
      } else {
        clearTimeout(autosaveTimeout);
      }
    }, autosaveInterval);
    return () => clearTimeout(autosaveTimeout);
  }, [data, previousState, saveWithDebounce]);

  return <> {children ? cloneElement(children, { ...props }) : ''} </>;
};
