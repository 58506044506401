import { FC, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { OwnableAssetData, Dispatcher, GamesData, TransactionModalData, UserData } from '../../../global/interfaces';
import { DOCS_LINK, ORG_CREDIT } from '../../../global/routes';
import { GET_GAMES_QUERY } from '../../../global/gql/queries';
import { CSV_FILE_BULK_MINT_ASSET, CSV_FILE_UPLOAD_MUTATION } from '../../../global/gql/mutations';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { SearchUserWithDropdown } from '../../search-user/search-user';
import { validations } from './validations';
import User from '../../user/user';
import Icon from '../../Icon/Icon';
import Toggle from '../../toggle/toggle';
import Spinner from '../../spinner/spinner';
import FileUpload from '../../file-upload/file-upload';
import placeholder from '../../../global/images/avatar.webp';

import './bulk-asset-mint-file-modal.scss';

interface Props {
  ownableAsset: OwnableAssetData;
  isMainChainActive: boolean | undefined;
  setIsBulkMintFileModalOpen: Dispatcher<boolean>;
  setTransactionModal: Dispatcher<TransactionModalData>;
  isMintingAllowed?: boolean;
}

const CsvErrors = ({ fileName, csvErrors }: { fileName: string; csvErrors: { message: string }[] }) => {
  return (
    <div className="bulk-asset-mint-file-modal__content_csv-error">
      <div className="bulk-asset-mint-file-modal__content_csv-error_file">
        <Icon name="circle-cross" size={24} />
        <p>
          Error occurred while uploading file <span>{fileName}</span>. Please try again.
        </p>
      </div>
      <div className="bulk-asset-mint-file-modal__content_csv-error_errors">
        <div>
          <Icon name="circle-cross" size={24} />
          <p>Following parsing errors occurred:</p>
        </div>
        <ul>
          {csvErrors.map((error) => (
            <li key={error.message}>{error.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const BulkAssetMintFileModal: FC<Props> = ({
  ownableAsset,
  isMainChainActive,
  setIsBulkMintFileModalOpen,
  setTransactionModal,
  isMintingAllowed,
}) => {
  const params = useParams();
  const uploadFile = useFileUpload();
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [gameRecipient, setGameRecipient] = useState<boolean>(false);
  const [csvErrors, setCsvErrors] = useState<{ message: string }[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{
    file?: File;
    recipient?: UserData;
    isTestNet: boolean;
  }>({
    file: undefined,
    recipient: undefined,
    isTestNet: true,
  });

  const { data: selectedGame } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const [bulkMintAsset] = useReneMutation(CSV_FILE_BULK_MINT_ASSET, {
    onCompleted: (data: { CSVFileBulkMintAsset: { errors: { message: string }[] } }) => {
      if (data.CSVFileBulkMintAsset.errors.length) {
        setLoading(false);
        setCsvErrors(data.CSVFileBulkMintAsset.errors);
      } else {
        setLoading(false);
        handleCloseModal();
        setTransactionModal({ show: true, success: true });
      }
    },
  });

  const [uploadCsv] = useReneMutation(CSV_FILE_UPLOAD_MUTATION, {
    onCompleted: (data: { CSVFileUploadURL: { fileId: string; uploadUrl: string } }) => {
      if (data.CSVFileUploadURL.uploadUrl && form.file && (form.recipient || gameRecipient)) {
        uploadFile(data.CSVFileUploadURL.uploadUrl, form.file).then(() => {
          bulkMintAsset({
            variables: {
              ownableAssetId: ownableAsset.ownableAssetId,
              fileId: data.CSVFileUploadURL.fileId,
              isTestNet: form.isTestNet,
              userId: !gameRecipient ? form.recipient?.userId : undefined,
              gameId: gameRecipient ? params.gameId : undefined,
            },
          });
        });
      }
    },
  });

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (csvErrors) setCsvErrors(undefined);
    return !isFieldInvalid('file', { ...form, file });
  };

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const setAssetRecipient = (user: UserData | undefined) => {
    setForm((prev) => ({ ...prev, recipient: user }));
  };

  const handleCloseModal = () => {
    setIsBulkMintFileModalOpen(false);
  };

  const handleBulkMint = () => {
    if (isFormInvalid({ ...form, gameRecipient })) return;
    setLoading(true);
    uploadCsv();
  };

  const renderPlayerRecipient = () => {
    return (
      <>
        {!form.recipient ? (
          <SearchUserWithDropdown
            gameId={params.gameId}
            setSelectedUser={setAssetRecipient}
            errorMessage={errors?.recipient}
          />
        ) : (
          <div className="bulk-asset-mint-random-modal__content_recipient_recipient">
            <User user={form.recipient} />
            <button type="button" onClick={() => setAssetRecipient(undefined)}>
              <Icon name="close" size={24} />
            </button>
          </div>
        )}
      </>
    );
  };

  const renderGameRecipient = () => (
    <div className="bulk-asset-mint-random-modal__content_recipient-game">
      <img src={selectedGame?.Games.items[0]?.image.url || placeholder} alt="selected game" />
      <div>{selectedGame?.Games.items[0]?.name}</div>
    </div>
  );

  return (
    <div className="bulk-asset-mint-file-modal">
      <div className="bulk-asset-mint-file-modal__heading">
        <h2>Mint Bulk Assets From File</h2>
        <button type="button" onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="bulk-asset-mint-file-modal__content">
        <div className="bulk-asset-mint-file-modal__content_recipient">
          <div className="bulk-asset-recipient-top-row">
            <label className="recipient-label">Bulk Asset Recipient</label>
            <div className="recipient-radio">
              <label>Player</label>
              <Toggle
                id="recipient"
                name="recipient"
                checked={Boolean(gameRecipient)}
                setValue={() => setGameRecipient(!gameRecipient)}
              />
              <label>Game</label>
            </div>
          </div>
          <div className="bulk-asset-recipient-bottom-row">
            {!gameRecipient ? renderPlayerRecipient() : renderGameRecipient()}
          </div>
        </div>
        <div className="bulk-asset-mint-file-modal__content_files">
          <label>Upload Assets Metadata CSV File</label>
          <p>
            Metadata CSV file needs to follow expected{' '}
            <a href={DOCS_LINK} target="_blank" rel="noreferrer">
              ReneVerse Metadata CSV format
            </a>
            , and all images and animations mapped in file should be uploaded in ownable asset metadata data upload
            section prior to bulk minting
          </p>
          <FileUpload
            setFiles={setFile}
            description="Drag & Drop Assets Metadata CSV File"
            errorMessage={errors?.file}
            handleBeforeChange={handleFileChange}
          />
        </div>
        {csvErrors && form.file && <CsvErrors fileName={form.file?.name} csvErrors={csvErrors} />}
        <div className="bulk-asset-mint-file-modal__content_testnet">
          <label>Test Net:</label>
          <Toggle
            checked={form.isTestNet}
            setValue={(e) => {
              setForm((prev) => ({ ...prev, isTestNet: e.target.checked }));
            }}
            disabled={!isMainChainActive}
          />
        </div>
      </div>
      <div className="bulk-asset-mint-file-modal__actions">
        {!isMintingAllowed && !form.isTestNet ? (
          <div>
            You can't mint due to insufficient funds. You can top up the credits
            <NavLink to={ORG_CREDIT} className={({ isActive }) => (isActive ? 'active-page' : '')}>
              here
            </NavLink>
          </div>
        ) : (
          ''
        )}
        <div>
          <button className="secondary-btn" onClick={handleCloseModal} disabled={loading}>
            Cancel
          </button>
          <button
            type="submit"
            className="primary-btn"
            onClick={handleBulkMint}
            disabled={loading || (!isMintingAllowed && !form.isTestNet)}
          >
            {loading ? <Spinner size="sm" /> : 'Mint'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkAssetMintFileModal;
