import { gql } from '@apollo/client';

export const GAME_CONNECT_SUBSCRIPTION = gql`
  subscription OnGameConnectUpdate($userId: String!) {
    OnGameConnectUpdate(userId: $userId) {
      name
      gameId
      userId
      status
    }
  }
`;

export const GAME_AUTHORIZATION_SUBSCRIPTION = gql`
  subscription OnGameConnectAuthUpdate($userId: String!) {
    OnGameConnectAuthUpdate(userId: $userId) {
      gameId
      userId
      authId
      authorized
      name
    }
  }
`;

export const GENERAL_NOTIFICATION_SUBSCRIPTION = gql`
  subscription OnGeneralNotification($userId: String!) {
    OnGeneralNotification(userId: $userId) {
      message
      userId
      type
      notificationId
      meta {
        key
        value
      }
    }
  }
`;
