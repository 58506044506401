import { useState } from 'react';
import { AttributesData, Dispatcher } from '../../../global/interfaces';
import { InputValues } from '../../input-values/input-values';
import Select from '../../select/select';
import './attribute-type-text.scss';

const AttributeTypeText: React.FC<{
  mode?: 'add' | 'range' | 'value';
  attribute: AttributesData;
  setAttributeList: Dispatcher<AttributesData[] | []>;
  errorMessage?: string;
  disabled?: boolean;
}> = ({ mode = 'add', attribute, setAttributeList, errorMessage, disabled = false }) => {
  const [name, setName] = useState<string>('');

  const addValueToAttribute = (selectedValue?: number, value?: string) => {
    if (selectedValue === undefined && !value?.trim()) return;
    setAttributeList((prev) => {
      return prev.map((attr) => {
        if (attr.traitType === attribute.traitType) {
          let newAttr = {
            ...attr,
          };

          newAttr =
            selectedValue || selectedValue === 0 ? { ...newAttr, selectedValue: attr.values[selectedValue] } : newAttr;
          newAttr = value ? { ...newAttr, values: [...attr.values, value.trim() as string] } : newAttr;
          return newAttr;
        }
        return attr;
      });
    });
  };

  const handleAddAttribute = (value: string) => {
    addValueToAttribute(undefined, value);
  };

  const handleAttributeSelect = (selectedValue: string) => {
    addValueToAttribute(parseInt(selectedValue));
    setName(selectedValue);
  };

  const deleteTextAttribute = (value: string) => {
    setAttributeList((prev) => {
      return prev.map((attr) => {
        if (attr.traitType === attribute.traitType) {
          return {
            ...attr,
            values: attr.values.filter((attrVal) => attrVal !== value),
          };
        }
        return attr;
      });
    });
  };

  return (
    <>
      <div className="attribute_type_text">
        {['add', 'range'].includes(mode) && (
          <InputValues
            label="Values"
            values={attribute.values}
            changeHandler={handleAddAttribute}
            deleteValueHandler={deleteTextAttribute}
            disabled={disabled}
            errorMessage={errorMessage}
          />
        )}
        {['value'].includes(mode) && (
          <>
            <Select
              value={attribute.values[parseInt(name)]}
              options={attribute.values}
              changeHandler={(index) => {
                handleAttributeSelect(index);
              }}
              disabled={disabled}
            />
            <p className="error-msg">{errorMessage}</p>
          </>
        )}
      </div>
    </>
  );
};

export default AttributeTypeText;
