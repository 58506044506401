import { useEffect, useState } from 'react';
import { useProdEffect } from '../../../hooks/useProdEffect';
import { Event } from '../../../global/interfaces';
import { EventTypes, TOKEN, UserRole } from '../../../global/consts';
import { FIRST_TIME_USER, HOME, LOGIN, PRIVACY_POLICY } from '../../../global/routes';
import { Link, useNavigate } from 'react-router-dom';
import { validations } from './validations';
import { ApolloError, useMutation } from '@apollo/client';
import { CHECK_USERNAME_QUERY } from '../../../global/gql/queries';
import { useErrorContext } from '../../../context/error-context';
import { debounce, handleError, handleKeyPressSubmit, isUserLoggedIn } from '../../../utils';
import { REGISTER_USER_MUTATION, TRACK_UNAUTH_EVENT_MUTATIONS } from '../../../global/gql/mutations';
import { useLazyReneQuery, useReneMutation, useValidation } from '../../../hooks';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';
import Checkbox from '../../../components/checkbox/checkbox';

import x from './images/x.svg';
import twitter from './images/twitter.svg';
import discord from './images/discord.svg';
import reneLogo from '../../../global/images/rene-logo.png';
import gepl from './images/gepl.svg';

import './registration-gepl-page.scss';

const RegistrationGEPLPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const navigate = useNavigate();
  const { setError } = useErrorContext();
  const { errors, isFormInvalid } = useValidation(validations);
  const [isEventSend, setEventSend] = useState(false);
  const [form, setForm] = useState<{
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    confirmNewPassword: string;
    term: boolean;
    newsletter: boolean;
  }>({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmNewPassword: '',
    term: false,
    newsletter: false,
  });

  const [trackEvent] = useMutation(TRACK_UNAUTH_EVENT_MUTATIONS);
  const [checkUsername, { data: usernameCheck }] = useLazyReneQuery<{ CheckUsername: boolean }>(CHECK_USERNAME_QUERY);
  const [registerUser, { loading }] = useReneMutation(REGISTER_USER_MUTATION, {
    onCompleted(data: { RegisterUser: { jwt: string } }) {
      if (data?.RegisterUser?.jwt) {
        localStorage.setItem(TOKEN, data?.RegisterUser.jwt);
        navigate(`/${FIRST_TIME_USER}`, { replace: true });
      }
    },
    onError: (err: ApolloError) => {
      handleError(err, setError, err.message);
    },
  });

  useProdEffect(() => {
    if (form.email && !isEventSend) {
      trackEvent({
        variables: {
          distinctId: form.email,
          eventType: EventTypes.SIGN_UP_STARTED,
        },
      });
      setEventSend(true);
    }
  }, [form.email]);

  useEffect(() => {
    if (form.username) debounce(() => checkUsername({ variables: { username: form.username } }), 1000)();
  }, [checkUsername, form.username]);

  useEffect(() => {
    if (isLoggedIn) navigate(HOME);
  }, [isLoggedIn, navigate]);

  const inputHandler = (e: Event['Input']) => {
    setForm((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleRegister = () => {
    if (isFormInvalid(form) || !usernameCheck?.CheckUsername) return;
    if (form.newsletter) {
      fetch(`https://hooks.zapier.com/hooks/catch/12446590/3o7y8tt/?email=${form.email}`, {
        method: 'POST',
      });
    }
    let variables: {
      firstName: string;
      lastName: string;
      email: string;
      password: string;
      username: string;
      role: UserRole;
      referralCode: string;
    } = {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      password: form.password,
      username: form.username,
      role: UserRole.GAMER,
      referralCode: 'NRGY2X5844P',
    };

    registerUser({ variables });
  };
  return (
    <div className="registration-gepl-page">
      <div className="registration-gepl-page__logo">
        <img src={reneLogo} alt="reneverse" />
        <img src={x} alt="x" width={15} height={15} />
        <img src={gepl} alt="gepl" height={25} />
      </div>
      <div className="registration-gepl-page__welcome">
        <h1>Welcome GEPL 2024 contestants!</h1>
        <p>
          ReneVerse is a proud partner of GEPL, and to kick it off we’re running a <b>$2500 RAFFLE</b>, with <b>$500</b>{' '}
          going to the 1st place winner!
        </p>
      </div>
      <div className="registration-gepl-page__form">
        <div className="registration-gepl-page__form_heading">
          <h2>
            To enter, please create a ReneID account below, and <b>for extra entries</b> to the raffle, be sure to
          </h2>
          <div>
            <Link to="https://x.com/ReneVerse_io" target="_blank" className="secondary-btn">
              <img src={twitter} alt="twitter" />
              Follow @ReneVerse_io on X
            </Link>
            <Link to="https://discord.com/invite/reneverse" target="_blank">
              <img src={discord} alt="discord" />
              Join the ReneVerse Discord
            </Link>
          </div>
          <p>More games, rewards, and experiences await!</p>
        </div>
        <div className="registration-gepl-page__form_inputs">
          <div className="registration-gepl-page__form_inputs_name">
            <Input
              label="First name *"
              name="firstName"
              placeholder="Enter your first name"
              handleInput={inputHandler}
              value={form.firstName}
              errorMessage={errors?.firstName}
            />
            <Input
              label="Last name *"
              name="lastName"
              placeholder="Enter your last name"
              handleInput={inputHandler}
              value={form.lastName}
              errorMessage={errors?.lastName}
            />
          </div>
          <Input
            label="Username *"
            name="username"
            placeholder="Enter your username"
            handleInput={inputHandler}
            value={form.username}
            errorMessage={errors?.username || (usernameCheck && !usernameCheck.CheckUsername && 'Username taken')}
          />
          <Input
            label="Email *"
            name="email"
            type="text"
            autoComplete="email username"
            placeholder="Enter your email"
            handleInput={inputHandler}
            onKeyDown={(e) => handleKeyPressSubmit(e, handleRegister)}
            value={form.email}
            errorMessage={errors?.email}
          />
          <Input
            label="Password *"
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder="Enter your password"
            handleInput={inputHandler}
            onKeyDown={(e) => handleKeyPressSubmit(e, handleRegister)}
            value={form.password}
            errorMessage={errors?.password}
          />
          <Input
            label="Confirm password *"
            name="confirmNewPassword"
            type="password"
            autoComplete="current-password"
            placeholder="Enter your password"
            handleInput={inputHandler}
            onKeyDown={(e) => handleKeyPressSubmit(e, handleRegister)}
            value={form.confirmNewPassword}
            errorMessage={errors?.confirmNewPassword}
          />
          <div className="registration-gepl-page__form_inputs_terms">
            <div>
              <Checkbox value={form.term} setValue={(term) => setForm((prev) => ({ ...prev, term }))}>
                <p>
                  I accept your
                  <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
                    Terms and Conditions
                  </a>
                </p>
              </Checkbox>
            </div>
            <div>
              <Checkbox value={form.newsletter} setValue={(newsletter) => setForm((prev) => ({ ...prev, newsletter }))}>
                I want to subscribe to product newsletter
              </Checkbox>
            </div>
          </div>
          <button className="primary-btn small-btn" disabled={!form.term} onClick={handleRegister}>
            {loading ? <Spinner size="sm" /> : 'Sign up'}
          </button>
        </div>
        <h2>
          Already a member? <Link to={`/${LOGIN}`}>Log in</Link>
        </h2>
      </div>
    </div>
  );
};

export default RegistrationGEPLPage;
