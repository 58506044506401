import { useState } from 'react';
import { UPSERT_OWNABLE_ASSET_MUTATION } from '../../../global/gql/mutations';
import { OwnableAssetData, Event, Refetch } from '../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { extractExtensionAndName } from '../../../utils';
import { validations } from './validations';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import './files-upload-modal.scss';

interface Props {
  type: 'images' | 'animations' | '';
  ownableAssetId: string;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
  setCloseModal: () => void;
  selectedType?: string;
  uploadedFiles?: any;
}

const FilesUploadModal: React.FC<Props> = ({
  setCloseModal,
  ownableAssetId,
  refetch,
  type,
  selectedType,
  uploadedFiles = [],
}) => {
  const uploadFile = useFileUpload();
  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [upsertOwnableAsset] = useReneMutation(UPSERT_OWNABLE_ASSET_MUTATION, {
    onCompleted(data: { UpsertOwnableAsset: OwnableAssetData }) {
      const promises: Promise<Response | void>[] = [];
      if (type && files?.length) {
        const ownableAssetFiles = data.UpsertOwnableAsset.files[type];
        const filesForUpload = ownableAssetFiles.slice(0 - files?.length);
        filesForUpload.forEach((file, i) => {
          promises.push(uploadFile(file.uploadUrl, files[i]));
        });
        Promise.all(promises).then(() => {
          setCloseModal();
          refetch();
        });
      }
    },
  });
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const handleUploadFiles = () => {
    if (isFormInvalid({ files })) return;
    const variables: {
      ownableAssetId: string;
      files: {
        [x: string]: { extension: string; name: string }[];
      };
    } = {
      ownableAssetId,
      files: {
        [type]: files?.map((file) => ({
          extension: extractExtensionAndName(file.name)[2],
          name: extractExtensionAndName(file.name)[1],
        })),
      },
    };
    setLoading(true);
    upsertOwnableAsset({ variables });
  };
  const uploadedFileNames: string[] = uploadedFiles?.map(
    ({ name, extension }: { name: string; extension?: string }) => `${name}.${extension}`,
  );

  const handleFileChange = (e: Event['Input']) => {
    const files = e?.target?.files;
    return !isFieldInvalid('files', { files, uploadedFileNames, type: selectedType });
  };

  return (
    <div className="files-upload-modal">
      <div className="files-upload-modal__heading">
        <h2>Upload {type}</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <FileUpload setFiles={setFiles} multiple handleBeforeChange={handleFileChange} errorMessage={errors?.files} />
      <div className="files-upload-modal__files">
        {files &&
          Object.values(files).map((file, i) => (
            <div key={i}>
              <div>
                <Icon name="file" />
                <p>{file.name}</p>
              </div>
              <p>{(file.size / (1024 * 1024)).toFixed(2)} Mb</p>
            </div>
          ))}
      </div>
      <div className="files-upload-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleUploadFiles} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Upload'}
        </button>
      </div>
    </div>
  );
};

export default FilesUploadModal;
