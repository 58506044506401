import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFileUpload, useLazyReneQuery, useReneMutation, useReneQuery, useValidation } from '../../../../hooks';
import {
  BrandedAssetData,
  CollectionData,
  CollectionsData,
  Dispatcher,
  Event,
  OwnableAssetData,
  Refetch,
  UserData,
} from '../../../../global/interfaces';
import {
  BRANDED_ASSET_SEARCH,
  GET_USER_QUERY,
  OWNABLE_ASSET_SEARCH,
  createCollectionsQuery,
} from '../../../../global/gql/queries';
import { AssetType, UserRole } from '../../../../global/consts';
import { validations } from './validations';
import { COLLECTIONS, GAMES, ORG } from '../../../../global/routes';
import { UPSERT_COLLECTION_MUTATION } from '../../../../global/gql/mutations';
import { isRoleAllowed } from '../../../../utils';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import Select from '../../../../components/select/select';
import Search from '../../../../components/search/search';
import Input from '../../../../components/input/input';
import Spinner from '../../../../components/spinner/spinner';
import Textarea from '../../../../components/textarea/textarea';
import Statistics from '../../../../components/statistics/statistics';
import EditableImage from '../../../../components/editable-image/editable-image';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import OwnableAssetList from '../../../../components/asset/ownable-asset-list/ownable-asset-list';
import BrandedAssetList from '../../../../components/asset/branded-asset-list/branded-asset-list';
import SmallDashboardBar from '../../../../components/small-dashboard-bar/small-dashboard-bar';
import NewBrandedAssetModal from '../../../../components/modal/new-branded-asset-modal/new-branded-asset-modal';
import NewOwnableAssetModal from '../../../../components/modal/new-ownable-asset-modal/new-ownable-asset-modal';

import placeholder from '../../../../global/images/collection-placeholder.png';

import './collection-page.scss';

type AssetSearchQueryResult<T extends boolean> = T extends true
  ? { BrandedAssetSearch: { items: BrandedAssetData[] } }
  : { OwnableAssetSearch: { items: OwnableAssetData[] } };

const CollectionPage = () => {
  const params = useParams();
  const navigation = useNavigate();
  const isBranded = params.type === AssetType.BRANDED;
  const searchQuery = isBranded ? BRANDED_ASSET_SEARCH : OWNABLE_ASSET_SEARCH;
  const [isEdit, setEdit] = useState<boolean>(false);
  const [assetSearchTerm, setSearchTerm] = useState('');
  const [isNewAsset, setIsNewAsset] = useState(false);
  const {
    data: assetList,
    refetch,
    loading,
  } = useReneQuery<{ Collections: CollectionsData }>(createCollectionsQuery(params?.type as AssetType), {
    variables: { collectionId: params.collectionId },
  });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const [search, { data: searchAssets, loading: searchLoading }] =
    useLazyReneQuery<AssetSearchQueryResult<typeof isBranded>>(searchQuery);

  const isBrandedAssetData = (data: any): data is { BrandedAssetSearch: { items: BrandedAssetData[] } } => {
    return data && 'BrandedAssetSearch' in data;
  };

  useEffect(() => {
    if (assetSearchTerm) {
      let variables: {
        ownableAssetSearchTerm?: string;
        collectionId?: string;
        gameId?: string;
        brandedAssetSearchTerm?: string;
      } = {};
      variables = isBranded ? { brandedAssetSearchTerm: assetSearchTerm } : { ownableAssetSearchTerm: assetSearchTerm };
      variables = params.gameId ? { ...variables, gameId: params.gameId } : variables;
      variables = params.collectionId ? { ...variables, collectionId: params.collectionId } : variables;
      search({ variables });
    }
  }, [assetSearchTerm, params.gameId, search, isBranded, params.collectionId]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const backLink = params.gameId
    ? `/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}`
    : `/${ORG}/${COLLECTIONS}/${params.type}`;

  const allowedRole = isBranded ? [UserRole.CREATOR] : [UserRole.DEVELOPER];
  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, allowedRole);

  const collection = assetList?.Collections?.items[0];
  const showBrandedAssets =
    assetSearchTerm && searchAssets && isBrandedAssetData(searchAssets)
      ? searchAssets.BrandedAssetSearch.items
      : assetList?.Collections.items[0].brandedAssets?.items;
  const showOwnableAssets =
    assetSearchTerm && searchAssets && !isBrandedAssetData(searchAssets)
      ? searchAssets.OwnableAssetSearch.items
      : assetList?.Collections.items[0].ownableAssets?.items;

  const assetsCount =
    params.type === AssetType.BRANDED
      ? collection?.brandedAssets?.items?.length
      : collection?.ownableAssets?.items?.length;
  const collectionOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Edit',
        icon: <Icon name="edit" />,
        func: setEdit,
      },
    ],
    [],
  );

  const handleCollectionAction = useCallback(
    (index: number) => {
      collectionOptions[index].func(true);
    },
    [collectionOptions],
  );

  const assets = () => {
    switch (true) {
      case params.type === AssetType.BRANDED:
        return <BrandedAssetList brandedAssetList={showBrandedAssets} />;
      case params.type === AssetType.OWNABLE:
        return <OwnableAssetList ownableAssetList={showOwnableAssets} />;
      default:
        return null;
    }
  };

  return (
    <div className="collection-page">
      <div className="collection-page__back">
        <button type="button" onClick={() => navigation(backLink)}>
          <Icon name="chevron-left" size={16} />
          <p>Back</p>
        </button>
        <Select
          className="collection-page__banner_big_dots"
          value=""
          placeholder=""
          options={collectionOptions}
          changeHandler={handleCollectionAction}
          showListValueFn={(option) => {
            return (
              <>
                {option.icon} {option.label}
              </>
            );
          }}
        >
          <Icon name="dots" size={40} />
        </Select>
      </div>
      <div className="collection-page__banner">
        <SmallDashboardBar id="" name={collection?.name as string} imageUrl={collection?.image?.url}>
          <div className="small-dashboard-bar__stats">
            <Statistics
              data={{
                games: collection?.stats.games,
                assets: collection?.stats.assets,
              }}
            />
          </div>
        </SmallDashboardBar>
        <div className="collection-page__banner_big">
          <img src={collection?.image?.url || placeholder} alt="collection" />
          <div>
            <h3>{collection?.name}</h3>
            <div>
              {params.type === AssetType.BRANDED && <p>{collection?.brand.name}</p>}
              <p>{collection?.stats.games} games</p>
              <p>{collection?.stats.assets} assets</p>
            </div>
          </div>
        </div>
      </div>
      <main className="collection-page__main">
        <div className="collection-page__main_actions">
          <div className="collection-page__main_actions_heading">
            <h2>Assets</h2>
            <h2>{assetsCount && assetsCount > 0 ? assetsCount : null}</h2>
          </div>
          {isUserAllowed && (
            <button
              type="button"
              className="primary-btn  collection-page__main_actions_new"
              onClick={() => setIsNewAsset(true)}
            >
              <Icon name="plus" size={24} />
              <p>
                New <span>{params.type}</span> Asset
              </p>
            </button>
          )}
          <Search callback={handleSearch} apiSearch />
          <Select
            className="collection-page__main_actions_sort"
            value="Latest"
            options={['Latest']}
            changeHandler={() => {}}
          >
            <Icon name="arrows-sort" size={16} />
          </Select>
        </div>
        <div className="collection-page__main_content">{loading || searchLoading ? <LoadingFallback /> : assets()}</div>
      </main>
      <Modal isOpen={isNewAsset}>
        {params.type === AssetType.BRANDED ? (
          <NewBrandedAssetModal
            collectionId={collection?.collectionId}
            brand={assetList?.Collections.items[0].brand}
            refetch={refetch}
            setCloseModal={setIsNewAsset}
          />
        ) : (
          <NewOwnableAssetModal
            setCloseModal={setIsNewAsset}
            collectionId={collection?.collectionId}
            refetch={refetch}
          />
        )}
      </Modal>
      {collection && (
        <EditCollectionModal
          collection={collection}
          isOpen={isEdit}
          closeModal={() => setEdit(false)}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default CollectionPage;

const EditCollectionModal = ({
  collection,
  isOpen,
  closeModal,
  refetch,
}: {
  collection: CollectionData;
  isOpen: boolean;
  closeModal: () => void;
  refetch: Refetch<{
    Collections: CollectionsData;
  }>;
}) => {
  const uploadImage = useFileUpload();

  const { errors, isFormInvalid } = useValidation(validations);

  const [file, setFile] = useState<File>();
  const [form, setForm] = useState<{ name: string; description: string; image: string }>({
    name: '',
    description: '',
    image: '',
  });
  const [prevCollection, setPrevCollection] = useState<CollectionData | undefined>();

  const [upsertCollection, { loading: upsertLoading }] = useReneMutation(UPSERT_COLLECTION_MUTATION, {
    onCompleted(data: { UpsertCollection: CollectionData }) {
      if (file) {
        uploadImage(data.UpsertCollection.image.uploadUrl, file).then(() => {
          refetch();
          closeModal();
        });
      } else {
        refetch();
        closeModal();
      }
    },
  });

  if (collection && !prevCollection && collection !== prevCollection) {
    setForm((prev) => ({
      ...prev,
      name: collection.name,
      description: collection.description,
      image: collection.image?.url,
    }));
    setPrevCollection(collection);
  }

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveEdit = () => {
    if (isFormInvalid(form)) return;
    if (!collection?.collectionId) return;
    let variables: {
      name: string;
      collectionId: string;
      updatedAt: string;
      image?: { extension: string; fileId: string };
      description: string;
      type: string;
    } = {
      name: form.name,
      description: form.description,
      collectionId: collection.collectionId,
      updatedAt: collection.updatedAt,
      type: collection.type,
    };

    variables = file
      ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: collection.image.fileId } }
      : variables;
    upsertCollection({ variables });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="collection-edit-modal">
        <div className="collection-edit-modal__heading">
          <h2>Edit Collection</h2>
          <button type="button" onClick={closeModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <Input
          label="Name"
          name="name"
          placeholder="Enter game name"
          handleInput={handleFormChange}
          value={form.name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          name="description"
          value={form.description}
          handleInput={handleFormChange}
          placeholder="Enter game description"
          showCounter
          maxLength={100}
          errorMessage={errors?.description}
        />
        <EditableImage imageUrl={form.image} alt="game" setFile={setFile} />
        <div className="collection-edit-modal__asset_actions">
          <button type="button" className="secondary-btn" onClick={closeModal} disabled={upsertLoading}>
            Cancel
          </button>
          <button type="button" className="primary-btn" onClick={handleSaveEdit} disabled={upsertLoading}>
            {upsertLoading ? <Spinner size="sm" /> : 'Update'}
          </button>
        </div>
      </div>
    </Modal>
  );
};
