import { Dispatcher, Event } from '../../../global/interfaces';
import { useState } from 'react';
import { useReneMutation, useValidation } from '../../../hooks';
import { TOP_UP_BALANCE_MUTATION } from '../../../global/gql/mutations';
import { validations } from './validations';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import './top-up-credit-modal.scss';

const TopUpCreditModal = ({
  redirectPage,
  setIsModalOpen,
}: {
  redirectPage: string;
  setIsModalOpen: Dispatcher<'top-up' | 'payment-history' | undefined>;
}) => {
  const { isFieldInvalid, errors } = useValidation(validations);
  const [amount, setAmount] = useState('');

  const [topUpBalance, { loading }] = useReneMutation(TOP_UP_BALANCE_MUTATION, {
    onCompleted: (data: { TopupBalance: { paymentLink: string } }) => {
      if (data?.TopupBalance.paymentLink) {
        window.location.href = data.TopupBalance.paymentLink;
      }
    },
  });

  const handleAddingFixedAmount = (e: Event['Button']) => setAmount(e.currentTarget.name);
  const handleModalClose = () => {
    setIsModalOpen(undefined);
  };

  const handleTopUp = () => {
    if (isFieldInvalid('amount', { amount })) return;

    topUpBalance({
      variables: {
        amount,
        successUrl: `${window.location.origin}/${redirectPage}/credit#success`,
        cancelUrl: `${window.location.origin}/${redirectPage}/credit#fail`,
      },
    });
  };

  return (
    <div className="top-up-credit-modal">
      <div className="top-up-credit-modal__heading">
        <h2>Credit purchase</h2>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="top-up-credit-modal__main">
        <div className="top-up-credit-modal__main_menu">
          <button className="top-up-credit-modal__main_menu_active">
            <p>Fiat</p>
          </button>
          <button className="top-up-credit-modal__main_menu_disabled">
            <p>Crypto</p>
          </button>
        </div>
        <div className="top-up-credit-modal__main_actions">
          <h3>Credit (USD)</h3>
          <div className="top-up-credit-modal__main_actions_preset">
            <button name="5" className="secondary-btn" onClick={handleAddingFixedAmount}>
              $5
            </button>
            <button name="10" className="secondary-btn" onClick={handleAddingFixedAmount}>
              $10
            </button>
            <button name="25" className="secondary-btn" onClick={handleAddingFixedAmount}>
              $25
            </button>
            <button name="50" className="secondary-btn" onClick={handleAddingFixedAmount}>
              $50
            </button>
          </div>
          <h3>Enter amount</h3>
          <div className="top-up-credit-modal__main_actions_input">
            <p>$</p>
            <Input
              value={amount}
              type="number"
              placeholder="Enter amount"
              handleInput={(e) => {
                const newValue = e.target.value;
                if (/^[1-9]\d*$/.test(newValue) || newValue === '') {
                  setAmount(newValue);
                }
              }}
              errorMessage={errors?.amount}
            />
          </div>
          <p className="top-up-credit-modal__main_actions_error">{errors?.amount}</p>
          <div className="top-up-credit-modal__main_actions_buttons">
            <button className="secondary-btn" onClick={handleModalClose}>
              Cancel
            </button>
            <button className="primary-btn" onClick={handleTopUp}>
              {loading ? <Spinner size="sm" /> : 'Purchase'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopUpCreditModal;
