import { ORG } from './../global/routes/index';
import {
  useMutation,
  MutationHookOptions,
  MutationFunctionOptions,
  DefaultContext,
  ApolloCache,
  ApolloError,
  MutationResult,
} from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { handleError } from '../utils';
import { useErrorContext } from '../context/error-context';
import { TokenType } from '../global/consts';

export const useReneMutation = (
  mutation: any,
  options?: MutationHookOptions<any, Record<string, any>>,
): [
  mutate: (
    options?: MutationFunctionOptions<any, Record<string, any>, DefaultContext, ApolloCache<any>> | undefined,
  ) => any,
  props: MutationResult<any>,
] => {
  const { setError } = useErrorContext();
  const location = useLocation();
  const isOrg = !!location.pathname.match(`/${ORG}`);
  const [mutate, props] = useMutation(mutation, {
    context: {
      headers: {
        authorization: isOrg ? TokenType.ORG : TokenType.GAMER,
      },
    },
    onError: (err: ApolloError) => {
      handleError(err, setError);
    },
    ...options,
  });

  return [mutate, props];
};
