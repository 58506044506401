import { useErrorContext } from '../context/error-context';
import { handleError } from '../utils';

export const useFileUpload = () => {
  const { setError } = useErrorContext();
  const uploadImage = (imageUploadUrl: string, file: File) =>
    fetch(imageUploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': `${file?.type}`,
      },
      body: file,
    }).catch((err) => {
      handleError(err, setError);
    });

  return uploadImage;
};
