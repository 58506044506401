import { Link } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { useReneQuery } from '../../../hooks';
import { differenceInDays } from 'date-fns';
import { GET_PUBLIC_AD_CAMPAIGN_QUERY, GET_USER_NFTS } from '../../../global/gql/queries';
import { Dispatcher, PublicCampaignData, PublicCampaignsData, UserData } from '../../../global/interfaces';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import LeaderboardModal from '../../../components/modal/leaderboard-modal/leaderboard-modal';

import point from '../../../global/images/point.png';
import placeholder from '../../../global/images/avatar.webp';

import './points-and-rewards.scss';

const PublicCampaignBar = ({
  campaign,
  daysLeft,
  multiplier,
  setSelectedCampaignId,
}: {
  campaign: PublicCampaignData;
  daysLeft?: number;
  multiplier?: string;
  setSelectedCampaignId: Dispatcher<string>;
}) => {
  const [showGaols, setShowGaols] = useState(false);
  console.log(multiplier);
  return (
    <div className="public-campaign-bar">
      <div className="public-campaign-bar__info">
        <img src={campaign.campaignImage.url || placeholder} alt="campaign" />
        <div>
          <h2>{campaign.name}</h2>
          {campaign?.actions ? (
            <button
              onClick={() => setShowGaols(!showGaols)}
              className={showGaols ? 'public-campaign-bar__info_showGoals' : ''}
            >
              <p>{showGaols ? 'Hide' : 'Show'} goals</p>
              <Icon name={'chevron-down'} />
            </button>
          ) : null}
        </div>
      </div>
      {showGaols ? (
        <div className="public-campaign-bar__goals">
          {campaign?.actions.map((action) => (
            <div key={action.actionId} className={action.fulfilled ? 'public-campaign-bar__goals_fulfilled' : ''}>
              <Icon name="flag" />
              <p>{action.description}</p>
              <div>
                <p>
                  {action.amount} <img src={point} alt="rene point" />
                </p>
                {action.fulfilled ? (
                  <p>
                    <Icon name="checked" /> <span>Done</span>
                  </p>
                ) : (
                  <Link className="secondary-btn" to={action.url} target="_blank" rel="noreferrer">
                    Start Quest
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className="public-campaign-bar__stats">
        {daysLeft && (
          <div>
            <h2>
              <span>Campaign</span> Status
            </h2>
            <h3>{`${daysLeft} days left`}</h3>
          </div>
        )}
        <button onClick={() => setSelectedCampaignId(campaign.adCampaignId)}>
          <h2>Your Rank</h2>
          <h3>{campaign.leaderboard?.items[0]?.rank || '-'}</h3>
        </button>
        <div>
          <h2>Your Points</h2>
          <h3>{campaign.leaderboard?.items[0]?.amount || '0'}</h3>
        </div>
        {multiplier ? (
          <div className="public-campaign-bar__stats_multiplier">
            <h2>Point Multiplier</h2>
            <h3>
              <Icon name="rene-card-logo" />
              <span>{multiplier}</span>
            </h3>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const PointsAndRewards = ({ setOpenMobileMenu }: { setOpenMobileMenu: Dispatcher<boolean> }) => {
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const { data: userNfts } = useReneQuery<{ User: UserData }>(GET_USER_NFTS);
  const { data, loading: campaignsLoading } = useReneQuery<{ PublicAdCampaigns: PublicCampaignsData } | undefined>(
    GET_PUBLIC_AD_CAMPAIGN_QUERY,
    {
      variables: {
        onlyCurrentUser: true,
      },
    },
  );

  const repackData = data?.PublicAdCampaigns.items.reduce<{
    active: ReactNode[];
    passed: ReactNode[];
  }>(
    (acc, campaign) => {
      const daysLeft = differenceInDays(new Date(campaign.endDate), new Date());

      if (daysLeft > 0) {
        acc.active.push(
          <PublicCampaignBar
            key={campaign.adCampaignId}
            campaign={campaign}
            daysLeft={daysLeft}
            setSelectedCampaignId={setSelectedCampaignId}
            multiplier={userNfts?.User?.stakedNft?.multiplier}
          />,
        );
      } else {
        acc.passed.push(
          <PublicCampaignBar
            key={campaign.adCampaignId}
            campaign={campaign}
            setSelectedCampaignId={setSelectedCampaignId}
          />,
        );
      }

      return acc;
    },
    { active: [], passed: [] },
  );

  return (
    <div className="points-and-rewards">
      <div className="points-and-rewards__heading">
        <button onClick={() => setOpenMobileMenu(true)}>
          <Icon name="hamburger" />
        </button>
        <h1>Points & Rewards</h1>
      </div>
      <div className="points-and-rewards__content">
        {repackData?.active.length ? <h2>Active campaigns</h2> : null}
        <div className="points-and-rewards__content_campaigns">
          {!campaignsLoading ? repackData?.active : <LoadingFallback />}
        </div>
        {repackData?.passed.length ? <h2>Passed campaigns</h2> : null}
        {repackData?.passed.length ? (
          <div className="points-and-rewards__content_campaigns">{repackData?.passed}</div>
        ) : null}
      </div>
      <Modal isOpen={!!selectedCampaignId}>
        <LeaderboardModal adCampaignId={selectedCampaignId} setShowLeaderboard={setSelectedCampaignId} />
      </Modal>
    </div>
  );
};

export default PointsAndRewards;
