import { useCallback, useEffect, useState } from 'react';
import { useLazyReneQuery, useReneMutation } from '../../../hooks';
import { format, isPast } from 'date-fns';
import { CampaignData, CampaignsData, Event, UserData } from '../../../global/interfaces';
import { CAMPAIGNS_SEARCH_QUERY, GET_AD_CAMPAIGNS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import { UserRole } from '../../../global/consts';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Select from '../../../components/select/select';
import Search from '../../../components/search/search';
import Statistics from '../../../components/statistics/statistics';
import CampaignModal from '../../../components/modal/campaign-modal/campaign-modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import SmallDashboardBar from '../../../components/small-dashboard-bar/small-dashboard-bar';

import './organization-campaigns-page.scss';

const OrganizationCampaignsPage = () => {
  const [adCampaignSearchTerm, setSearchTerm] = useState('');

  const [isNewCampaignModalOpen, setIsNewCampaignModalOpen] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const {
    data,
    loading: campaignsLoading,
    refetch,
  } = useReneQuery<{ AdCampaigns: CampaignsData } | undefined>(GET_AD_CAMPAIGNS_QUERY);

  const [search, { data: searchCampaigns, loading: searchLoading }] = useLazyReneQuery<{
    AdCampaignSearch: CampaignsData;
  }>(CAMPAIGNS_SEARCH_QUERY);

  const [upsertCampaign] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted() {
      refetch();
    },
  });

  useEffect(() => {
    if (adCampaignSearchTerm) {
      search({ variables: { adCampaignSearchTerm } });
    }
  }, [adCampaignSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const handleCleanCampaign = (e: Event['Button']) => {
    e.stopPropagation();
    e.preventDefault();
    upsertCampaign({
      variables: {
        isDemo: false,
        adCampaignId: e.currentTarget.name,
      },
    });
  };

  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, [UserRole.ADVERTISER]);
  const showCampaignData =
    adCampaignSearchTerm && searchCampaigns ? searchCampaigns.AdCampaignSearch : data?.AdCampaigns;

  const campaigns = showCampaignData?.items.map((campaign: CampaignData) => (
    <SmallDashboardBar
      id={campaign.adCampaignId}
      key={campaign.adCampaignId}
      linkTo={`${campaign.adCampaignId}`}
      name={campaign.name}
      imageUrl={campaign?.campaignImage?.url}
      description={campaign?.brand?.name}
    >
      <>
        <p className="small-dashboard-bar__time">
          {!isPast(new Date(campaign.endDate)) ? <span>Active</span> : null}
          {format(new Date(campaign.startDate), 'MMM dd, yyyy')} - {format(new Date(campaign.endDate), 'MMM dd, yyyy')}
        </p>
        <div className="small-dashboard-bar__stats">
          <Statistics
            data={{
              impressions: campaign?.stats?.impressions,
              // interactions: campaign?.stats?.interactions,
            }}
          />
        </div>
        {campaign.isDemo && user?.User?.isDemo ? (
          <button
            name={campaign.adCampaignId}
            className="primary-btn small-dashboard-bar__clean"
            onClick={handleCleanCampaign}
          >
            X
          </button>
        ) : null}
      </>
    </SmallDashboardBar>
  ));

  return (
    <div className="organization-campaigns-page">
      <div className="organization-campaigns-page__heading">
        <div className="organization-campaigns-page__heading_title">
          <h1>
            Campaigns <span>{showCampaignData?.items.length}</span>
          </h1>
          {/* <div>
            <p>
              <span> {showCampaignData?.items[0]?.stats?.impressions}</span> Impressions
            </p>
            <p>
              <span>{showCampaignData?.items[0]?.stats?.interactions}</span> Interactions
            </p>
          </div> */}
        </div>
        {isUserAllowed && (
          <button
            type="button"
            className="primary-btn organization-campaigns-page__heading_new"
            onClick={() => setIsNewCampaignModalOpen(true)}
          >
            <Icon name="plus" size={24} />
            <p>New Campaign</p>
          </button>
        )}
      </div>
      <div className="organization-campaigns-page__content">
        <div className="organization-campaigns-page__content_actions">
          <Search callback={handleSearch} apiSearch />
          <Select value="Latest" options={['Latest']} changeHandler={() => {}}>
            <Icon name="arrows-sort" size={16} />
          </Select>
        </div>
        <div className="organization-campaigns-page__content_campaigns">
          {campaignsLoading || searchLoading ? <LoadingFallback /> : campaigns}
        </div>
      </div>
      <Modal isOpen={isNewCampaignModalOpen}>
        <CampaignModal setCloseModal={() => setIsNewCampaignModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationCampaignsPage;
