import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyReneQuery } from '../../hooks';
import { Event } from '../../global/interfaces';
import { GET_GAME_GENRES } from '../../global/gql/queries';
import Icon from '../Icon/Icon';
import Search from '../search/search';
import Spinner from '../spinner/spinner';

import './search-values.scss';

const AddedValues = ({
  value,
  disabled,
  deleteTextAttribute,
}: {
  value: string;
  disabled: boolean | undefined;
  deleteTextAttribute: (e: Event['Button']) => void;
}) => {
  return (
    <div className="search-values__main_value">
      <p>{value}</p>
      {!disabled && (
        <button name={value} type="button" onClick={deleteTextAttribute} disabled={disabled}>
          <Icon name="close" />
        </button>
      )}
    </div>
  );
};

export const SearchValues = ({
  label,
  values,
  errorMessage,
  disabled,
  deleteValueHandler,
  changeHandler,
}: {
  label?: string;
  values: string[];
  errorMessage?: string;
  disabled?: boolean;
  deleteValueHandler: (value: string) => void;
  changeHandler: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const [gameGenreSearchTerm, setGameGenreSearchTerm] = useState('');
  const [alreadyExists, setAlreadyExists] = useState<string>('');
  const [addValue, setAddValue] = useState(false);

  const [search, { data, loading }] = useLazyReneQuery<{ GameGenreSearch: [string] }>(GET_GAME_GENRES);

  const showGenres = gameGenreSearchTerm && data ? data.GameGenreSearch : [];

  useEffect(() => {
    const handleOuterClick = (event: { target: any }) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setAddValue(false);
      }
    };

    document.addEventListener('mousedown', handleOuterClick);
    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [inputRef]);

  useEffect(() => {
    if (gameGenreSearchTerm) {
      search({ variables: { gameGenreSearchTerm } });
    }
  }, [gameGenreSearchTerm, search]);

  const handleSearch = useCallback((data: any) => {
    setGameGenreSearchTerm(data);
  }, []);

  const handleAddGenre = (value: string) => {
    const doesExist = values.find((genre) => genre === value);
    if (doesExist) {
      return setAlreadyExists('This genre name already exists');
    }
    if (alreadyExists) {
      setAlreadyExists('');
    }
    if (searchRef.current) {
      searchRef.current.focus();
    }

    changeHandler(value);
    setGameGenreSearchTerm('');
  };

  const deleteValue = (e: Event['Button']) => {
    const { name } = e.currentTarget;
    deleteValueHandler(name);
  };

  return (
    <>
      <div className="search-values__heading">{label ? <label>{label}</label> : null}</div>
      <div className="search-values__main">
        {values.map((value) => (
          <AddedValues value={value} key={value} deleteTextAttribute={deleteValue} disabled={disabled} />
        ))}
        <div className="search-values__main_input" ref={inputRef}>
          {addValue ? (
            <div className="search-values__main_input_search">
              {loading ? <Spinner size="sm" /> : null}
              <Search
                key={values.length}
                ref={searchRef}
                callback={handleSearch}
                disabled={disabled}
                apiSearch
                autoFocus
              />
              {showGenres.length ? (
                <div className="search-values__main_input_select">
                  {showGenres.map((genre, i) => (
                    <button key={i} onClick={() => handleAddGenre(genre)}>
                      {genre}
                    </button>
                  ))}
                </div>
              ) : null}
            </div>
          ) : (
            <button onClick={() => setAddValue(true)}>
              <Icon name="plus" size={16} /> <p>New value</p>
            </button>
          )}
        </div>
      </div>
      <p className="search-values__error">{alreadyExists || errorMessage}</p>
    </>
  );
};
