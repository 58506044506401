import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Event, Dispatcher, UserData, Refetch, CollectionsData, BrandedAssetData } from '../../../global/interfaces';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { GET_USER_QUERY, createCollectionsQuery } from '../../../global/gql/queries';
import { UPSERT_BRANDED_ASSET_MUTATION } from '../../../global/gql/mutations';
import { BRANDED_ASSETS, COLLECTIONS, GAMES, ORG } from '../../../global/routes';
import { isRoleAllowed } from '../../../utils';
import { validations } from './validations';
import { AssetType, UserRole } from '../../../global/consts';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import Modal from '../../modal/modal';
import placeholder from '../../../global/images/avatar.webp';
import LoadingFallback from '../../loading-fallback/loading-fallback';
import EditableImage from '../../editable-image/editable-image';

import './branded-asset-banner.scss';

interface Props {
  brandedAsset: BrandedAssetData | undefined;
  refetch: Refetch<{
    BrandedAssets: {
      items: BrandedAssetData[];
    };
  }>;
}

const BrandedAssetBanner: React.FC<Props> = ({ brandedAsset, refetch }) => {
  const params = useParams();
  const navigate = useNavigate();
  const [isShrunken, setIsShrunken] = useState(false);
  const [isEdit, setEdit] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const { data: collection } = useReneQuery<{
    Collections: CollectionsData;
  }>(createCollectionsQuery(AssetType.BRANDED), {
    variables: {
      collectionId: params.collectionId,
    },
  });

  const allowUpsertBrandedAsset = isRoleAllowed(user?.User.role as UserRole, [UserRole.CREATOR]) && !params.gameId;
  const backLink = params.gameId
    ? `/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}/${params.collectionId}/${BRANDED_ASSETS}`
    : `/${ORG}/${COLLECTIONS}/${BRANDED_ASSETS}/${params.collectionId}`;

  const brandedAssetOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Edit',
        icon: <Icon name="edit" />,
        func: setEdit,
      },
    ],
    [],
  );

  const handleBrandedAssetAction = useCallback(
    (index: number) => {
      brandedAssetOptions[index].func(true);
    },
    [brandedAssetOptions],
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 250) {
        setIsShrunken(true);
      } else {
        setIsShrunken(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const brandedAssetActions = () => (
    <div className="branded-asset__banner_actions">
      <Select
        className="branded-asset__banner_actions_dots"
        value=""
        placeholder=""
        options={brandedAssetOptions}
        changeHandler={handleBrandedAssetAction}
        showListValueFn={(option) => {
          return (
            <>
              {option.icon} {option.label}
            </>
          );
        }}
      >
        <Icon name="dots" size={44} />
      </Select>
    </div>
  );

  return (
    <div className={`branded-asset ${isShrunken && 'branded-asset--shrunken'}`}>
      <div className="branded-asset__breadcrumbs_mobile">
        <button onClick={() => navigate(backLink)}>
          <Icon name="chevron-left" />
          <p>Back</p>
        </button>
      </div>
      <div className={`branded-asset__banner${isShrunken ? '--shrunken' : '--full'}`}>
        <img src={brandedAsset?.image?.url || placeholder} alt="branded asset" />
        <div>
          <p>{collection?.Collections.items[0].name}</p>
          <p>{brandedAsset?.name} </p>
        </div>
        {allowUpsertBrandedAsset && brandedAssetActions()}
      </div>
      <div className="branded-asset__banner">
        <img src={brandedAsset?.image?.url || placeholder} alt="branded asset" />
        {brandedAsset ? (
          <>
            <div className="branded-asset__banner_heading">
              <p className="branded-asset__banner_heading_collection">{collection?.Collections.items[0].name}</p>
              <p className="branded-asset__banner_heading_name">{brandedAsset.name}</p>
              <p className="branded-asset__banner_heading_stats">
                <Icon name="gamepad" />
                <span>Used in {brandedAsset?.stats?.games} games</span>
              </p>
            </div>
            <div className="branded-asset__banner_description">
              <p>{brandedAsset.description} </p>
            </div>
            {allowUpsertBrandedAsset && brandedAssetActions()}
          </>
        ) : (
          <LoadingFallback />
        )}
        {brandedAsset && (
          <>
            <EditBrandedAssetModal
              brandedAsset={brandedAsset}
              isOpen={isEdit}
              collectionId={params.collectionId as string}
              closeModal={() => setEdit(false)}
              refetch={refetch}
            />
          </>
        )}
      </div>
    </div>
  );
};

const EditBrandedAssetModal = ({
  brandedAsset,
  isOpen,
  closeModal,
  refetch,
}: {
  brandedAsset: BrandedAssetData;
  isOpen: boolean;
  collectionId: string;
  closeModal: () => void;
  refetch: Refetch<{
    BrandedAssets: {
      items: BrandedAssetData[];
    };
  }>;
}) => {
  const uploadImage = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);

  const [file, setFile] = useState<File>();
  const [form, setForm] = useState<{ name: string; description: string; image: string }>({
    name: '',
    description: '',
    image: '',
  });
  const [prevBrandedAsset, setPrevBrandedAsset] = useState<BrandedAssetData | undefined>();

  const [upsertBrandedAsset, { loading: upsertLoading }] = useReneMutation(UPSERT_BRANDED_ASSET_MUTATION, {
    onCompleted(data: { UpsertBrandedAsset: BrandedAssetData }) {
      if (file) {
        uploadImage(data.UpsertBrandedAsset.image.uploadUrl, file).then(() => {
          refetch();
          closeModal();
        });
      } else {
        refetch();
        closeModal();
      }
    },
  });

  if (brandedAsset && !prevBrandedAsset && brandedAsset !== prevBrandedAsset) {
    setForm((prev) => ({
      ...prev,
      name: brandedAsset.name,
      description: brandedAsset.description,
      image: brandedAsset.image?.url,
    }));
    setPrevBrandedAsset(brandedAsset);
  }

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSaveEdit = () => {
    if (isFormInvalid(form)) return;
    if (!brandedAsset?.brandedAssetId) return;
    let variables: {
      name: string;
      brandedAssetId: string;
      updatedAt: string;
      description?: string;
      image?: { extension: string; fileId: string };
    } = {
      name: form.name,
      description: form.description,
      brandedAssetId: brandedAsset.brandedAssetId,
      updatedAt: brandedAsset.updatedAt,
    };

    variables = file
      ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: brandedAsset.image.fileId } }
      : variables;

    upsertBrandedAsset({ variables });
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="branded-asset-edit-modal">
        <div className="branded-asset-edit-modal__heading">
          <h2>Edit Branded Asset</h2>
          <button type="button" onClick={closeModal}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <Input
          label="Name"
          name="name"
          placeholder="Enter game name"
          handleInput={handleFormChange}
          value={form.name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          name="description"
          value={form.description}
          handleInput={handleFormChange}
          placeholder="Enter game description"
          showCounter
          maxLength={100}
          errorMessage={errors?.description}
        />
        <EditableImage imageUrl={form.image} alt="game" setFile={setFile} />
        <div className="branded-asset-edit-modal__asset_actions">
          <button type="button" className="secondary-btn" onClick={closeModal} disabled={upsertLoading}>
            Cancel
          </button>
          <button type="button" className="primary-btn" onClick={handleSaveEdit} disabled={upsertLoading}>
            {upsertLoading ? <Spinner size="sm" /> : 'Update'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

// const BreadCrumbs = ({
//   selectedGame,
//   gameId,
//   collectionId,
//   collection,
//   brandedAssetName,
// }: {
//   selectedGame: GamesData | undefined;
//   gameId: string;
//   collectionId: string;
//   collection: CollectionsData | undefined;
//   brandedAssetName: string;
// }) => {
//   return (
//     <div className="branded-asset__breadcrumbs_desktop">
//       <Link to={`/${ORG}`}>
//         <p>All games</p>
//       </Link>
//       <p>{'>'}</p>
//       <Link to={`/${ORG}/${GAMES}/${gameId}/${COLLECTION}`}>
//         <p>{selectedGame?.items[0]?.name}</p>
//       </Link>
//       <p>{'>'}</p>
//       <Link to={`/${ORG}/${GAMES}/${gameId}/${COLLECTION}/${collectionId}/${'branded'}`}>
//         <p>{collection?.items[0]?.name}</p>
//       </Link>
//       <p>{'>'}</p>
//       <Link to="">
//         <p>{brandedAssetName}</p>
//       </Link>
//     </div>
//   );
// };

export default BrandedAssetBanner;
