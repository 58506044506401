import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dispatcher } from '../../global/interfaces';
import {
  parse,
  format,
  startOfToday,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  startOfWeek,
  isToday,
  isSameMonth,
  isEqual,
  eachMonthOfInterval,
} from 'date-fns';
import Icon from '../Icon/Icon';
import Select from '../select/select';

import './calendar.scss';

const daysOfTheWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const startYear = new Date().getFullYear() - 90;
const endYear = new Date().getFullYear() + 70;

interface Props extends React.PropsWithChildren {
  className?: string;
}

const Cell: React.FC<Props> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

const Calendar = ({
  selectedDay,
  setSelectedDay,
  setIsOpenCalendar,
}: {
  selectedDay: Date;
  setSelectedDay: Dispatcher<Date>;
  setIsOpenCalendar?: Dispatcher<boolean>;
}) => {
  const today = startOfToday();
  const [currentMonth, setCurrentMonth] = useState(format(selectedDay || today, 'MMM-yyyy'));
  const [currentYear, setCurrentYear] = useState(format(selectedDay || today, 'yyyy'));
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const endOfYear = new Date(today.getFullYear(), 11, 31);
  const monthsList = eachMonthOfInterval({ start: startOfYear, end: endOfYear });
  const formattedMonths = monthsList.map((month) => format(month, 'MMMM'));
  const firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date(currentYear));
  const newDays = eachDayOfInterval({
    start: startOfWeek(startOfMonth(firstDayCurrentMonth)),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  });

  const yearsList = useMemo(() => {
    const list: string[] = [];
    for (let year = startYear; year <= endYear; year++) {
      list.push(year.toString());
    }
    return list;
  }, []);

  const handleSelectedDay = useCallback(
    (day: Date) => {
      setSelectedDay(day);
      if (setIsOpenCalendar) {
        setIsOpenCalendar(false);
      }
    },
    [setIsOpenCalendar, setSelectedDay],
  );

  const handleSectedMonth = useCallback(
    (index: number, year = currentYear) => {
      const firstDayOfMonth = startOfMonth(new Date(Number.parseInt(year), index, 1));
      setCurrentMonth(format(firstDayOfMonth, 'MMM-yyyy'));
    },
    [currentYear],
  );

  const handleSelectYear = (index: number) => {
    setCurrentYear(yearsList[index]);
    handleSectedMonth(
      monthsList.map((month) => format(month, 'MMM')).indexOf(currentMonth.split('-')[0]),
      yearsList[index],
    );
  };

  return (
    <div className="calendar">
      <div className="calendar__heading">
        <Select
          value={format(firstDayCurrentMonth, 'MMMM')}
          options={formattedMonths}
          changeHandler={handleSectedMonth}
        />
        <Select value={currentYear} options={yearsList} changeHandler={handleSelectYear} />
      </div>
      <div className="calendar__main">
        {daysOfTheWeek.map((day) => (
          <Cell key={day}>{day}</Cell>
        ))}

        {newDays.map((day, i) => {
          return (
            <Cell
              key={i}
              className={`calendar__main ${isToday(day) && 'today'} ${isEqual(day, selectedDay) && 'selected-day'}`}
            >
              <button
                onClick={() => handleSelectedDay(day)}
                className={`calendar__main_day 
                ${!isSameMonth(day, firstDayCurrentMonth) && 'not-current'}`}
              >
                <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
              </button>
            </Cell>
          );
        })}
      </div>
    </div>
  );
};

const DatePicker = ({ selectedDay, setSelectedDay }: { selectedDay: Date; setSelectedDay: Dispatcher<Date> }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOuterClick = (event: { target: any }) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpenCalendar(false);
      }
    };

    document.addEventListener('mousedown', handleOuterClick);
    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [selectRef]);

  return (
    <div className="date-picker" ref={selectRef}>
      <button className="date-picker__select" onClick={() => setIsOpenCalendar(!isOpenCalendar)}>
        <p>{format(selectedDay, 'dd-MM-yyyy')}</p>
        <Icon name="calendar" />
      </button>
      {isOpenCalendar ? (
        <Calendar selectedDay={selectedDay} setSelectedDay={setSelectedDay} setIsOpenCalendar={setIsOpenCalendar} />
      ) : null}
    </div>
  );
};

export { Calendar, DatePicker };
