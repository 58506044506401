import './metadata-template-options.scss';

const MetadataTemplateOptions = ({
  style,
  filteredOptions,
  handleOptionClick,
}: {
  style?: React.CSSProperties;
  filteredOptions?: string[];
  handleOptionClick: (option: string) => void;
}) => {
  if (!filteredOptions?.length) {
    return null;
  }
  return (
    <div className="metadata_template_options" style={style}>
      <div>
        {filteredOptions?.map((option) => (
          <p
            key={option}
            onClick={(e) => {
              handleOptionClick(option);
            }}
          >
            {option}
          </p>
        ))}
      </div>
    </div>
  );
};

export default MetadataTemplateOptions;
