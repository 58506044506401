import { useState } from 'react';
import { AdData, CampaignsData, Content, Event, Refetch } from '../../../global/interfaces';
import { useFileUpload, useReneMutation, useValidation } from '../../../hooks';
import { validations } from './validations';
import { AdType } from '../../../global/consts';
import { CONTENT_SEARCH } from '../../../global/gql/queries';
import { DELETE_ADD_MUTATION, UPSERT_AD_MUTATION } from '../../../global/gql/mutations';
import { SearchContentValues } from '../../search-content-values/search-content-values';
import { removeTypename } from '../../../utils';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Modal from '../modal';
import Spinner from '../../spinner/spinner';
import ReachText from '../../reach-text/reach-text';
import FileUpload from '../../file-upload/file-upload';
import DeleteModal from '../delete-modal/delete-modal';
import EditableImage from '../../editable-image/editable-image';

import avatar from '../../../global/images/avatar.webp';

import './modify-ad-modal.scss';

const ModifyAdModal = ({
  ad,
  allowEditCampaign,
  refetch,
  setCloseModal,
}: {
  ad?: AdData;
  allowEditCampaign: boolean;
  refetch: Refetch<{ AdCampaigns: CampaignsData } | undefined>;
  setCloseModal: () => void;
}) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid, isFieldInvalid } = useValidation(validations);
  const [isEdit, setIsEdit] = useState(false);
  const [file, setFile] = useState<File>();
  const [isDeleteAdModalOpen, setIsDeleteAdModalOpen] = useState(false);
  const [loading, setLoading] = useState<'update' | 'deactivate' | 'activate' | undefined>();
  const [reachText, setReachText] = useState('');
  const [previousData, setPreviousData] = useState<AdData>();
  const [form, setForm] = useState<{ name: string; video: string; contentTags: Content[] }>({
    name: '',
    video: '',
    contentTags: [],
  });

  if (ad && ad !== previousData) {
    setPreviousData(ad);
    setForm({
      name: ad.name,
      video: ad?.video?.url,
      contentTags: removeTypename(ad.contentTags),
    });
    setReachText(ad.interactionHtml);
  }

  const [upsertAd] = useReneMutation(UPSERT_AD_MUTATION, {
    onCompleted(data: { UpsertAd: AdData }) {
      const type = data.UpsertAd.adType === AdType.BANNER ? 'banner' : 'video';
      if (file && type) {
        uploadFile(data.UpsertAd[type].uploadUrl, file).finally(() => {
          handleFinishUpsert();
        });
      } else {
        handleFinishUpsert();
      }
    },
  });

  const [deleteAd, { loading: loadingDelete }] = useReneMutation(DELETE_ADD_MUTATION, {
    variables: {
      adId: ad?.adId,
    },
    onCompleted() {
      refetch();
      setLoading(undefined);
      setCloseModal();
    },
  });

  const handleUpsertAd = (e: Event['Button'], action: 'update' | 'deactivate' | 'activate') => {
    if (isFormInvalid(form)) return;
    setLoading(action);
    let variables: {
      name: string;
      adId: string;
      updatedAt: string;
      interactionHtml: string;
      video?: {
        extension: string;
      };
      banner?: {
        extension: string;
      };
      isActive?: boolean;
      contentTags?: Content[];
    } = {
      adId: ad?.adId as string,
      name: form.name,
      updatedAt: ad?.updatedAt as string,
      interactionHtml: reachText,
      isActive: ad?.isActive,
      contentTags: form.contentTags,
    };
    variables = file
      ? { ...variables, [ad?.adType === AdType.BANNER ? 'banner' : 'video']: { extension: file?.type.split('/')[1] } }
      : variables;

    variables = action === 'deactivate' ? { ...variables, isActive: false } : variables;
    variables = action === 'activate' ? { ...variables, isActive: true } : variables;

    upsertAd({ variables });
  };

  const handleAdDelete = () => {
    setIsDeleteAdModalOpen(true);
    deleteAd();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const addTargetValue = (value: Content) => {
    setForm((prev) => ({ ...prev, contentTags: [...form.contentTags, removeTypename(value)] }));
  };

  const deleteTargetValue = (id: string) => {
    const contentTags = [...form.contentTags].filter((taxonomy) => taxonomy.taxonomyContentId !== id);
    setForm({ ...form, contentTags });
  };

  const handleFinishUpsert = () => {
    refetch();
    setLoading(undefined);
    setCloseModal();
  };

  return (
    <div className="modify-ad-modal">
      <div className="modify-ad-modal__heading">
        <h2>Ad detail</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      {ad?.adType === AdType.BANNER ? (
        <EditableImage imageUrl={ad?.banner?.url || avatar} alt="banner image" setFile={setFile} disabled={!isEdit} />
      ) : (
        <video autoPlay muted>
          <source src={ad?.video?.url} />
        </video>
      )}
      <div className="modify-ad-modal__name">
        <div>
          <h2>Name</h2>
          {!isEdit && <p>{ad?.name}</p>}
        </div>
        {isEdit && (
          <Input
            placeholder="Enter new name"
            handleInput={(e) => setForm((prev) => ({ ...prev, name: e.target.value }))}
            value={form.name}
            errorMessage={errors?.name}
          />
        )}
      </div>

      <SearchContentValues
        query={CONTENT_SEARCH}
        label="Ad Content Tags"
        values={form.contentTags}
        changeHandler={addTargetValue}
        deleteValueHandler={deleteTargetValue}
        disabled={!isEdit}
      />

      {ad?.adType === AdType.VIDEO && isEdit && (
        <FileUpload
          label="Video"
          setFiles={(files: File[]) => setFile(files?.[0])}
          description="Upload .mp4 video"
          errorMessage={errors?.file}
          handleChange={handleFileChange}
        />
      )}
      <ReachText reachText={reachText} setReachText={setReachText} error={errors?.reachText} readOnly={!isEdit} />
      <p className="modify-ad-modal__type">
        Type:<span>{ad?.adType}</span>
      </p>
      <p className="modify-ad-modal__status">
        Status:<span>{ad?.isActive ? 'Active' : 'Inactive'}</span>
      </p>
      {!isEdit && allowEditCampaign && (
        <div className="modify-ad-modal__edit_actions">
          {ad?.isActive ? (
            <button
              type="button"
              name="deactivate"
              className="secondary-btn"
              onClick={(e) => handleUpsertAd(e, 'deactivate')}
              disabled={!!loading}
            >
              {loading === 'deactivate' ? <Spinner size="sm" /> : <div>Deactivate</div>}
            </button>
          ) : (
            <>
              <button
                type="button"
                className="secondary-btn"
                onClick={() => setIsDeleteAdModalOpen(true)}
                disabled={!!loading}
              >
                {loadingDelete ? (
                  <Spinner size="sm" />
                ) : (
                  <div>
                    <Icon name="trash" />
                    Delete
                  </div>
                )}
              </button>
              <button
                type="button"
                className="primary-btn"
                disabled={!!loading}
                onClick={(e) => handleUpsertAd(e, 'activate')}
              >
                Activate
              </button>
            </>
          )}
          <button type="button" className="primary-btn" onClick={() => setIsEdit(true)} disabled={!!loading}>
            {loading === 'update' ? (
              <Spinner size="sm" />
            ) : (
              <div>
                <Icon name="edit" />
                <p>Edit</p>
              </div>
            )}
          </button>
        </div>
      )}
      {isEdit && (
        <div className="modify-ad-modal__save_actions">
          <button type="button" className="secondary-btn" onClick={() => setIsEdit(false)} disabled={!!loading}>
            Cancel
          </button>
          <button
            type="button"
            className="primary-btn"
            onClick={(e) => handleUpsertAd(e, 'update')}
            disabled={!!loading}
          >
            {loading === 'update' ? <Spinner size="sm" /> : 'Save'}
          </button>
        </div>
      )}
      <Modal isOpen={isDeleteAdModalOpen}>
        <DeleteModal
          isLoading={loadingDelete}
          text=" this ads? You cannot undo this step"
          setIsModalOpen={() => setIsDeleteAdModalOpen(false)}
          deleteHandler={handleAdDelete}
        />
      </Modal>
    </div>
  );
};

export default ModifyAdModal;
