import { useState, useCallback, useEffect } from 'react';
import { useLazyReneQuery } from '../../../hooks';
import { AssetData, AssetsData, Event } from '../../../global/interfaces';
import { ASSET_SEARCH_QUERY } from '../../../global/gql/queries';
import Modal from '../../modal/modal';
import Search from '../../search/search';
import AssetModal from '../../modal/asset-modal/asset-modal';
import AssetsList from '../assets-list/assets-list';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import './asset-search-list.scss';

const AssetSearchList = ({
  gameId,
  assetList,
  disableSearch = false,
}: {
  gameId?: string;
  assetList: AssetData[] | undefined;
  disableSearch?: boolean;
}) => {
  const [assetSearchTerm, setSearchTerm] = useState('');
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [isAssetModalOpen, setIsAssetModalOpen] = useState<boolean>(false);

  const [search, { data: searchAssets, loading: searchLoading }] = useLazyReneQuery<{ AssetSearch: AssetsData }>(
    ASSET_SEARCH_QUERY,
  );

  const showAssets = assetSearchTerm && searchAssets ? searchAssets.AssetSearch.items : assetList;

  useEffect(() => {
    if (assetSearchTerm) {
      search({ variables: { assetSearchTerm, gameId } });
    }
  }, [assetSearchTerm, search, gameId]);

  const handleSearch = useCallback((data: any) => {
    setSearchTerm(data);
  }, []);

  const handleSelectAsset = (e: Event['Button']) => {
    const target = e.currentTarget as HTMLButtonElement;
    setSelectedAsset(target.id as AssetData['assetId']);
    setIsAssetModalOpen(true);
  };

  return (
    <div className="asset-search-list">
      {!disableSearch && <Search callback={handleSearch} apiSearch />}
      {!searchLoading ? (
        <AssetsList assetsList={showAssets as AssetData[]} handleSelectAsset={handleSelectAsset} />
      ) : (
        <LoadingFallback />
      )}
      <Modal isOpen={isAssetModalOpen}>
        <AssetModal closeModal={() => setIsAssetModalOpen(false)} assetId={selectedAsset} />
      </Modal>
    </div>
  );
};

export default AssetSearchList;
