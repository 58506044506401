import { useState } from 'react';
import { OwnableAssetData, Dispatcher, CustomFile, Refetch } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import Modal from '../modal/modal';
import FilesTable from '../files-table/files-table';
import NoFilesUploaded from '../no-files-uploaded/no-files-uploaded';
import FilesUploadModal from '../modal/files-upload-modal/files-upload-modal';
import './files.scss';

const Files = ({
  type,
  files,
  selectedType,
  ownableAssetId,
  isUploadAllowed = true,
  refetch,
  setSelectedFolder,
}: {
  type: any;
  files?: CustomFile[];
  ownableAssetId: string;
  selectedType?: string;
  isUploadAllowed?: boolean;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
  setSelectedFolder: Dispatcher<'animations' | 'images' | ''>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="files">
      <div className="files__heading">
        <button type="button" onClick={() => setSelectedFolder('')}>
          <Icon name="chevron-left" size={16} />
          <h1 className="rainbow-btn-text">{type.toUpperCase()}</h1>
        </button>
        <div>
          <button className="secondary-btn">Action</button>
          {isUploadAllowed && (
            <button className="secondary-btn" onClick={handleModal}>
              Upload
            </button>
          )}
        </div>
      </div>
      <div className="files__main">{files?.length ? <FilesTable files={files} /> : <NoFilesUploaded />}</div>
      <Modal isOpen={isModalOpen}>
        <FilesUploadModal
          type={type}
          ownableAssetId={ownableAssetId}
          refetch={refetch}
          setCloseModal={handleModal}
          selectedType={selectedType}
          uploadedFiles={files}
        />
      </Modal>
    </div>
  );
};

export default Files;
