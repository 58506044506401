import { DragEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyReneQuery, useReneMutation, useReneQuery } from '../../../../hooks';
import { COLLECTIONS_SEARCH_QUERY, GET_GAMES_QUERY, GET_USER_QUERY } from '../../../../global/gql/queries';
import { isRoleAllowed } from '../../../../utils';
import { AssetType, UserRole } from '../../../../global/consts';
import { BRANDED_ASSETS, OWNABLE_ASSETS } from '../../../../global/routes';
import { CollectionData, CollectionsData, Event, GamesData, UserData } from '../../../../global/interfaces';
import { ADD_COLLECTION_TO_GAME_MUTATION } from '../../../../global/gql/mutations';
import Icon from '../../../../components/Icon/Icon';
import Modal from '../../../../components/modal/modal';
import Select from '../../../../components/select/select';
import Search from '../../../../components/search/search';
import GridBar from '../../../../components/grid-bar/grid-bar';
import CatalogPopup from '../../../../components/catalog-popup/catalog-popup';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import NewCollectionModal from '../../../../components/modal/new-collection-modal/new-collection-modal';
import CollectionsCatalogModal from '../../../../components/modal/collections-catalog-modal/collections-catalog-modal';
import collPlaceholder from '../../../../global/images/collection-placeholder.png';

import './organization-game-collections.scss';

const OrganizationGameCollections = () => {
  const params = useParams();
  const [collectionSearchTerm, setSearchTerm] = useState('');
  const [isNewCollectionModalOpen, setNewCollectionModalOpen] = useState<boolean>(false);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { data, refetch: refetchGame } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
    onCompleted: () => {
      setIsLoading(false);
    },
  });

  const [addCollectionToAGame] = useReneMutation(ADD_COLLECTION_TO_GAME_MUTATION, {
    onCompleted: () => {
      refetchGame().finally(() => {
        setIsLoading(false);
      });
    },
  });

  const [search, { data: searchCollections, loading: searchLoading }] = useLazyReneQuery<{
    CollectionSearch: CollectionsData;
  }>(COLLECTIONS_SEARCH_QUERY);

  useEffect(() => {
    if (collectionSearchTerm) {
      search({ variables: { collectionSearchTerm } });
    }
  }, [collectionSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const collections =
    collectionSearchTerm && searchCollections
      ? searchCollections.CollectionSearch.items
      : data?.Games?.items[0]?.collections?.items;
  const allowUpsertCollection = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);

  const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
    e.dataTransfer.setData('text/plain', e.currentTarget.id);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement> | Event['Button']) => {
    e.preventDefault();
    let draggedId = '';
    if ('dataTransfer' in e) {
      draggedId = e.dataTransfer.getData('text/plain');
    } else if ('currentTarget' in e) {
      draggedId = e.currentTarget.id;
      setIsCatalogOpen(false);
    }

    setIsLoading(true);
    addCollectionToAGame({
      variables: {
        collectionId: draggedId,
        gameId: params.gameId,
      },
    });
  };

  return (
    <div className="organization-game-collections">
      <div className="organization-game-collections__heading">
        <h2>Collections {collections?.length}</h2>
        {allowUpsertCollection && (
          <div>
            <button type="button" className="primary-btn center-btn" onClick={() => setNewCollectionModalOpen(true)}>
              <Icon name="plus" size={24} />
              <p>New Collection</p>
            </button>
            <button type="button" className="secondary-btn center-btn" onClick={() => setIsCatalogOpen(true)}>
              <p>Collections Catalog</p>
            </button>
          </div>
        )}
      </div>
      <div className="organization-game-collections__search">
        <Search callback={handleSearch} apiSearch />
        <Select value="Latest" options={['Latest']} changeHandler={() => {}}>
          <Icon name="arrows-sort" size={16} />
        </Select>
      </div>
      <div
        className={`organization-game-collections__main ${
          !collections?.length && 'organization-game-collections__main--empty'
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {!isLoading && !searchLoading ? (
          collections?.map((collection: CollectionData) => {
            const link =
              collection.type.toLowerCase() === AssetType.OWNABLE
                ? `${collection.collectionId}/${OWNABLE_ASSETS}`
                : `${collection.collectionId}/${BRANDED_ASSETS}`;
            return (
              <GridBar
                key={collection.collectionId}
                linkTo={link}
                img={collection?.image?.url}
                title={collection?.name}
                stats={collection.stats}
                brandName={collection?.brand?.name}
                type={collection.type.toLowerCase() as AssetType}
                placeholderImg={collPlaceholder}
              />
            );
          })
        ) : (
          <LoadingFallback />
        )}
      </div>
      <CatalogPopup isMenuOpen={isCatalogOpen} setIsOpen={setIsCatalogOpen}>
        <CollectionsCatalogModal
          onDragOver={handleDragOver}
          onDragStart={handleDragStart}
          onDrop={handleDrop}
          setCloseModal={() => setIsCatalogOpen(false)}
        />
      </CatalogPopup>
      <Modal isOpen={isNewCollectionModalOpen}>
        <NewCollectionModal
          type={AssetType.OWNABLE}
          gameId={params.gameId}
          setCloseModal={setNewCollectionModalOpen}
          callback={() => refetchGame()}
        />
      </Modal>
    </div>
  );
};

export default OrganizationGameCollections;
