import { useReneQuery } from '../../../hooks';
import { GET_USER_POINTS, GET_USER_QUERY } from '../../../global/gql/queries';
import { UserData } from '../../../global/interfaces';
import GamerBanner from '../../../components/banners/gamer-banner/gamer-banner';
import './assets-marketplace-page.scss';

const AssetsMarketplacePage = () => {
  const { data: fetchedUser } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);
  const { data: userPoint } = useReneQuery<{ User: UserData } | undefined>(GET_USER_POINTS);

  return (
    <div className="assets_marketplace_page">
      <GamerBanner user={fetchedUser?.User} points={userPoint?.User.points?.amount} />
      <h1 className="rainbow-btn-text">Assets Marketplace</h1>
    </div>
  );
};

export default AssetsMarketplacePage;
