import { useState } from 'react';
import { Dispatcher, Event } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import avatar from '../../global/images/avatar.webp';

import './editable-image.scss';

interface Props {
  label?: string;
  alt: string;
  imageUrl: string | undefined;
  disabled?: boolean;
  setFile: Dispatcher<File | undefined> | ((file: File) => void);
}

const EditableImage: React.FC<Props> = ({ label, imageUrl, alt, disabled, setFile }) => {
  const [image, setImage] = useState<string | undefined>(imageUrl);
  const [prevImage, setPrevImage] = useState<string | undefined>();

  if (prevImage !== imageUrl) {
    setPrevImage(imageUrl);
    setImage(imageUrl);
  }

  const handleFile = (e: Event['Input']) => {
    if (e.target?.files?.length) {
      setFile(e.target.files[0]);
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <div className="editable_image">
      {label && <label>{label}</label>}
      <div className="editable_image__image">
        <img src={image || avatar} alt={alt} />
      </div>
      {!disabled && (
        <div className="editable_image__edit">
          <Icon name="edit" />
          <input type="file" accept="image/*" multiple={false} onChange={handleFile} />
        </div>
      )}
    </div>
  );
};

export default EditableImage;
