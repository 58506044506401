import { useEffect, useRef } from 'react';

export const usePrevious = <T>(value: T): T => {
  const ref: any = useRef<T>(JSON.parse(JSON.stringify(value || '')));

  useEffect(() => {
    ref.current = JSON.parse(JSON.stringify(value || ''));
  }, [value]);

  return ref.current;
};
