import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AssetTypeId, UserRole } from '../../../global/consts';
import { GET_BRANDED_ASSETS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { MenuItems, UserData, BrandedAssetData } from '../../../global/interfaces';
import { ORG, COLLECTIONS, BRANDED_ASSETS, GAMES } from '../../../global/routes';
import { useReneQuery } from '../../../hooks';
import { isRoleAllowed } from '../../../utils';
import AsideMenu from '../../../components/aside-menu/aside-menu';
import AdSurfaceList from '../../../components/asset/ad-surface-list/ad-surface-list';
import AsidePopupMenu from '../../../components/aside-popup-menu/aside-popup-menu';
import GameEngineFiles from '../../../components/asset/game-engine-files/game-engine-files';
import BrandedAssetBanner from '../../../components/banners/branded-asset-banner/branded-asset-banner';

import './organization-branded-asset-page.scss';

const sideMenuSections: { [key: string]: { title: string; items: MenuItems } } = {
  files: {
    title: 'Game engine',
    items: {
      files: { name: 'files', icon: 'file', label: 'Files' },
    },
  },
  asset: {
    title: 'Asset',
    items: {
      surface: { name: 'surface', icon: 'pip', label: 'Surface' },
    },
  },
};

const OrganizationBrandedAssetPage = ({ disabled }: { disabled?: boolean }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const { data, refetch } = useReneQuery<{
    BrandedAssets: { items: BrandedAssetData[] };
  }>(GET_BRANDED_ASSETS_QUERY, {
    variables: { brandedAssetId: params.assetId },
  });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isUserAllowedToUpsert = isRoleAllowed(user?.User.role as UserRole, [UserRole.CREATOR]);

  const handleMenuItemChange = (value: string) => {
    const navigateLink = params.gameId
      ? `/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}/${params.collectionId}/${BRANDED_ASSETS}/${params.assetId}/${value}`
      : `/${ORG}/${COLLECTIONS}/${BRANDED_ASSETS}/${params.collectionId}/${params.assetId}/${value}`;
    navigate(navigateLink);
  };

  const renderSelectedMenuItem = (selectedMenuItem: string) => {
    switch (selectedMenuItem) {
      case 'files':
        return (
          <GameEngineFiles
            type={AssetTypeId.brandedAssetId}
            setOpenMobileMenu={setIsMobileMenuOpen}
            disabled={!!params.gameId}
          />
        );

      case 'surface':
        return (
          <AdSurfaceList
            id={params.assetId}
            type="brandedAssetId"
            isUserAllowedToUpsert={isUserAllowedToUpsert && !params.gameId}
            setOpenMobileMenu={setIsMobileMenuOpen}
          />
        );

      default:
        break;
    }
  };

  return (
    <div className="organization-branded-asset-page">
      <BrandedAssetBanner brandedAsset={data?.BrandedAssets.items[0]} refetch={refetch} />
      <div className="organization-branded-asset-page__main">
        <AsidePopupMenu isMenuOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen}>
          <div className="organization-branded-asset-page__main_aside">
            {Object.keys(sideMenuSections).map((key) => {
              const { title, items } = sideMenuSections[key];

              return (
                <div key={title}>
                  <h1 className="rainbow-btn-text">{title}</h1>
                  <AsideMenu
                    items={items}
                    selectedItem={params.menuItem as string}
                    handleItemSelect={handleMenuItemChange}
                  />
                </div>
              );
            })}
          </div>
        </AsidePopupMenu>
        <div className="organization-branded-asset-page__main_selected">
          {renderSelectedMenuItem(params.menuItem as string)}
        </div>
      </div>
    </div>
  );
};

export default OrganizationBrandedAssetPage;
