import { differenceInYears, isAfter, isToday } from 'date-fns';

export const validations = {
  endDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    const endDate = new Date(field);
    if (isToday(endDate) || !isAfter(endDate, new Date())) return 'The End date needs to be in the future';
    if (differenceInYears(endDate, new Date(form.startDate)) > 0) return 'Date interval cannot be more than a year';
  },
};
