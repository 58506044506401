import { Dispatcher } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import trashcan from '../../../global/images/trashcan.webp';
import './delete-modal.scss';

interface Props {
  text: string;
  setIsModalOpen: Dispatcher<boolean>;
  deleteHandler: Dispatcher<any>;
  isLoading?: boolean;
}

const DeleteModal: React.FC<Props> = ({ text, setIsModalOpen, deleteHandler, isLoading = false }) => {
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="delete_modal">
      <div className="delete_modal__heading">
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <img src={trashcan} alt="trashcan" />
      <p>Are you sure you want to delete {text}?</p>
      <div className="delete_modal__actions">
        <button type="button" className="secondary-btn" onClick={handleModalClose} disabled={isLoading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={deleteHandler} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : 'Delete'}
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
