import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  OrganizationOwnableAssetPage,
  OrganizationCampaignPage,
  OrganizationGamePage,
  OrganizationSettingsPage,
  OrganizationGamesPage,
  OrganizationCampaignsPage,
  CollectionsPage,
  CollectionPage,
  OrganizationBrandedAssetPage,
} from '../..';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import { UserRole } from '../../../global/consts';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { UserData } from '../../../global/interfaces';
import {
  OWNABLE_ASSETS,
  ORG_MANAGEMENT,
  SETTINGS,
  GAMES,
  CAMPAIGNS,
  COLLECTIONS,
  BRANDED_ASSETS,
} from '../../../global/routes';
import { useReneQuery } from '../../../hooks/useReneQuery';
import OrganizationDashboardPage from '../organization-dashboard-page/organization-dashboard-page';

import './organization-view-page.scss';

const OrganizationViewPage = () => {
  const navigate = useNavigate();
  const { data } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  useEffect(() => {
    if (data?.User.role && data?.User.role === UserRole.GAMER) {
      navigate(-1);
    }

    if (data?.User && !data.User.orgId) {
      navigate(`${ORG_MANAGEMENT}`);
    }
  }, [data, navigate]);

  if (!data) {
    return <LoadingFallback />;
  }

  return (
    <div className="organization-view-page">
      <Routes>
        <Route index element={<OrganizationDashboardPage />} />
        <Route path={`${GAMES}`} element={<OrganizationGamesPage />} />
        <Route path={`${CAMPAIGNS}`} element={<OrganizationCampaignsPage />} />
        <Route path={`${COLLECTIONS}/:type`} element={<CollectionsPage />} />
        <Route path={`${COLLECTIONS}/:type/:collectionId`} element={<CollectionPage />} />
        <Route
          path={`${COLLECTIONS}/ownable/:collectionId/:assetId/:menuItem`}
          element={<OrganizationOwnableAssetPage />}
        />
        <Route
          path={`${COLLECTIONS}/branded/:collectionId/:assetId/:menuItem`}
          element={<OrganizationBrandedAssetPage />}
        />
        <Route path={`${SETTINGS}/:menuItem/*`} element={<OrganizationSettingsPage />} />
        <Route path={`${CAMPAIGNS}/:adCampaignId/`} element={<OrganizationCampaignPage />} />
        <Route path={`${CAMPAIGNS}/:adCampaignId/:adId`} element={<OrganizationCampaignPage />} />
        <Route path={`${GAMES}/:gameId/:menuItem`} element={<OrganizationGamePage />} />
        <Route path={`${GAMES}/:gameId/${COLLECTIONS}/:collectionId/:type`} element={<CollectionPage />} />

        <Route
          path={`${GAMES}/:gameId/${COLLECTIONS}/:collectionId/${OWNABLE_ASSETS}/:assetId/:menuItem`}
          element={<OrganizationOwnableAssetPage />}
        />
        <Route
          path={`${GAMES}/:gameId/${COLLECTIONS}/:collectionId/${BRANDED_ASSETS}/:assetId/:menuItem`}
          element={<OrganizationBrandedAssetPage />}
        />
      </Routes>
    </div>
  );
};

export default OrganizationViewPage;
