import { commonValidations } from '../../../../../global/validations';

export const userValidations = {
  firstName: commonValidations.required(),
  lastName: commonValidations.required(),
  username: commonValidations.username(),
};

export const passwordValidations = {
  oldPassword: commonValidations.required(),
  newPassword: commonValidations.password(),
  confirmNewPassword: (form: { [k: string]: any }, key: string) => {
    if (!form[key]) return 'This field is required';
    if (form[key] !== form.newPassword) return "The passwords don't match";
  },
};
