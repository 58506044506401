import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProdEffect } from '../../../hooks/useProdEffect';
import { TRACK_AUTH_EVENT_MUTATIONS, UPSERT_GAME_MUTATION } from '../../../global/gql/mutations';
import { ChainsData, Event, GameData, UserData } from '../../../global/interfaces';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { GET_CHAINS, GET_ORG_GAMES_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { COLLECTIONS, GAMES, ORG } from '../../../global/routes';
import { EventTypes } from '../../../global/consts';
import { createChainOptions } from '../../../utils';
import { validations } from './validations';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import './new-game-modal.scss';

interface Props {
  setCloseModal: () => void;
}

const NewGameModal: React.FC<Props> = ({ setCloseModal }) => {
  const uploadFile = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{ name: string; description: string; blockchain: string; file?: File | undefined }>({
    name: '',
    description: '',
    blockchain: '',
    file: undefined,
  });
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const { data: chains } = useReneQuery<{ Chains: ChainsData }>(GET_CHAINS);

  const chainOptions = createChainOptions(chains?.Chains);

  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS, {
    onError() {},
  });

  const [addGame] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted(data: { UpsertGame: GameData }) {
      if (form?.file) {
        uploadFile(data.UpsertGame.image.uploadUrl, form.file).finally(() => {
          setLoading(false);
          setCloseModal();

          if (location.pathname === `/${ORG}`) {
            navigate(`${GAMES}/${data.UpsertGame.gameId}/${COLLECTIONS}`);
          }
        });
      }
    },
    refetchQueries: [GET_ORG_GAMES_QUERY],
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.GAME_CREATE_STARTED,
        },
      });
    }
  }, []);

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelect = (option: string) => {
    setForm((prev) => ({ ...prev, blockchain: option }));
  };

  const handleAddingNewGame = () => {
    if (isFormInvalid(form)) return;
    let variables: {
      name: string;
      description: string;
      image: { extension?: string };
      isActive: boolean;
      chainSymbol?: string;
      approvedUserScopes: string;
    } = {
      name: form.name.trim(),
      description: form.description.trim(),
      image: { extension: form?.file?.type.split('/')[1] },
      isActive: true,
      approvedUserScopes: 'user_all',
    };
    variables =
      form.blockchain === 'None'
        ? variables
        : { ...variables, chainSymbol: chainOptions && chainOptions[form.blockchain].chainSymbol };
    setLoading(true);
    addGame({ variables });
  };

  return (
    <div className="new-game-modal">
      <div className="new-game-modal__heading">
        <h2>New Game</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter game name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      <FileUpload
        setFiles={setFile}
        description="Upload Game Picture"
        errorMessage={errors?.file}
        handleChange={handleFileChange}
      />
      <div className="new-game-modal__blockchain">
        {chainOptions ? (
          <Select
            label="Blockchain"
            value={form.blockchain}
            options={chainOptions}
            showListValueFn={(item) => item.name}
            changeHandler={handleSelect}
            errorMsg={errors?.blockchain}
            showError
          />
        ) : (
          <LoadingFallback />
        )}
      </div>
      <div className="new-game-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewGame}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewGameModal;
