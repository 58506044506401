import { BalanceItemData, Dispatcher } from '../../../global/interfaces';
import { getTimeAgo } from '../../../utils';
import Icon from '../../Icon/Icon';
import './payment-history-modal.scss';

const Payment = ({ payment }: { payment: BalanceItemData }) => (
  <tr>
    <td className={`payment-history-modal__main_${payment.status.toLowerCase()}`}>{payment.status}</td>
    <td className="payment-history-modal__main_info">$ {payment.amount}</td>
    <td className="payment-history-modal__main_info">{getTimeAgo(payment.date)}</td>
  </tr>
);

const PaymentHistoryModal = ({
  transactions,
  setIsModalOpen,
}: {
  transactions: BalanceItemData[] | undefined;
  setIsModalOpen: Dispatcher<'top-up' | 'payment-history' | undefined>;
}) => {
  const handleModalClose = () => {
    setIsModalOpen(undefined);
  };

  return (
    <div className="payment-history-modal">
      <div className="payment-history-modal__heading">
        <h2>Payment history</h2>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      {transactions && (
        <div className="payment-history-modal__main">
          <table>
            <thead>
              <tr>
                <th>Status</th>
                <th>Amount</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((payment) => (
                <Payment key={payment.transactionId} payment={payment} />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PaymentHistoryModal;
