import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LOGIN } from '../../../global/routes';
import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import { INITIATE_PASSWORD_RESET_MUTATION, RESET_PASSWORD_MUTATION } from '../../../global/gql/mutations';
import { setPasswordValidations } from '../forgot-password-page/validations';
import { Dispatcher, Event } from '../../../global/interfaces';
import { handleError, handleKeyPressSubmit } from '../../../utils';
import { useValidation } from '../../../hooks';
import { useErrorContext } from '../../../context/error-context';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';
import OtpInput from '../../../components/otp-input/otp-input';

import successImg from '../../../global/images/success.webp';
import reneLogo from '../../../global/images/rene-logo.png';

import './create-password-page.scss';

interface CreatePasswordState {
  newPassword: string;
  confirmPassword: string;
  otp: string;
}

const createPasswordInitialState = {
  email: '',
  newPassword: '',
  confirmPassword: '',
  otp: '',
};

const CreatePassword = ({
  email,
  otpLoading,
  setStep,
  setError,
  initiatePasswordReset,
}: {
  email: string;
  otpLoading: boolean;
  setStep: Dispatcher<'password' | 'success'>;
  setError: Dispatcher<{ message: string; showError: boolean }>;
  initiatePasswordReset: MutationFunction<{ InitiatePasswordReset: boolean }, { email: string }>;
}) => {
  const navigate = useNavigate();
  const [wrongOtp, setWrongOtp] = useState('');
  const { errors, isFormInvalid } = useValidation(setPasswordValidations);
  const [credentials, setCredentials] = useState<CreatePasswordState>(createPasswordInitialState);

  const inputHandler = (e: Event['Input']) => {
    setCredentials((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onChange = (value: string) => {
    if (wrongOtp) setWrongOtp('');
    setCredentials((prev) => ({ ...prev, otp: value }));
  };

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: {
      email,
      newPassword: credentials.newPassword,
      otp: credentials.otp,
    },
    onCompleted(data: { ResetPassword: boolean }) {
      if (data?.ResetPassword) setStep('success');
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Otp is invalid / expired') {
        return setWrongOtp('Wrong OTP! Please try again');
      }
      handleError(err, setError);
    },
  });

  const handleSetPassword = () => {
    if (isFormInvalid(credentials)) return;
    resetPassword();
  };

  const handleGoBack = () => {
    navigate(`/${LOGIN}`, { state: { email } });
  };

  return (
    <div className="create-password">
      <h1>Create password</h1>
      <h2>Please enter OTP Code we’ve sent you via email</h2>
      <OtpInput value={credentials.otp} valueLength={6} onChange={onChange} submit={handleSetPassword} />
      {(wrongOtp || errors?.otp) && <h3>{wrongOtp || errors?.otp}</h3>}
      <p className="create-password__email">The email was sent to: {email}</p>
      <div className="create-password__resend-otp">
        <p>Did you not receive OTP code? </p>
        <button type="button" onClick={() => initiatePasswordReset()}>
          {otpLoading ? <Spinner size="sm" /> : 'Send again'}
        </button>
      </div>
      <p className="create-password__back">
        Change the email address?
        <button type="button" onClick={handleGoBack}>
          Go back
        </button>
      </p>
      <Input
        label="Create Password *"
        name="newPassword"
        type="password"
        value={credentials.newPassword}
        handleInput={inputHandler}
        onKeyDown={(e) => handleKeyPressSubmit(e, handleSetPassword)}
        errorMessage={errors?.newPassword}
      />
      <Input
        label="Confirm password *"
        name="confirmPassword"
        type="password"
        value={credentials.confirmPassword}
        handleInput={inputHandler}
        onKeyDown={(e) => handleKeyPressSubmit(e, handleSetPassword)}
        errorMessage={errors?.confirmPassword}
      />
      <button type="button" className="primary-btn medium-btn" onClick={handleSetPassword}>
        {loading ? <Spinner size="md" /> : 'Create password'}
      </button>
    </div>
  );
};

const SetPasswordSuccess = () => {
  return (
    <div className="set-password-success">
      <h1>Password created!</h1>
      <img src={successImg} alt="success" />
      <Link to={LOGIN} className="primary-btn small-btn">
        Back to Sign in page
      </Link>
    </div>
  );
};

const CreatePasswordPage = () => {
  const { state } = useLocation();
  const { email } = state || { email: '' };
  const navigate = useNavigate();
  const { setError } = useErrorContext();
  const [step, setStep] = useState<'password' | 'success'>('password');

  useEffect(() => {
    if (!email) {
      navigate(`/${LOGIN}`, { state: { email } });
    }
  }, [email, navigate]);

  const [initiatePasswordReset, { loading }] = useMutation(INITIATE_PASSWORD_RESET_MUTATION, {
    variables: {
      email,
    },
    onCompleted(data: { InitiatePasswordReset: boolean }) {
      if (data?.InitiatePasswordReset) {
        setStep('password');
      }
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Password reset failed, please check the params') {
        return handleError(err, setError, 'Email not registered');
      }
      handleError(err, setError);
    },
  });

  const renderStep = () => {
    switch (step) {
      case 'password':
        return (
          <CreatePassword
            email={email}
            setStep={setStep}
            setError={setError}
            initiatePasswordReset={initiatePasswordReset}
            otpLoading={loading}
          />
        );
      case 'success':
        return <SetPasswordSuccess />;
    }
  };

  return (
    <div className="create-password-page">
      <div className="create-password-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      {renderStep()}
    </div>
  );
};

export default CreatePasswordPage;
