import { useState } from 'react';
import { AttributesData, Dispatcher } from '../../../global/interfaces';
import { useValidation } from '../../../hooks';
import { validations } from './validations';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import './new-attribute-modal.scss';
import { AttributeTypes } from '../../../global/consts';

interface Props {
  attributeList: AttributesData[];
  setCloseModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  addAttribute: Dispatcher<AttributesData[] | []>;
}

const NewAttributeModal: React.FC<Props> = ({ attributeList, setCloseModal, addAttribute }) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const [name, setName] = useState<string>('');
  const [alreadyExists, setAlreadyExists] = useState<string>('');
  const [attributeType, setAttributeType] = useState<'string' | 'number' | 'boost_percentage' | 'date'>('string');

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };

  const handleAddAttribute = () => {
    const doesExist = attributeList.find((attr) => attr.traitType === name);
    if (doesExist) {
      return setAlreadyExists('This attribute name already exists');
    }
    if (isFormInvalid({ name: name.trim() })) return;
    addAttribute((prev) => [
      ...prev,
      { __typename: 'OwnableAssetAttribute', traitType: name.trim(), displayType: attributeType, values: [] },
    ]);
    handleModal();
  };

  return (
    <div className="new-attribute-modal">
      <div className="new-attribute-modal__heading">
        <h2>New Attribute</h2>
        <button type="button" onClick={handleModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div>
        <Input
          label="Name"
          name="name"
          value={name}
          handleInput={(e) => {
            if (alreadyExists) setAlreadyExists('');
            setName(e.target.value);
          }}
          errorMessage={errors?.name || alreadyExists}
        />
        <Select
          label="Attribute type"
          value={AttributeTypes[attributeType]}
          options={AttributeTypes}
          changeHandler={setAttributeType}
        />
        <div className="new-attribute-modal__actions">
          <button type="button" className="secondary-btn" onClick={handleModal}>
            Cancel
          </button>
          <button className="primary-btn" type="button" onClick={handleAddAttribute}>
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAttributeModal;
