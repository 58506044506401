import { UserData } from '../../../global/interfaces';
import avatar from '../../../global/images/avatar.webp';
// import Statistics from '../../statistics/statistics';
import './gamer-banner.scss';

interface Props {
  user: UserData | undefined;
  points: string | undefined;
}

const GamerBanner: React.FC<Props> = ({ user, points = '0' }) => {
  return (
    <div className="gamer_banner">
      <div className="gamer_banner__image">
        <img src={user?.image?.url || avatar} alt="user" />
      </div>
      <div className="gamer_banner__title">
        <h1>{`${user?.data.firstName || ''} ${user?.data.lastName || ''}`}</h1>
        <h2>{user?.email}</h2>
      </div>
      {/* {user?.stats && (
        <Statistics className="gamer_banner__data" data={{ assets: user.stats.assets, games: user.stats.games }} />
      )} */}
    </div>
  );
};

export default GamerBanner;
