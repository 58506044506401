import { commonValidations } from '../../../../global/validations';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  recipient: (form: { [key: string]: any }, key: any) => {
    if (!form[key] && !form.randomRecipient) {
      return 'This field is required';
    }
  },
  attributes: (form: { [key: string]: any }, key: any) => {
    if (!form?.[key].length) return 'You must add at least one attribute to be able to mint';

    const attrErrors: string[] = form?.[key].map((attr: any, index: number) => {
      const { randomize, displayType, values } = attr;
      if (randomize) return false;
      const [from, to, value] = values;

      const attrValidations: any = {
        value: {
          number: () => {
            if (!value) return 'This field is required';
            if (+value < +from || +value > +to) return 'Please provide valid values';
          },
          date: () => {
            if (!value) return 'This field is required';
            if (value < from || value > to) return 'Please provide valid values';
          },
          string: () => {
            if (!attr.selectedValue) return 'This field is required';
          },
          boost_percentage: () => {},
        },
      };

      return attrValidations.value[displayType]();
    });

    return attrErrors.some((err) => err) ? attrErrors : undefined;
  },
};
