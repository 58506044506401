import { MenuItems } from '../../global/interfaces';
import Icon, { IconName } from '../Icon/Icon';
import Select from '../select/select';
import './aside-menu.scss';

interface Props {
  items: MenuItems;
  selectedItem: string;
  handleItemSelect: (value: string) => void;
}
const AsideMenu: React.FC<Props> = ({ items, selectedItem, handleItemSelect }) => {
  return (
    <aside className="aside-menu">
      <div className="aside-menu__desktop">
        {Object.keys(items).map((key: string) => (
          <button
            key={items[key].name}
            className={items[selectedItem]?.name === items[key].name ? 'active' : ''}
            onClick={() => handleItemSelect(key)}
          >
            <Icon name={items[key].icon as IconName} />
            <p>{items[key].label}</p>
          </button>
        ))}
      </div>
      <Select
        className="aside-menu__mobile"
        value={items[selectedItem]?.name}
        options={items}
        changeHandler={(index) => {
          handleItemSelect(index);
        }}
        showSelectedValueFn={(option) => option.label}
        showListValueFn={(option) => (
          <div className="aside-menu__mobile_dropdown">
            <Icon name={option.icon} />
            <p>{option.label}</p>
          </div>
        )}
      >
        <Icon name={items[selectedItem]?.icon as IconName} />
      </Select>
    </aside>
  );
};

export default AsideMenu;
