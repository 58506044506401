import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../global/consts';
import { UserData } from '../../global/interfaces';
import { GET_USER_ROLE_QUERY } from '../../global/gql/queries';
import { FIRST_TIME_USER, GAMES, ORG, VERIFY } from '../../global/routes';
import { useReneQuery } from '../../hooks/useReneQuery';
import LoadingFallback from '../loading-fallback/loading-fallback';

const Portal = () => {
  const navigate = useNavigate();
  const { data } = useReneQuery<{ User: UserData }>(GET_USER_ROLE_QUERY, {
    fetchPolicy: 'network-only',
  });

  useLayoutEffect(() => {
    if (data?.User) {
      if (!data.User?.verified?.isEmail) return navigate(`/${VERIFY}`);
      if (data.User?.isFirstTimeUser) return navigate(`/${FIRST_TIME_USER}`);
      if (data.User?.role === UserRole.GAMER) {
        navigate(GAMES);
      } else {
        navigate(ORG);
      }
    }
  }, [data, navigate]);

  return <LoadingFallback />;
};

export default Portal;
