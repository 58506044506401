import { Route, Routes } from 'react-router-dom';
import { ASSETS, CATALOG, MARKETPLACE } from '../../../global/routes';
import AssetsMarketplacePage from '../assets-marketplace-page/assets-marketplace-page';
import GamerAssetsPage from '../gamer-assets-page/gamer-assets-page';
import GamerDashboardPage from '../gamer-dashboard-page/gamer-dashboard-page';
import GamesCatalogPage from '../games-catalog-page/games-catalog-page';
import './gamer-view-page.scss';

const GamerViewPage = () => {
  return (
    <div className="gamer-view-page">
      <Routes>
        <Route index element={<GamerDashboardPage />} />
        <Route path={`:gameId/${ASSETS}`} element={<GamerAssetsPage />} />
        <Route path={MARKETPLACE} element={<AssetsMarketplacePage />} />
        <Route path={CATALOG} element={<GamesCatalogPage />} />
      </Routes>
    </div>
  );
};

export default GamerViewPage;
