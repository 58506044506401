import { UserData } from '../../../../global/interfaces';
import { CSV_VALIDATION_REGEX } from '../../../../global/consts';

export const validations = {
  recipient: (form: { [key: string]: any }, key: string) => {
    if (!form[key] && !form.gameRecipient) {
      return 'This field is required';
    }
  },
  file: (
    form: {
      recipient: UserData;
      file: File;
    },
    key: 'file',
  ) => {
    if (!form?.[key]) return 'This field is required';
    if (!form?.[key]?.type.match(CSV_VALIDATION_REGEX)) return 'You must provide a CSV file';
  },
};
