import { useState } from 'react';
import { validations } from './validations';
import { TRACK_AUTH_EVENT_MUTATIONS, UPSERT_OWNABLE_ASSET_MUTATION } from '../../../global/gql/mutations';
import { OwnableAssetData, CollectionsData, OrganizationData, Refetch, UserData } from '../../../global/interfaces';
import { EventTypes } from '../../../global/consts';
import { useProdEffect } from '../../../hooks/useProdEffect';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { GET_ORG_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import { RadioButton } from '../../radio/radio-button';

import './new-ownable-asset-modal.scss';

interface Props {
  collectionId: string | undefined;
  refetch: Refetch<{
    Collections: CollectionsData;
  }>;
  refetchGame?: Refetch<
    | {
        Collections: CollectionsData;
      }
    | undefined
  >;
  setCloseModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

interface Variables {
  name: string;
  collectionId?: string;
  data?: {
    description?: string;
    supply?: string | boolean;
  };
  image?: {
    extension?: string;
  };
  isIpfs: boolean;
}

const NewOwnableAssetModal: React.FC<Props> = ({ collectionId, refetch, refetchGame, setCloseModal }) => {
  const uploadImage = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [storageType, setStorageType] = useState<string>('Reneverse');
  const [finite, setFinite] = useState<string | undefined>(undefined);
  const [isFinite, setIsFinite] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const { data: org } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS);

  const maxMint = org && org?.Organization.mintLimit - org?.Organization.mintCount;

  const [upsertOwnableAsset] = useReneMutation(UPSERT_OWNABLE_ASSET_MUTATION, {
    onCompleted(data: { UpsertOwnableAsset: OwnableAssetData }) {
      if (file) {
        uploadImage(data.UpsertOwnableAsset.image.uploadUrl, file)
          .then(() => {
            refetch();
            if (refetchGame) {
              refetchGame();
            }
          })
          .finally(() => {
            handleModal();
            setLoading(false);
          });
      }
    },
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.OWNABLE_ASSET_STARTED,
        },
      });
    }
  }, []);

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };

  const handleOwnableAssetCreate = () => {
    if (
      isFormInvalid({
        file,
        name,
        finite,
        isFinite,
        maxMint,
      })
    )
      return;

    setLoading(true);

    const variables: Variables = {
      name: name.trim(),
      collectionId,
      data: {
        description: description.trim() || undefined,
        supply: finite || undefined,
      },
      image: {
        extension: file?.type.split('/')[1],
      },
      isIpfs: storageType === 'IPFS',
    };

    upsertOwnableAsset({ variables });
  };

  return (
    <div className="new-ownable-asset_modal">
      <div className="new-ownable-asset_modal__heading">
        <h2>New Ownable Asset</h2>
        <button type="button" onClick={handleModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <form className="new_ownable-asset_modal__main">
        <Input
          name="name"
          label="Name"
          placeholder="Enter ownable asset name"
          handleInput={(e) => setName(e.target.value)}
          value={name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          value={description}
          handleInput={(e) => setDescription(e.target.value)}
          placeholder="Enter ownable asset description"
          showCounter
          maxLength={100}
        />
        <FileUpload
          setFiles={(files: File[]) => setFile(files?.[0])}
          description="Upload ownable asset picture"
          errorMessage={errors?.file}
        />
        <div className="new-ownable-asset_modal__main_supply">
          <label>Supply</label>
          <div>
            <div className="supply-infinite">
              <RadioButton
                id="infinite-radio-btn"
                value="infinite"
                name="finite"
                label="Infinite"
                setValue={() => {
                  setIsFinite(false);
                  setFinite(undefined);
                }}
                isSelected={!isFinite}
              />
            </div>
            <div className="supply-finite">
              <RadioButton
                id="finite-radio-btn"
                value="finite"
                name="finite"
                label="Finite"
                setValue={() => {
                  setIsFinite(true);
                  setFinite(undefined);
                }}
                isSelected={isFinite}
              />
              <Input
                type="text"
                value={finite}
                handleInput={(e) => {
                  setIsFinite(true);
                  setFinite(e.target.value.replace(/[^0-9]/g, ''));
                }}
                placeholder={isFinite ? `max ${maxMint}` : ''}
                disabled={!isFinite}
                errorMessage={errors?.finite}
              />
            </div>
          </div>
        </div>
        <div className="new-ownable-asset_modal__main_storage">
          <Select
            label="Storage"
            value={storageType}
            options={{ Reneverse: 'Reneverse', IPFS: 'IPFS' }}
            changeHandler={(index) => {
              setStorageType(index);
            }}
          />
        </div>
        <div className="new-ownable-asset_modal__main_actions">
          <button type="button" className="secondary-btn" disabled={loading} onClick={handleModal}>
            Cancel
          </button>
          <button type="button" className="primary-btn" disabled={loading} onClick={handleOwnableAssetCreate}>
            {loading ? <Spinner size="sm" /> : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewOwnableAssetModal;
