import { useState } from 'react';
import { AdResolutionIab, AdSurfaceInteractivity, AdType } from '../../../../global/consts';
import { SearchContentValues } from '../../../search-content-values/search-content-values';
import { CONTENT_SEARCH } from '../../../../global/gql/queries';
import { Content, Event } from '../../../../global/interfaces';
import FileUpload from '../../../file-upload/file-upload';
import Select from '../../../select/select';
import Input from '../../../input/input';
import { useValidation } from '../../../../hooks';
import { validations } from '../validations';

const AdSurfaceFields = ({
  surface,
  updateSurface,
  allowCreateSurface = true,
  handleFileChange,
}: {
  surface: {
    adType: string;
    placeholder?: { url: string };
    resolutionIab: AdResolutionIab;
    interactivity: AdSurfaceInteractivity;
    contentTags: { name: string; taxonomyContentId: string }[];
    maxWidth: string;
    maxHeight: string;
    file?: File | undefined;
  };
  updateSurface: any;
  allowCreateSurface?: boolean;
  handleFileChange?: (e: Event['Input']) => void;
}) => {
  const { errors, isFormInvalid, isFieldInvalid } = useValidation(validations);

  const [editPlaceholder, setEditPlaceholder] = useState(false);

  const showImageVideo = () => {
    if (!surface.placeholder) return null;
    const url = surface.file ? URL.createObjectURL(surface.file) : surface.placeholder.url;
    return (
      <div className="ad-surface-item__body_placeholder">
        <label>Placeholder file</label>
        {surface.adType === AdType.BANNER ? (
          <img src={url} alt="placeholder file" />
        ) : (
          <video autoPlay muted>
            <source src={url} />
          </video>
        )}
        <p>{surface.placeholder.url}</p>
        <button className="primary-btn" onClick={() => setEditPlaceholder(true)}>
          Change
        </button>
      </div>
    );
  };

  const addTargetValue = (value: Content) => {
    const newValue = { name: value.name, taxonomyContentId: value.taxonomyContentId };
    const contentTags = surface.contentTags ? [...surface.contentTags, newValue] : [newValue];
    updateSurface({ ...surface, contentTags });
  };

  const deleteTargetValue = (id: string) => {
    const contentTags = [...surface.contentTags].filter((taxonomy) => taxonomy.taxonomyContentId !== id);
    updateSurface({ ...surface, contentTags });
  };

  return (
    <>
      <Select
        label="Resolution"
        value={AdResolutionIab[surface.resolutionIab]}
        options={AdResolutionIab}
        showError
        errorMsg={errors?.resolutionIab}
        changeHandler={(index) => {
          updateSurface({ ...surface, resolutionIab: index });
        }}
        disabled={!allowCreateSurface}
      />
      <div className="ad-surface-item__body_maxMin">
        <Input
          label="Max width"
          type="text"
          handleInput={(e: Event['Input']) => {
            isFormInvalid({ ...surface, maxWidth: e.target.value });
            updateSurface({ ...surface, maxWidth: e.target.value });
          }}
          value={surface.maxWidth || ''}
          errorMessage={errors?.maxWidth}
        />
        <Input
          label="Max height"
          type="text"
          handleInput={(e: Event['Input']) => {
            isFormInvalid({ ...surface, maxHeight: e.target.value });
            updateSurface({ ...surface, maxHeight: e.target.value });
          }}
          value={surface.maxHeight || ''}
          errorMessage={errors?.maxHeight}
        />
      </div>
      <Select
        label="Interactivity"
        value={AdSurfaceInteractivity[surface?.interactivity]}
        options={AdSurfaceInteractivity}
        showError
        errorMsg={errors?.interactivity}
        changeHandler={(index) => {
          updateSurface({ ...surface, interactivity: index });
        }}
        disabled={!allowCreateSurface}
      />
      {!surface.placeholder?.url || editPlaceholder ? (
        <FileUpload
          label="Placeholder File"
          setFiles={(files: File[]) => {
            if (isFieldInvalid('file', { file: files[0], adType: surface.adType })) return;
            updateSurface({ ...surface, file: files[0] });
          }}
          description="Upload Placeholder File"
          errorMessage={errors?.file}
          handleChange={handleFileChange}
        />
      ) : (
        showImageVideo()
      )}
      <SearchContentValues
        query={CONTENT_SEARCH}
        label="Ad Surface Content Tags"
        values={surface.contentTags}
        changeHandler={addTargetValue}
        deleteValueHandler={deleteTargetValue}
        errorMessage={errors?.targetedGenres}
        disabled={!allowCreateSurface}
      />
    </>
  );
};

export default AdSurfaceFields;
