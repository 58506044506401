import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LOGIN } from '../../../global/routes';
import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import { INITIATE_PASSWORD_RESET_MUTATION, RESET_PASSWORD_MUTATION } from '../../../global/gql/mutations';
import { initiateValidations, setPasswordValidations } from './validations';
import { Dispatcher, Event } from '../../../global/interfaces';
import { handleError, handleKeyPressSubmit } from '../../../utils';
import { useValidation } from '../../../hooks';
import { useErrorContext } from '../../../context/error-context';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';
import OtpInput from '../../../components/otp-input/otp-input';

import successImg from '../../../global/images/success.webp';
import reneLogo from '../../../global/images/rene-logo.png';

import './forgot-password-page.scss';

interface ForgotPasswordState {
  newPassword: string;
  confirmPassword: string;
  otp: string;
}

const forgotPasswordInitialState = {
  email: '',
  newPassword: '',
  confirmPassword: '',
  otp: '',
};

const NewPassword = ({
  email,
  otpLoading,
  setStep,
  setError,
  initiatePasswordReset,
}: {
  email: string;
  otpLoading: boolean;
  setStep: Dispatcher<'initiate' | 'password' | 'success'>;
  setError: Dispatcher<{ message: string; showError: boolean }>;
  initiatePasswordReset: MutationFunction<{ InitiatePasswordReset: boolean }, { email: string }>;
}) => {
  const [wrongOtp, setWrongOtp] = useState('');
  const { errors, isFormInvalid } = useValidation(setPasswordValidations);
  const [credentials, setCredentials] = useState<ForgotPasswordState>(forgotPasswordInitialState);

  const inputHandler = (e: Event['Input']) => {
    setCredentials((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const onChange = (value: string) => {
    if (wrongOtp) setWrongOtp('');
    setCredentials((prev) => ({ ...prev, otp: value }));
  };

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION, {
    variables: {
      email,
      newPassword: credentials.newPassword,
      otp: credentials.otp,
    },
    onCompleted(data: { ResetPassword: boolean }) {
      if (data?.ResetPassword) setStep('success');
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Otp is invalid / expired') {
        return setWrongOtp('Wrong OTP! Please try again');
      }
      handleError(err, setError);
    },
  });

  const handleSetPassword = () => {
    if (isFormInvalid(credentials)) return;
    resetPassword();
  };

  const handleGoBack = () => {
    setStep('initiate');
  };

  return (
    <div className="new-password">
      <h1>Reset password</h1>
      <h2>Please enter OTP Code we’ve sent you via email</h2>
      <OtpInput value={credentials.otp} valueLength={6} onChange={onChange} submit={handleSetPassword} />
      {(wrongOtp || errors?.otp) && <h3>{wrongOtp || errors?.otp}</h3>}
      <p className="new-password__email">The email was sent to: {email}</p>
      <div className="new-password__resend-otp">
        <p>Did you not receive OTP code? </p>
        <button type="button" onClick={() => initiatePasswordReset()}>
          {otpLoading ? <Spinner size="sm" /> : 'Send again'}
        </button>
      </div>
      <p className="new-password__back">
        Change the email address?
        <button type="button" onClick={handleGoBack}>
          Go back
        </button>
      </p>
      <Input
        label="New Password *"
        name="newPassword"
        type="password"
        value={credentials.newPassword}
        handleInput={inputHandler}
        onKeyDown={(e) => handleKeyPressSubmit(e, handleSetPassword)}
        errorMessage={errors?.newPassword}
      />
      <Input
        label="Confirm password *"
        name="confirmPassword"
        type="password"
        value={credentials.confirmPassword}
        handleInput={inputHandler}
        onKeyDown={(e) => handleKeyPressSubmit(e, handleSetPassword)}
        errorMessage={errors?.confirmPassword}
      />
      <button type="button" className="primary-btn medium-btn" onClick={handleSetPassword}>
        {loading ? <Spinner size="md" /> : 'Reset password'}
      </button>
    </div>
  );
};

const EnterEmail = ({
  email,
  setEmail,
  loading,
  initiatePasswordReset,
}: {
  email: string;
  loading: boolean;
  setEmail: Dispatcher<string>;
  initiatePasswordReset: MutationFunction<{ InitiatePasswordReset: boolean }, { email: string }>;
}) => {
  const { errors, isFormInvalid } = useValidation(initiateValidations);

  const handleInitiatePasswordReset = () => {
    if (isFormInvalid({ email })) return;
    initiatePasswordReset();
  };

  return (
    <div className="enter-email">
      <h1>Forgot password</h1>
      <h2>
        Change your mind?<Link to={`/${LOGIN}`}>Log in</Link>
      </h2>
      <Input
        label="Email"
        placeholder="Enter your email"
        value={email}
        handleInput={(e) => setEmail(e.target.value)}
        onKeyDown={(e) => handleKeyPressSubmit(e, handleInitiatePasswordReset)}
        errorMessage={errors?.email}
      />
      <button type="button" className="primary-btn small-btn" onClick={handleInitiatePasswordReset}>
        {loading ? <Spinner size="sm" /> : 'Reset password'}
      </button>
    </div>
  );
};

const SetPasswordSuccess = () => {
  return (
    <div className="set-password-success">
      <h1>Password changed!</h1>
      <img src={successImg} alt="success" />
      <Link to={LOGIN} className="primary-btn small-btn">
        Back to Sign in page
      </Link>
    </div>
  );
};
const ForgotPasswordPage = () => {
  const { setError } = useErrorContext();
  const [email, setEmail] = useState('');
  const [step, setStep] = useState<'initiate' | 'password' | 'success'>('initiate');

  const [initiatePasswordReset, { loading }] = useMutation(INITIATE_PASSWORD_RESET_MUTATION, {
    variables: {
      email,
    },
    onCompleted(data: { InitiatePasswordReset: boolean }) {
      if (data?.InitiatePasswordReset) {
        setStep('password');
      }
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Password reset failed, please check the params') {
        return handleError(err, setError, 'Email not registered');
      }
      handleError(err, setError);
    },
  });

  const renderStep = () => {
    switch (step) {
      case 'initiate':
        return (
          <EnterEmail
            email={email}
            setEmail={setEmail}
            initiatePasswordReset={initiatePasswordReset}
            loading={loading}
          />
        );
      case 'password':
        return (
          <NewPassword
            email={email}
            setStep={setStep}
            setError={setError}
            initiatePasswordReset={initiatePasswordReset}
            otpLoading={loading}
          />
        );
      case 'success':
        return <SetPasswordSuccess />;
    }
  };

  return (
    <div className="forgot-password-page">
      <div className="forgot-password-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      {renderStep()}
    </div>
  );
};

export default ForgotPasswordPage;
