/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { BrandedAssetData, CollectionsData } from '../../../../global/interfaces';
import { AssetType } from '../../../../global/consts';
import { useReneQuery } from '../../../../hooks';
import { createCollectionsQuery } from '../../../../global/gql/queries';
// import collPlaceholder from '../../../global/images/collection-placeholder.png';
import OrgAssetCard from '../../../../components/asset/org-asset-card/org-asset-card';
import Icon from '../../../../components/Icon/Icon';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import './branded-asset-catalog.scss';

const BrandedAssetCatalog = ({
  addedBrandedAssets,
  addBrandedAsset,
  setCloseModal,
}: {
  addedBrandedAssets: BrandedAssetData[] | [];
  addBrandedAsset: (brandedAsset: BrandedAssetData) => void;
  setCloseModal: () => void;
}) => {
  const [brandedAssetList, setBrandedAssetList] = useState<BrandedAssetData[] | []>([]);

  const { data, loading } = useReneQuery<{ Collections: CollectionsData }>(createCollectionsQuery(AssetType.BRANDED), {
    variables: { collectionId: '32f0aa31-11c9-444f-a1b0-27c7cb55d6ca' },
  });

  useEffect(() => {
    if (data) {
      if (addedBrandedAssets) {
        const newList = data.Collections.items[0].brandedAssets.items.filter(
          (asset) => !addedBrandedAssets.some((el: BrandedAssetData) => asset.brandedAssetId === el.brandedAssetId),
        );
        setBrandedAssetList(newList);
      } else {
        setBrandedAssetList(data.Collections.items[0].brandedAssets.items);
      }
    }
  }, [addedBrandedAssets, data]);

  return (
    <div className="branded-asset-catalog">
      <div className="branded-asset-catalog__heading">
        <h2>Branded Assets</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="branded-asset-catalog__main">
        {!loading ? (
          brandedAssetList.map((asset: BrandedAssetData) => (
            <OrgAssetCard key={asset.brandedAssetId} title={asset.name} image={asset.image?.url}>
              <div className="branded-asset-catalog__main_asset-actions">
                {/* <button className="secondary-btn">Preview</button> */}
                <button className="primary-btn" onClick={() => addBrandedAsset(asset)}>
                  Add
                </button>
              </div>
            </OrgAssetCard>
          ))
        ) : (
          <LoadingFallback />
        )}
      </div>
    </div>
  );
};

export default BrandedAssetCatalog;
