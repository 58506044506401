import { useState } from 'react';
import { Brand, CampaignData, Event, OrganizationData } from '../../../global/interfaces';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isPast } from 'date-fns';
import { GET_AD_CAMPAIGNS_QUERY, GET_ORG_BRANDS } from '../../../global/gql/queries';
import { BRANDS, CAMPAIGNS, ORG, SETTINGS } from '../../../global/routes';
import { countries, regions } from './countries';
import { validations } from './validations';
import { formatDate } from '../../../utils';
import { SearchValues } from '../../search-values/search-values';
import { AdType, Age, CampaignGoal, Gender } from '../../../global/consts';
import { useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';

import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import Checkbox from '../../checkbox/checkbox';
import Textarea from '../../textarea/textarea';
import FileUpload from '../../file-upload/file-upload';
import EditableImage from '../../editable-image/editable-image';

import placeholder from '../../../global/images/avatar.webp';

import './campaign-modal.scss';

interface Variables {
  adCampaignId?: string;
  name: string;
  description: string;
  file?: File | undefined;
  age: Age[] | [];
  gender: Gender[] | [];
  campaignGoal: string;
  brandId?: string;
  startDate: string;
  endDate: string;
  targetedLocations?: string[] | [];
  targetedRegion?: string;
  targetedGenres: string[] | [];
  updatedAt?: string;
  campaignImage?: {
    extension: string;
    fileId?: string;
  };
}

const selectOptions = {
  campaignGoal: {
    // [CampaignGoal.PRODUCT_PLACEMENT]: 'Product placement',
    [CampaignGoal.BRAND_AWARENESS]: 'Brand Awareness',
  },
  targetCountries: countries,
  targetedRegion: regions,
  adType: {
    [AdType.BANNER]: 'Banner',
    [AdType.VIDEO]: 'Video',
  },
};

const CampaignModal = ({
  isEdit = false,
  previousCampaign,
  setCloseModal,
}: {
  isEdit?: boolean;
  previousCampaign?: CampaignData;
  setCloseModal: () => void;
}) => {
  const uploadFile = useFileUpload();
  const location = useLocation();
  const navigate = useNavigate();
  const { errors, isFieldInvalid, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [previousData, setPreviousData] = useState<CampaignData>();

  const [form, setForm] = useState<{
    name: string;
    description: string;
    file?: File | undefined;
    age: Age[] | [];
    gender: Gender[] | [];
    campaignGoal: string;
    campaignImage: string;
    startDate: string;
    endDate: string;
    targetedLocations?: string[] | [];
    targetedRegion: string;
    targetedGenres: string[] | [];
    brand: string;
  }>({
    name: '',
    description: '',
    age: [],
    gender: [],
    file: undefined,
    campaignGoal: '',
    startDate: '',
    endDate: '',
    targetedLocations: ['all'],
    targetedGenres: [],
    targetedRegion: '',
    campaignImage: '',
    brand: '',
  });

  const { data: orgData } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_BRANDS);

  if (orgData && previousCampaign && previousCampaign !== previousData) {
    const brand = orgData.Organization?.brands?.items
      .findIndex((item) => item.brandId === previousCampaign.brand?.brandId)
      .toString();
    setPreviousData(previousCampaign);
    setForm({
      name: previousCampaign.name,
      description: previousCampaign.description,
      startDate: formatDate(previousCampaign.startDate),
      endDate: formatDate(previousCampaign.endDate),
      campaignGoal: previousCampaign.campaignGoal,
      age: previousCampaign.age,
      gender: previousCampaign.gender,
      file: undefined,
      targetedRegion: previousCampaign.targetedRegion,
      targetedGenres: previousCampaign.targetedGenres,
      campaignImage: previousCampaign?.campaignImage?.url,
      brand,
    });
  }

  const [upsertCampaign] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      if (form?.file) {
        uploadFile(data.UpsertAdCampaign.campaignImage.uploadUrl, form.file).finally(() => {
          handleFinishUpsert(data.UpsertAdCampaign.adCampaignId);
        });
      } else {
        handleFinishUpsert(data.UpsertAdCampaign.adCampaignId);
      }
    },
    refetchQueries: [GET_AD_CAMPAIGNS_QUERY],
  });

  const handleFinishUpsert = (campaignId: string) => {
    setLoading(false);
    setCloseModal();

    if (location.pathname === `/${ORG}`) {
      navigate(`${CAMPAIGNS}/${campaignId}`);
    }
  };

  const setFile = (files: File[]) => {
    setForm((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleFormChange = (e: Event['Input'] | Event['TextArea']) => {
    if (isEdit && e.target.name === 'startDate') {
      if (isFieldInvalid('editStartDate', e.target.value)) return;
    }
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (e: Event['Input']) => {
    const file = e.target.files && e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const selectChangeHandler = (field: string, value: string) => {
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleCheckbox = (field: 'age' | 'gender', value: Age | Gender) => {
    if (form[field].some((key) => key === value)) {
      return setForm((prev) => ({
        ...prev,
        [field]: [...(form[field] as Array<Age | Gender>).filter((key) => key !== value)],
      }));
    }
    setForm((prev) => ({ ...prev, [field]: [...form[field], value] }));
  };

  const addTargetValue = (value: string) => {
    setForm((prev) => ({ ...prev, targetedGenres: [...form.targetedGenres, value] }));
  };

  const addLocationsValue = (value: string) => {
    setForm((prev) => ({ ...prev, targetedRegion: value }));
  };

  const deleteTargetValue = (value: string) => {
    setForm((prev) => ({
      ...prev,
      targetedGenres: [...form.targetedGenres.filter((attrVal) => attrVal !== value)],
    }));
  };

  const handleAddingNewCampaign = () => {
    if (isFormInvalid({ ...form, isEdit })) return;
    let variables: Variables = {
      name: form.name.trim(),
      description: form.description.trim(),
      age: form.age,
      gender: form.gender,
      campaignGoal: form.campaignGoal,
      startDate: form.startDate,
      endDate: form.endDate,
      targetedGenres: form.targetedGenres,
      updatedAt: previousCampaign?.updatedAt,
    };
    variables = !isEdit && form.targetedRegion ? { ...variables, targetedRegion: form.targetedRegion } : variables;
    variables = previousCampaign?.adCampaignId
      ? { ...variables, adCampaignId: previousCampaign.adCampaignId }
      : variables;
    variables = form.file ? { ...variables, campaignImage: { extension: form.file?.type.split('/')[1] } } : variables;
    variables =
      form.file && previousCampaign?.campaignImage.fileId
        ? {
            ...variables,
            campaignImage: { extension: form.file?.type.split('/')[1], fileId: previousCampaign.campaignImage.fileId },
          }
        : variables;

    variables =
      form.campaignGoal === CampaignGoal.BRAND_AWARENESS && !isEdit
        ? { ...variables, brandId: orgData?.Organization.brands.items[parseInt(form.brand)].brandId }
        : variables;

    setLoading(true);
    upsertCampaign({ variables });
  };
  const brandedItems = () => {
    return orgData?.Organization.brands.items.length ? (
      <div className="campaign-modal__brand">
        <Select
          label="Brand"
          className="campaign-modal__brand_select"
          value={form.brand}
          placeholder="Select brand"
          options={{ ...orgData?.Organization.brands.items }}
          changeHandler={(index) => {
            setForm((prev) => ({ ...prev, brand: index }));
          }}
          showSelectedValueFn={(val) => val?.name}
          showListValueFn={(val: Brand) => (
            <div key={val.brandId} className="campaign-modal__brand_selected">
              <img src={val?.image.url || placeholder} alt="brand" />
              <p>{val.name}</p>
            </div>
          )}
          disabled={isEdit}
        />
        <p className="error-msg">{errors?.brand}</p>
      </div>
    ) : (
      <p className="new-collection-modal__type_brand_no-brand">
        Please <Link to={`/${ORG}/${SETTINGS}/${BRANDS}`}>create</Link> a brand.
      </p>
    );
  };

  return (
    <div className="campaign-modal">
      <div className="campaign-modal__heading">
        <h2>{isEdit ? 'Edit' : 'New'} Campaign</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        placeholder="Enter Campaign name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Textarea
        label="Description"
        name="description"
        value={form.description}
        handleInput={handleFormChange}
        placeholder="Enter Campaign description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />
      {previousData ? (
        <EditableImage
          imageUrl={form.campaignImage}
          alt="banner image"
          setFile={(file: File) => {
            setForm((prev) => ({ ...prev, file }));
          }}
        />
      ) : (
        <FileUpload
          setFiles={setFile}
          description="Upload Campaign Picture"
          errorMessage={errors?.file}
          handleChange={handleFileChange}
        />
      )}
      <Select
        label="Campaign Goal"
        value={form.campaignGoal}
        placeholder="Select campaign goal"
        options={selectOptions.campaignGoal}
        changeHandler={(value) => selectChangeHandler('campaignGoal', value)}
        showSelectedValueFn={(value) => value}
        errorMsg={errors?.campaignGoal}
        showError
        disabled={isEdit && !!form.campaignGoal}
      />
      {form.campaignGoal === CampaignGoal.BRAND_AWARENESS && brandedItems()}
      <div className="campaign-modal__date">
        <Input
          label="Start Date"
          type="date"
          name="startDate"
          value={form.startDate}
          handleInput={handleFormChange}
          errorMessage={errors?.startDate || errors?.editStartDate}
          disabled={isEdit && isPast(new Date(form.startDate))}
        />
        <Input
          label="End Date"
          type="date"
          name="endDate"
          value={form.endDate}
          handleInput={handleFormChange}
          errorMessage={errors?.endDate}
        />
      </div>
      <Select
        label="Target Regions"
        value={form.targetedRegion}
        placeholder="Select region"
        options={selectOptions.targetedRegion}
        changeHandler={(value) => addLocationsValue(value)}
        showSelectedValueFn={(value) => value}
        errorMsg={errors?.targetedRegion}
        disabled={isEdit}
        showError
      />
      <div className="campaign-modal__demographics">
        <label>Demographics</label>
        <div className="campaign-modal__demographics_gender">
          <label>Gender</label>
          <div>
            <Checkbox
              value={form.gender.some((key) => key === Gender.MALE)}
              setValue={() => handleCheckbox('gender', Gender.MALE)}
            >
              Male
            </Checkbox>
            <Checkbox
              value={form.gender.some((key) => key === Gender.FEMALE)}
              setValue={() => handleCheckbox('gender', Gender.FEMALE)}
            >
              Female
            </Checkbox>
            <Checkbox
              value={form.gender.some((key) => key === Gender.UNKNOWN)}
              setValue={() => handleCheckbox('gender', Gender.UNKNOWN)}
            >
              Unknown
            </Checkbox>
          </div>
        </div>
        <p className="error-msg">{errors?.gender}</p>
        <div className="campaign-modal__demographics_age">
          <label>Age</label>
          <div>
            <Checkbox
              value={form.age.some((key) => key === Age.TWELVE_TO_TWENTY_FIVE)}
              setValue={() => handleCheckbox('age', Age.TWELVE_TO_TWENTY_FIVE)}
            >
              12-25
            </Checkbox>
            <Checkbox
              value={form.age.some((key) => key === Age.TWENTY_SIX_TO_THIRTY_FIVE)}
              setValue={() => handleCheckbox('age', Age.TWENTY_SIX_TO_THIRTY_FIVE)}
            >
              26-35
            </Checkbox>
            <Checkbox
              value={form.age.some((key) => key === Age.THIRTY_SIX_TO_FORTY_FIVE)}
              setValue={() => handleCheckbox('age', Age.THIRTY_SIX_TO_FORTY_FIVE)}
            >
              36-45
            </Checkbox>
            <Checkbox
              value={form.age.some((key) => key === Age.FORTY_SIX_TO_FIFTY_FIVE)}
              setValue={() => handleCheckbox('age', Age.FORTY_SIX_TO_FIFTY_FIVE)}
            >
              46-55
            </Checkbox>
            <Checkbox
              value={form.age.some((key) => key === Age.FIFTY_FIVE_PLUS)}
              setValue={() => handleCheckbox('age', Age.FIFTY_FIVE_PLUS)}
            >
              55+
            </Checkbox>
            <Checkbox
              value={form.age.some((key) => key === Age.UNKNOWN)}
              setValue={() => handleCheckbox('age', Age.UNKNOWN)}
            >
              Unknown
            </Checkbox>
          </div>
        </div>
        <p className="error-msg">{errors?.age}</p>
      </div>
      <SearchValues
        label="Targeted Game Genres"
        values={form.targetedGenres}
        changeHandler={addTargetValue}
        deleteValueHandler={deleteTargetValue}
        errorMessage={errors?.targetedGenres}
      />
      <div className="campaign-modal__asset_actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleAddingNewCampaign}>
          {loading ? <Spinner size="sm" /> : isEdit ? 'Edit' : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default CampaignModal;
