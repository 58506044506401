import { useEffect, useState } from 'react';
import Input from '../../input/input';
import { AttributesData, Dispatcher, Event } from '../../../global/interfaces';
import './attribute-type-number.scss';

const AttributeTypeNumber = ({
  attribute,
  setAttributeList,
  mode = 'add',
  errorMessage,
  disabled = false,
}: {
  attribute: AttributesData;
  setAttributeList: Dispatcher<AttributesData[] | []>;
  mode?: 'add' | 'range' | 'value';
  errorMessage?: string;
  disabled?: boolean;
}) => {
  const [prevPrevAttribute, setPrevAttribute] = useState<AttributesData>();
  const [newAttribute, setNewAttribute] = useState<{
    from: string;
    to: string;
    default: string;
  }>({
    from: '',
    to: '',
    default: '',
  });

  if (attribute.values.length && attribute !== prevPrevAttribute && !prevPrevAttribute) {
    setPrevAttribute(attribute);
    setNewAttribute({ from: attribute.values[0], to: attribute.values[1], default: attribute.values[2] });
  }

  const setAttribute = (e: Event['Input']) => {
    setNewAttribute((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const autosaveTimeout = setTimeout(() => {
      setAttributeList((prev) => {
        return prev.map((attr) => {
          if (attr.traitType === attribute.traitType) {
            return {
              ...attr,
              values: [`${newAttribute.from}`, `${newAttribute.to}`, `${newAttribute.default}`],
            };
          }
          return attr;
        });
      });
    }, 1000);
    return () => {
      clearTimeout(autosaveTimeout);
    };
  }, [attribute.traitType, newAttribute, setAttributeList]);

  return (
    <>
      <div className="attribute_type_number">
        {['add', 'range'].includes(mode) && (
          <>
            <Input
              name="from"
              label="From"
              type="number"
              value={newAttribute.from}
              handleInput={setAttribute}
              hideErrorMessage
              placeholder="Set From value"
              disabled={disabled}
            />
            <Input
              name="to"
              label="To"
              type="number"
              value={newAttribute.to}
              handleInput={setAttribute}
              hideErrorMessage
              placeholder="Set To value"
              disabled={disabled}
            />
          </>
        )}
        {['add', 'value'].includes(mode) && (
          <Input
            name="default"
            label="Default"
            type="number"
            value={newAttribute.default}
            handleInput={setAttribute}
            hideErrorMessage
            placeholder="Set Default value"
            disabled={disabled}
          />
        )}
      </div>
      <p className="error-msg">{errorMessage}</p>
    </>
  );
};

export default AttributeTypeNumber;
