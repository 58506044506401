import { differenceInDays } from 'date-fns';
import { PublicCampaignData, PublicCampaignsData, UserData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { GET_PUBLIC_AD_CAMPAIGN_QUERY, GET_USER_POINTS, GET_USER_QUERY } from '../../../global/gql/queries';
import { Link } from 'react-router-dom';
import { SETTINGS } from '../../../global/routes';
import Icon from '../../../components/Icon/Icon';
import GamerBanner from '../../../components/banners/gamer-banner/gamer-banner';
import ConnectedGames from '../../../components/connected-games/connected-games';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';

import point from '../../../global/images/point.png';

import './gamer-dashboard-page.scss';

const GamerDashboardPage = () => {
  const { data } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);
  const { data: userPoint } = useReneQuery<{ User: UserData } | undefined>(GET_USER_POINTS, {
    variables: { interval: 'DAILY' },
  });
  const { data: publicCampaigns, loading: campaignsLoading } = useReneQuery<
    { PublicAdCampaigns: PublicCampaignsData } | undefined
  >(GET_PUBLIC_AD_CAMPAIGN_QUERY, {
    variables: {
      onlyCurrentUser: true,
    },
  });

  const quests = publicCampaigns?.PublicAdCampaigns.items.reduce<{
    actions: PublicCampaignData['actions'][0][];
    points: string;
  }>(
    (acc, campaign) => {
      const daysLeft = differenceInDays(new Date(campaign.endDate), new Date());
      if (daysLeft > 0) {
        campaign.actions.forEach((action) => {
          if (!action.fulfilled) {
            acc.actions.push(action);
          }
        });
        if (!campaign.name.match('Starter')) {
          acc.points = campaign.leaderboard?.items[0]?.amount || '0';
        }
      }

      return acc;
    },
    { actions: [], points: '0' },
  );
  console.log(quests);
  return (
    <div className="gamer_dashboard_page">
      <GamerBanner user={data?.User} points={userPoint?.User.points?.amount} />
      {campaignsLoading ? (
        <LoadingFallback />
      ) : quests?.actions.length ? (
        <div className="gamer_dashboard_page__quests">
          <div className="gamer_dashboard_page__quests_header">
            <h2>Airdrop Quests</h2>
            <div>
              <p>Total Earn</p>
              <Link to={`/${SETTINGS}/points`}>
                {quests?.points} <img src={point} alt="rene point" />
              </Link>
            </div>
          </div>
          <div className="gamer_dashboard_page__quests_main">
            <div className="public-campaign-bar__goals">
              {quests?.actions.map((action) => (
                <div key={action.actionId}>
                  <Icon name={action.fulfilled ? 'flag-f' : 'flag'} />
                  <p>{action.description}</p>
                  <div>
                    <p>
                      {action.amount} <img src={point} alt="rene point" />
                    </p>
                    <Link className="secondary-btn" to={action.url} target="_blank" rel="noreferrer">
                      Start Quest
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="gamer_dashboard_page__quests_footer">
            <Link to={`/${SETTINGS}/points`}>
              More details <Icon name="chevron-right" />
            </Link>
          </div>
        </div>
      ) : (
        <div className="gamer_dashboard_page__quests_cleared">
          <Icon name="circle-check" />
          <p>You have completed all quest of this Airdrop Period</p>
          <Link className="secondary-btn" to={`/${SETTINGS}/points`}>
            View details
          </Link>
        </div>
      )}
      <div className="gamer_dashboard_page__referrals">
        <div>
          <h2>Invite friends to ReneVerse and earn rewards.</h2>
          {/* <button>
            <Icon name="close" />
          </button> */}
        </div>
        <p>Bonus - get your share of the currently ongoing $500K $RENE Airdrop!</p>
        <Link to={`/${SETTINGS}/referrals`}>Visit Referrals</Link>
      </div>
      <ConnectedGames />
    </div>
  );
};

export default GamerDashboardPage;
