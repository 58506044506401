import { ReactNode } from 'react';
import './tooltip.scss';

const Tooltip = ({ value, children }: { value: string; children: ReactNode }) => {
  return (
    <div className="tooltip" data-tooltip={value}>
      {children}
    </div>
  );
};

export default Tooltip;
