import { useCallback, useEffect, useState } from 'react';
import { COLLECTIONS } from '../../../global/routes';
import { GameData, GamesData, OrganizationData, UserData } from '../../../global/interfaces';
import { GET_ORG_QUERY, GAME_SEARCH_QUERY, GET_USER_QUERY, GET_ORG_GAMES_QUERY } from '../../../global/gql/queries';
import { UserRole } from '../../../global/consts';
import { isRoleAllowed } from '../../../utils';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { useLazyReneQuery } from '../../../hooks';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Select from '../../../components/select/select';
import Search from '../../../components/search/search';
import ChainLogo from '../../../components/chain-logo/chain-logo';
import Statistics from '../../../components/statistics/statistics';
import NewGameModal from '../../../components/modal/new-game-modal/new-game-modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import SmallDashboardBar from '../../../components/small-dashboard-bar/small-dashboard-bar';

import './organization-games-page.scss';

const OrganizationGamesPage = () => {
  const [gameSearchTerm, setSearchTerm] = useState('');
  const [isNewGameModalOpen, setIsNewGameModalOpen] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const { data: orgData, loading: orgLoading } = useReneQuery<{ Organization: OrganizationData } | undefined>(
    GET_ORG_QUERY,
  );

  const { data: gamesData, loading: gamesLoading } = useReneQuery<{ Games: GamesData } | undefined>(
    GET_ORG_GAMES_QUERY,
  );

  const [search, { data: searchGames, loading: searchLoading }] = useLazyReneQuery<{ GameSearch: GamesData }>(
    GAME_SEARCH_QUERY,
  );

  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]);

  const showGamesData = gameSearchTerm && searchGames ? searchGames.GameSearch : gamesData?.Games;

  useEffect(() => {
    if (gameSearchTerm) {
      search({ variables: { gameSearchTerm } });
    }
  }, [gameSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const games = showGamesData?.items.map((game: GameData) => (
    <SmallDashboardBar
      key={game.gameId}
      id={game.gameId}
      linkTo={`${game.gameId}/${COLLECTIONS}`}
      name={game.name}
      imageUrl={game?.image?.url}
      description={game?.data?.description}
    >
      <div className="small-dashboard-bar__stats">
        <ChainLogo chain={game?.chain} />
        <Statistics
          data={{
            assets: game?.stats?.mintedAssets,
            players: game?.stats?.players,
            impressions: game.adCampaignStats?.impressions,
          }}
        />
      </div>
    </SmallDashboardBar>
  ));

  return (
    <div className="organization-games-page">
      <div className="organization-games-page__heading">
        <div className="organization-games-page__heading_title">
          <h1>
            Games <span>{orgData?.Organization?.stats?.games}</span>
          </h1>
          <div>
            <p>
              <span> {orgData?.Organization?.stats?.mintedAssets}</span> Assets
            </p>
            <p>
              <span>{orgData?.Organization?.stats?.players}</span> Players
            </p>
          </div>
        </div>
        {isUserAllowed && (
          <button
            type="button"
            className="primary-btn organization-games-page__heading_new"
            onClick={() => setIsNewGameModalOpen(true)}
          >
            <Icon name="plus" size={24} />
            <p>New Game</p>
          </button>
        )}
      </div>
      <div className="organization-games-page__content">
        <div className="organization-games-page__content_actions">
          <Search callback={handleSearch} apiSearch />
          <Select value="Latest" options={['Latest']} changeHandler={() => {}}>
            <Icon name="arrows-sort" size={16} />
          </Select>
        </div>
        <div className="organization-games-page__content_games">
          {orgLoading || searchLoading || gamesLoading ? <LoadingFallback /> : games}
        </div>
      </div>
      <Modal isOpen={isNewGameModalOpen}>
        <NewGameModal setCloseModal={() => setIsNewGameModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationGamesPage;
