import React, { useEffect, useState } from 'react';

type ShowPopUpMessage = React.Dispatch<React.SetStateAction<string>>;
type CountProviderProps = { children: React.ReactNode };

const UpdatePopUpContext = React.createContext<
  { updatePopUpMessage: string; showUpdatePopUpMessage: ShowPopUpMessage } | undefined
>(undefined);

function UpdatePopUpProvider({ children }: CountProviderProps) {
  const [updatePopUpMessage, showUpdatePopUpMessage] = useState<string>('');

  useEffect(() => {
    let errorInterval: any;
    if (updatePopUpMessage) {
      errorInterval = setInterval(() => {
        showUpdatePopUpMessage('');
      }, 10000);
    }
    return () => {
      clearInterval(errorInterval);
    };
  }, [updatePopUpMessage]);
  const value = { updatePopUpMessage, showUpdatePopUpMessage };
  return <UpdatePopUpContext.Provider value={value}>{children}</UpdatePopUpContext.Provider>;
}

function useUpdatePopUpContext() {
  const context = React.useContext(UpdatePopUpContext);
  if (context === undefined) {
    throw new Error('useUpdatePopUpContext must be used within a NotificationProvider');
  }
  return context;
}

export { UpdatePopUpProvider, useUpdatePopUpContext };
