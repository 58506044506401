import './help-page.scss';

const HelpPage = () => {
  return (
    <div className="help-page">
      <h1 className="rainbow-btn-text">Help</h1>
    </div>
  );
};

export default HelpPage;
