import { GameData } from '../../../../../global/interfaces';
import GameWallet from '../../../../../components/game-wallet/game-wallet';
import LoadingFallback from '../../../../../components/loading-fallback/loading-fallback';

import './wallet.scss';

const Wallet = ({ game }: { game: GameData | undefined }) => {
  return (
    <div className="organization_game_setting_tab__wallet">
      <h3 className="rainbow-btn-text">Wallet</h3>
      {game ? <GameWallet game={game} /> : <LoadingFallback />}
    </div>
  );
};

export default Wallet;
