import { useState } from 'react';
import { useFileUpload, useReneMutation, useValidation } from '../../../hooks';
import { validations } from './validations';
import { UPSERT_AD_MUTATION } from '../../../global/gql/mutations';
import { AdType } from '../../../global/consts';
import { AdData, CampaignsData, Content, Event, Refetch } from '../../../global/interfaces';
import { SearchContentValues } from '../../search-content-values/search-content-values';
import { CONTENT_SEARCH } from '../../../global/gql/queries';
import { removeTypename } from '../../../utils';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import ReachText from '../../reach-text/reach-text';
import FileUpload from '../../file-upload/file-upload';

import './new-ad-modal.scss';

const selectOptions = {
  [AdType.BANNER]: 'Banner',
  [AdType.VIDEO]: 'Video',
};

const NewAdModal = ({
  adCampaignId,
  refetch,
  setCloseModal,
}: {
  adCampaignId: string;
  refetch: Refetch<{ AdCampaigns: CampaignsData } | undefined>;
  setCloseModal: () => void;
}) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid, isFieldInvalid } = useValidation(validations);
  const [reachText, setReachText] = useState('');

  const [form, setForm] = useState<{
    name: string;
    adType: AdType | '';
    externalBannerUrl: string;
    banner: File | undefined;
    video: File | undefined;
    contentTags: Content[];
  }>({
    name: '',
    adType: AdType.BANNER,
    externalBannerUrl: '',
    banner: undefined,
    video: undefined,
    contentTags: [],
  });
  const [loading, setLoading] = useState(false);

  const [upsertAd] = useReneMutation(UPSERT_AD_MUTATION, {
    onCompleted(data: { UpsertAd: AdData }) {
      const file = data.UpsertAd.adType === AdType.BANNER ? form.banner : form.video;
      const type = data.UpsertAd.adType === AdType.BANNER ? 'banner' : 'video';
      if (file && type) {
        uploadFile(data.UpsertAd[type].uploadUrl, file).finally(() => {
          handleFinishUpsert();
        });
      }
    },
  });

  const handleUpsertCollection = () => {
    if (isFormInvalid({ ...form, reachText })) return;
    setLoading(true);
    const variables = {
      adCampaignId,
      name: form.name,
      adType: form.adType,
      banner: form.adType === AdType.BANNER ? { extension: form?.banner?.type.split('/')[1] } : undefined,
      video: form.adType === AdType.VIDEO ? { extension: form?.video?.type.split('/')[1] } : undefined,
      interactionHtml: reachText,
      contentTags: form.contentTags,
    };

    upsertAd({ variables });
  };
  const handleFormChange = (e: Event['Input'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    if (isFieldInvalid('file', { ...form, file })) return;
  };

  const selectChangeHandler = (value: AdType) => {
    return setForm((prev) => ({ ...prev, adType: value, banner: undefined, video: undefined }));
  };

  const setFile = (files: File[]) => {
    const field = form.adType === AdType.BANNER ? 'banner' : 'video';
    setForm((prev) => ({ ...prev, [field]: files?.[0] }));
  };

  const addTargetValue = (value: Content) => {
    setForm((prev) => ({ ...prev, contentTags: [...form.contentTags, removeTypename(value)] }));
  };

  const deleteTargetValue = (id: string) => {
    const contentTags = [...form.contentTags].filter((taxonomy) => taxonomy.taxonomyContentId !== id);
    setForm({ ...form, contentTags });
  };

  const handleFinishUpsert = () => {
    refetch();
    setLoading(false);
    setCloseModal();
  };

  return (
    <div className="new-ad-modal">
      <div className="new-ad-modal__heading">
        <h2>New Ad</h2>
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Input
        label="Name"
        name="name"
        className="new-ad-modal__name"
        placeholder="Enter ad name"
        handleInput={handleFormChange}
        value={form.name}
        errorMessage={errors?.name}
      />
      <Select
        label="Type"
        value={form.adType}
        options={selectOptions}
        changeHandler={selectChangeHandler}
        showSelectedValueFn={(value) => value}
        errorMsg={errors?.adType}
        showError
      />
      {form.adType === AdType.BANNER && (
        <FileUpload
          label="Banner"
          setFiles={setFile}
          description="Upload Banner Image"
          errorMessage={errors?.banner}
          handleChange={handleFileChange}
        />
      )}
      {form.adType === AdType.VIDEO && (
        <FileUpload
          label="Video"
          setFiles={setFile}
          description="Upload mp4 video"
          errorMessage={errors?.video}
          handleChange={handleFileChange}
        />
      )}
      <SearchContentValues
        query={CONTENT_SEARCH}
        label="Ad Content Tags"
        values={form.contentTags}
        changeHandler={addTargetValue}
        deleteValueHandler={deleteTargetValue}
      />
      <ReachText reachText={reachText} setReachText={setReachText} error={errors?.reachText} />
      <div className="new-ad-modal__actions">
        <button type="button" className="secondary-btn" onClick={setCloseModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={handleUpsertCollection} disabled={loading}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};

export default NewAdModal;
