import { AssetType } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const validations = {
  name: commonValidations.requiredTrimmed(),
  description: commonValidations.requiredTrimmed(),
  file: commonValidations.fileImage(),
  // status: commonValidations.required(),
  brandId: (form: { type: string }, key: string, brandId: string) => {
    if (form.type === AssetType.BRANDED && !brandId) return 'This field is required';
  },
};
