import { CrossGameState } from '../../global/consts';
import Icon from '../Icon/Icon';
import './ownable-asset-state.scss';

const OwnableAssetState = ({
  derived,
  crossGameState,
  inGames,
  supply,
}: {
  derived: string | undefined;
  crossGameState: CrossGameState;
  inGames: number;
  supply: number;
}) => {
  return (
    <div className="ownable-asset-state">
      {/* {derived && (
        <div className="ownable-asset-state__derived">
          <Icon name="stars" />
          <p>Derived</p>
        </div>
      )} */}
      {/* {crossGameState === CrossGameState.CLOSED && (
        <div className="ownable-asset-state__closed">
          <Icon name="lock" />
          <p>Closed</p>
        </div>
      )}
      {crossGameState === CrossGameState.OPEN && (
        <div className="ownable-asset-state_opened">
          <Icon name="lock-open" />
          <p>Opened</p>
        </div>
      )} */}
      {/* <div className="ownable-asset-state__games">
        <Icon name="gamepad" />
        <p>
          {inGames} {`${inGames > 1 ? 'games' : 'game'}`}
        </p>
      </div> */}
      {supply && (
        <div className="ownable-asset-state__supply">
          <Icon name="database" />
          <p>{supply}</p>
        </div>
      )}
    </div>
  );
};

export default OwnableAssetState;
