import { allowedImageTypes, allowedVideoTypes } from '../../../../global/consts';

export const validations = {
  files: (
    form: {
      files: File[];
      type?: string;
      uploadedFileNames?: string[];
    },
    key: 'files',
  ) => {
    const files = Array.from(form?.[key] || []);
    if (!files.length) return 'This field is required';

    const uploadedFiles = form?.uploadedFileNames || [];
    const allowedType = form?.type;

    let errorMessage = '';
    files.forEach(({ name, type }) => {
      if (uploadedFiles.includes(name)) errorMessage = 'The file(s) already exists';
      if (allowedType === 'images') {
        if (!allowedImageTypes?.includes(type))
          errorMessage = 'You must provide a valid image (jpg/jpeg, png, webp) file';
      } else if (allowedType === 'animations') {
        if (!allowedVideoTypes?.includes(type)) errorMessage = 'You must provide a valid video (mp4, webm) file';
      }
    });

    return errorMessage;
  },
};
