import { OWNABLE_ASSET_FIELDS, GAME_FIELDS, USER_FIELDS, BRANDED_ASSET_FIELDS } from './../fragments/index';
import { gql } from '@apollo/client';

export const LOGIN_USER_MUTATION = gql`
  mutation Login($email: String, $password: String!, $username: String) {
    LoginUser(input: { email: $email, password: $password, username: $username }) {
      jwt
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  ${USER_FIELDS}
  mutation UpdateUser(
    $firstName: String!
    $lastName: String!
    $discordId: String
    $steamId: String
    $image: S3FileInput
  ) {
    UpdateUser(
      input: {
        data: { firstName: $firstName, lastName: $lastName }
        externalAccounts: { discordId: $discordId, steamId: $steamId }
        image: $image
      }
    ) {
      ...UserFields
    }
  }
`;

export const UPDATE_USERNAME = gql`
  mutation UpdateUsername($username: String!) {
    UpdateUsername(input: { username: $username })
  }
`;

export const UPDATE_FIRST_TIME_USER = gql`
  ${USER_FIELDS}
  mutation UpdateFirstTimeUser($isFirstTimeUser: Boolean) {
    UpdateUser(input: { isFirstTimeUser: $isFirstTimeUser }) {
      ...UserFields
    }
  }
`;

export const UPSERT_OWNABLE_ASSET_MUTATION = gql`
  ${OWNABLE_ASSET_FIELDS}
  mutation UpsertOwnableAsset(
    $name: String
    $ownableAssetId: String
    $collectionId: String
    $attributes: [OwnableAssetAttributeInput]
    $data: OwnableAssetDataInput
    $files: OwnableAssetFilesInput
    $gameEngineFiles: [S3FileInput]
    $image: S3FileInput
    $metadataTemplates: OwnableAssetMetadataTemplateInput
    $isIpfs: Boolean
    $updatedAt: String
  ) {
    UpsertOwnableAsset(
      input: {
        name: $name
        ownableAssetId: $ownableAssetId
        collectionId: $collectionId
        attributes: $attributes
        data: $data
        files: $files
        gameEngineFiles: $gameEngineFiles
        image: $image
        metadataTemplates: $metadataTemplates
        isIpfs: $isIpfs
        updatedAt: $updatedAt
      }
    ) {
      ...OwnableAssetFields
    }
  }
`;

export const UPSERT_BRANDED_ASSET_MUTATION = gql`
  ${BRANDED_ASSET_FIELDS}
  mutation UpsertBrandedAsset(
    $name: String
    $brandId: String
    $brandedAssetId: String
    $collectionId: String
    $description: String
    $gameEngineFiles: [S3FileInput]
    $image: S3FileInput
    $updatedAt: String
  ) {
    UpsertBrandedAsset(
      input: {
        name: $name
        brandId: $brandId
        brandedAssetId: $brandedAssetId
        collectionId: $collectionId
        description: $description
        gameEngineFiles: $gameEngineFiles
        image: $image
        updatedAt: $updatedAt
      }
    ) {
      ...BrandedAssetFields
    }
  }
`;

export const UPSERT_GAME_MUTATION = gql`
  mutation UpsertGame(
    $gameId: String
    $name: String!
    $chainSymbol: String
    $description: String!
    $image: S3FileInput
    $urls: [GameUrlInput]
    $isActive: Boolean!
    $updatedAt: String
    $approvedUserScopes: [UserScopes]
  ) {
    UpsertGame(
      input: {
        gameId: $gameId
        image: $image
        name: $name
        updatedAt: $updatedAt
        data: { description: $description }
        urls: $urls
        isActive: $isActive
        chainSymbol: $chainSymbol
        approvedUserScopes: $approvedUserScopes
      }
    ) {
      name
      gameId
      data {
        description
      }
      image {
        url
        uploadUrl
      }
    }
  }
`;

export const MINT_ASSET_MUTATION = gql`
  mutation MintAsset(
    $ownableAssetId: String!
    $gameId: String!
    $userId: String
    $animationFilename: String
    $description: String!
    $imageFilename: String
    $name: String!
    $attributes: [AssetAttributeInput!]!
    $isTestNet: Boolean!
    $randomRecipient: Boolean
  ) {
    MintAsset(
      input: {
        ownableAssetId: $ownableAssetId
        userId: $userId
        gameId: $gameId
        randomRecipient: $randomRecipient
        isTestNet: $isTestNet
        metadata: {
          animationFilename: $animationFilename
          description: $description
          imageFilename: $imageFilename
          name: $name
          attributes: $attributes
        }
      }
    )
  }
`;

export const UPSERT_COLLECTION_MUTATION = gql`
  mutation UpsertCollection(
    $brandId: String
    $collectionId: String
    $image: S3FileInput
    $name: String!
    $crossGameState: CrossGameState
    $description: String!
    $gameId: String
    $updatedAt: String
    $type: AssetType!
  ) {
    UpsertCollection(
      input: {
        gameId: $gameId
        brandId: $brandId
        collectionId: $collectionId
        description: $description
        updatedAt: $updatedAt
        name: $name
        image: $image
        crossGameState: $crossGameState
        type: $type
      }
    ) {
      name
      description
      collectionId
      crossGameState
      image {
        url
        uploadUrl
      }
    }
  }
`;

export const ADD_COLLECTION_TO_GAME_MUTATION = gql`
  mutation AddCollectionToGame($collectionId: String!, $gameId: String!) {
    AddCollectionToGame(input: { collectionId: $collectionId, gameId: $gameId })
  }
`;

export const AUTHORIZE_GAME_MUTATION = gql`
  mutation GameConnectAuth($authId: String!) {
    GameConnectAuth(authId: $authId) {
      authId
      authorized
      gameId
      userId
    }
  }
`;

export const TRANSFER_ASSET_MUTATION = gql`
  mutation TransferAsset($assetId: String!, $receiverUserId: String!) {
    TransferAsset(input: { assetId: $assetId, receiverUserId: $receiverUserId }) {
      assetId
      receiverUserId
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    ChangePassword(input: { oldPassword: $oldPassword, newPassword: $newPassword })
  }
`;

export const INITIATE_PASSWORD_RESET_MUTATION = gql`
  mutation InitiatePasswordReset($email: String!) {
    InitiatePasswordReset(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPassword($email: String!, $newPassword: String!, $otp: String!) {
    ResetPassword(input: { email: $email, newPassword: $newPassword, otp: $otp })
  }
`;

export const REGISTER_USER_MUTATION = gql`
  mutation RegisterUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $role: UserRoles!
    $referralCode: String
    $username: String!
  ) {
    RegisterUser(
      input: {
        data: { firstName: $firstName, lastName: $lastName }
        email: $email
        password: $password
        role: $role
        referralCode: $referralCode
        username: $username
      }
    ) {
      jwt
    }
  }
`;

export const INITIATE_USER_VERIFICATION_MUTATION = gql`
  mutation ResendEmailVerification {
    ResendEmailVerification
  }
`;

export const VERIFY_USER_MUTATION = gql`
  mutation VerifyUser($otp: String!) {
    VerifyUser(input: { otp: $otp }) {
      jwt
    }
  }
`;

export const UPSERT_ORG_MUTATION = gql`
  ${GAME_FIELDS}
  ${USER_FIELDS}
  mutation UpsertOrganization(
    $data: OrgDataInput
    $image: S3FileInput
    $name: String
    $orgId: String
    $isActive: Boolean
    $updatedAt: String
    $type: OrganizationTypes
  ) {
    UpsertOrganization(
      input: {
        data: $data
        image: $image
        type: $type
        name: $name
        orgId: $orgId
        isActive: $isActive
        updatedAt: $updatedAt
      }
    ) {
      orgId
      isActive
      name
      data {
        description
      }
      image {
        url
        uploadUrl
      }
      games {
        items {
          ...GameFields
        }
      }
      members {
        items {
          ...UserFields
        }
      }
    }
  }
`;

export const CSV_FILE_BULK_MINT_ASSET = gql`
  mutation CSVFileBulkMintAsset(
    $ownableAssetId: String!
    $fileId: String!
    $gameId: String
    $userId: String
    $isTestNet: Boolean!
  ) {
    CSVFileBulkMintAsset(
      input: {
        ownableAssetId: $ownableAssetId
        fileId: $fileId
        gameId: $gameId
        userId: $userId
        isTestNet: $isTestNet
      }
    ) {
      errors {
        message
      }
    }
  }
`;

export const CSV_FILE_UPLOAD_MUTATION = gql`
  mutation CSVFileUploadURL {
    CSVFileUploadURL {
      fileId
      uploadUrl
    }
  }
`;

export const BULK_MINT_ASSET = gql`
  mutation BulkMintAsset(
    $ownableAssetId: String!
    $animationFilename: String
    $description: String!
    $imageFilename: String
    $name: String!
    $userId: String
    $gameId: String
    $size: Int!
    $isTestNet: Boolean!
    $attributes: [AssetAttributeInput!]!
  ) {
    BulkMintAsset(
      input: {
        ownableAssetId: $ownableAssetId
        userId: $userId
        gameId: $gameId
        isTestNet: $isTestNet
        metadata: {
          animationFilename: $animationFilename
          description: $description
          imageFilename: $imageFilename
          name: $name
          attributes: $attributes
        }
        size: $size
      }
    )
  }
`;

export const ADD_USER_TO_ORG_MUTATION = gql`
  mutation AddUserToOrganization($userId: String!, $role: BasicUserRoles!) {
    AddUserToOrganization(input: { userId: $userId, role: $role })
  }
`;

export const REMOVE_USER_TO_ORG_MUTATION = gql`
  mutation RemoveUserFromOrganization($userId: String!) {
    RemoveUserFromOrganization(userId: $userId)
  }
`;

export const GENERATE_API_CREDENTIALS_MUTATION = gql`
  mutation GenerateApiCredentials($expiry: String, $gameId: String!, $isTestNet: Boolean, $type: ApiCredType!) {
    GenerateApiCredentials(
      input: { expiry: $expiry, gameId: $gameId, scopes: user_all, isTestNet: $isTestNet, type: $type }
    ) {
      apiKey
      apiSecret
      expiry
      scopes
      isTestNet
    }
  }
`;

export const REMOVE_API_CREDENTIALS_MUTATION = gql`
  mutation RemoveApiCredentials($apiKey: String!, $gameId: String!) {
    RemoveApiCredentials(input: { apiKey: $apiKey, gameId: $gameId })
  }
`;

export const REFRESH_TOKEN = gql`
  mutation RefreshToken {
    RefreshToken {
      jwt
    }
  }
`;

export const TRACK_AUTH_EVENT_MUTATIONS = gql`
  mutation TrackAuthenticatedEvent(
    $distinctId: String!
    $eventType: AuthenticatedEventType!
    $ownableAssetId: String
    $gameEngineFiles: [String]
  ) {
    TrackAuthenticatedEvent(
      input: {
        distinctId: $distinctId
        eventType: $eventType
        ownableAssetId: $ownableAssetId
        gameEngineFiles: $gameEngineFiles
      }
    )
  }
`;

export const TRACK_UNAUTH_EVENT_MUTATIONS = gql`
  mutation TrackUnauthenticatedEvent(
    $distinctId: String!
    $eventType: UnauthenticatedEventType!
    $requestDemoDate: String
    $supportRequest: String
  ) {
    TrackUnauthenticatedEvent(
      input: {
        distinctId: $distinctId
        eventType: $eventType
        requestDemoDate: $requestDemoDate
        supportRequest: $supportRequest
      }
    )
  }
`;

export const TOP_UP_BALANCE_MUTATION = gql`
  mutation TopupBalance($amount: Int!, $successUrl: String!, $cancelUrl: String!) {
    TopupBalance(amount: $amount, successUrl: $successUrl, cancelUrl: $cancelUrl) {
      paymentLink
      balanceId
    }
  }
`;

export const UPSERT_AD_CAMPAIGN_MUTATION = gql`
  mutation UpsertAdCampaign(
    $adCampaignId: String
    $age: [Age]
    $campaignGoal: CampaignGoal
    $description: String
    $endDate: String
    $gender: [Gender]
    $name: String
    $startDate: String
    $targetedGenres: [String]
    $targetedRegion: TargetedRegion
    $targetedLocations: [Countries]
    $campaignImage: S3FileInput
    $updatedAt: String
    $brandId: String
    $isDemo: Boolean
  ) {
    UpsertAdCampaign(
      input: {
        adCampaignId: $adCampaignId
        age: $age
        campaignGoal: $campaignGoal
        description: $description
        endDate: $endDate
        gender: $gender
        name: $name
        startDate: $startDate
        targetedGenres: $targetedGenres
        targetedLocations: $targetedLocations
        targetedRegion: $targetedRegion
        campaignImage: $campaignImage
        updatedAt: $updatedAt
        brandId: $brandId
        isDemo: $isDemo
      }
    ) {
      adCampaignId
      description
      name
      stats {
        impressions
        interactions
        cost
      }
      campaignImage {
        uploadUrl
        url
      }
    }
  }
`;

export const UPSERT_AD_MUTATION = gql`
  mutation UpsertAd(
    $name: String!
    $adId: String
    $adType: AdType
    $banner: S3FileInput
    $video: S3FileInput
    $isActive: Boolean
    $adCampaignId: String
    $interactionHtml: String
    $contentTags: [ContentInput]
    $updatedAt: String
  ) {
    UpsertAd(
      input: {
        name: $name
        adId: $adId
        adType: $adType
        banner: $banner
        video: $video
        adCampaignId: $adCampaignId
        updatedAt: $updatedAt
        interactionHtml: $interactionHtml
        contentTags: $contentTags
        isActive: $isActive
      }
    ) {
      adCampaignId
      adId
      adType
      banner {
        url
        uploadUrl
      }
      video {
        url
        uploadUrl
      }
      name
    }
  }
`;

export const DELETE_ADD_MUTATION = gql`
  mutation DeleteAd($adId: String!) {
    DeleteAd(adId: $adId)
  }
`;

export const UPSERT_AD_SURFACE_MUTATION = gql`
  mutation UpsertAdSurface(
    $adSurfaceId: String
    $ownableAssetId: String
    $brandedAssetId: String
    $gameId: String
    $adType: AdType
    $resolutionIab: AdResolutionIab
    $interactivity: AdInteractivity
    $placeholder: S3FileInput
    $contentTags: [ContentInput]
    $maxWidth: String
    $maxHeight: String
    $updatedAt: String
  ) {
    UpsertAdSurface(
      input: {
        adSurfaceId: $adSurfaceId
        ownableAssetId: $ownableAssetId
        brandedAssetId: $brandedAssetId
        gameId: $gameId
        adType: $adType
        interactivity: $interactivity
        resolutionIab: $resolutionIab
        placeholder: $placeholder
        contentTags: $contentTags
        maxWidth: $maxWidth
        maxHeight: $maxHeight
        updatedAt: $updatedAt
      }
    ) {
      adSurfaceId
      adType
      resolutionIab
      contentTags {
        name
      }
      maxWidth
      maxHeight
      placeholder {
        uploadUrl
        url
      }
    }
  }
`;

export const DELETE_AD_SURFACE_MUTATION = gql`
  mutation DeleteAdSurface($adSurfaceId: String!) {
    DeleteAdSurface(adSurfaceId: $adSurfaceId)
  }
`;

export const READ_NOTIFICATION_MUTATION = gql`
  mutation ReadNotification($notificationId: String!) {
    ReadNotification(input: { notificationId: $notificationId })
  }
`;

export const UPSERT_BRAND_MUTATION = gql`
  mutation UpsertBrand(
    $brandId: String
    $name: String!
    $description: String!
    $image: S3FileInput
    $website: String
    $updatedAt: String
  ) {
    UpsertBrand(
      input: {
        brandId: $brandId
        name: $name
        description: $description
        image: $image
        website: $website
        updatedAt: $updatedAt
      }
    ) {
      brandId
      image {
        url
        uploadUrl
      }
    }
  }
`;

export const CONNECT_TWITTER_MUTATION = gql`
  mutation ConnectTwitterAccount($redirectUrl: String!) {
    ConnectTwitterAccount(input: { redirectUrl: $redirectUrl }) {
      authUrl
    }
  }
`;

export const DISCONNECT_TWITTER_MUTATION = gql`
  mutation DisconnectTwitterAccount {
    DisconnectTwitterAccount
  }
`;

export const CONNECT_DISCORD_MUTATION = gql`
  mutation ConnectDiscordAccount($redirectUrl: String!) {
    ConnectDiscordAccount(input: { redirectUrl: $redirectUrl }) {
      authUrl
    }
  }
`;

export const DISCONNECT_DISCORD_MUTATION = gql`
  mutation DisconnectDiscordAccount {
    DisconnectDiscordAccount
  }
`;

export const INITIATE_NFT_CONNECT = gql`
  mutation InitiateConnectNft {
    InitiateConnectNft {
      nonce
    }
  }
`;

export const CONNECT_NFT = gql`
  mutation ConnectNft($message: String!, $signature: String!) {
    ConnectNft(message: $message, signature: $signature)
  }
`;
