import { Dispatcher, TransactionModalData } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import mintingImg from './images/minting.png';
import transferImg from './images/asset-transfer.png';

import './transaction-initiated-modal.scss';

type ImageKeys = 'minting' | 'transfer';

const images: { [key in ImageKeys]: string } = {
  minting: mintingImg,
  transfer: transferImg,
};

const TransactionInitiatedModal: React.FC<{
  text?: string;
  type: ImageKeys;
  setCloseModal: Dispatcher<TransactionModalData>;
}> = ({
  text = 'The minting has started. You will receive a notification once the minting is complete.',
  type,
  setCloseModal,
}) => {
  const handleCloseModal = () => {
    setCloseModal((prev) => ({ show: !prev }));
  };

  return (
    <div className="transaction_initiated">
      <button type="button" onClick={handleCloseModal}>
        <Icon name="close" size={24} />
      </button>
      <img src={images[type]} alt="paper plane" />
      <h2>{text}</h2>
      <button type="button" className="primary-btn" onClick={handleCloseModal}>
        Close
      </button>
    </div>
  );
};
export default TransactionInitiatedModal;
