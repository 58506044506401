import { DependencyList, EffectCallback, useEffect } from 'react';

// This hook is used to run effects only in production.
export function useProdEffect(callback: EffectCallback, deps?: DependencyList): void {
  return useEffect(
    () => {
      if (process.env.REACT_APP_RENE_ENVIRONMENT === 'prd') {
        return callback();
      }

      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );
}
