import { useReneQuery } from '../../../../hooks';
import { GET_ASSETS_QUERY, GET_USER_TRANSACTIONS_QUERY } from '../../../../global/gql/queries';
import { AssetsData, Dispatcher, UserData } from '../../../../global/interfaces';
import Icon from '../../../../components/Icon/Icon';
import Wallet from '../../../../components/wallet/wallet';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import './wallet-settings.scss';

const WalletSettings = ({ setOpenMobileMenu }: { setOpenMobileMenu?: Dispatcher<boolean> }) => {
  const { data } = useReneQuery<{ Assets: AssetsData }>(GET_ASSETS_QUERY);
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_TRANSACTIONS_QUERY);

  return (
    <div className="wallet-settings">
      <div className="wallet-settings__heading">
        {setOpenMobileMenu && (
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
        )}
        <h1>Wallet</h1>
      </div>
      {user && data ? (
        <Wallet
          walletAddress={user?.User.walletAddress}
          assets={data?.Assets.items}
          transactions={user?.User?.transactions?.items}
        />
      ) : (
        <LoadingFallback />
      )}
    </div>
  );
};

export default WalletSettings;
