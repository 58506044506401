import React, { useEffect, useState } from 'react';

type SetErrorMessage = React.Dispatch<React.SetStateAction<{ message: string; showError: boolean }>>;
type CountProviderProps = { children: React.ReactNode };

const ErrorContext = React.createContext<
  { error: { message: string; showError: boolean }; setError: SetErrorMessage } | undefined
>(undefined);

function ErrorProvider({ children }: CountProviderProps) {
  const [error, setError] = useState<{ message: string; showError: boolean }>({
    message: '',
    showError: false,
  });

  useEffect(() => {
    let errorInterval: any;
    if (error.showError) {
      errorInterval = setInterval(() => {
        setError({ message: '', showError: false });
      }, 5000);
    }
    return () => {
      clearInterval(errorInterval);
    };
  }, [error.showError]);

  const value = { error, setError };
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
}

function useErrorContext() {
  const context = React.useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useErrorContext must be used within a NotificationProvider');
  }
  return context;
}

export { ErrorProvider, useErrorContext };
