import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { OwnableAssetData, Dispatcher, Refetch } from '../../../../../../global/interfaces';
import Icon from '../../../../../../components/Icon/Icon';
import Files from '../../../../../../components/files/files';
import './data.scss';

const Data = ({
  ownableAsset,
  isUserAllowedToUpsert,
  setOpenMobileMenu,
  refetch,
}: {
  ownableAsset: OwnableAssetData | undefined;
  isUserAllowedToUpsert: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
  refetch: Refetch<{
    OwnableAssets: {
      items: OwnableAssetData[];
    };
  }>;
}) => {
  const params = useParams();
  const [selectedFolder, setSelectedFolder] = useState<'animations' | 'images' | ''>('');

  return (
    <div className="data">
      {selectedFolder ? (
        <Files
          type={selectedFolder}
          refetch={refetch}
          ownableAssetId={params.assetId as string}
          files={ownableAsset?.files[selectedFolder as keyof OwnableAssetData['files']]}
          setSelectedFolder={setSelectedFolder}
          selectedType={selectedFolder}
          isUploadAllowed={isUserAllowedToUpsert}
        />
      ) : (
        <>
          <div className="data__heading">
            <button onClick={() => setOpenMobileMenu(true)}>
              <Icon name="hamburger" />
            </button>
            <h1 className="rainbow-btn-text">DATA</h1>
          </div>
          <div className="data__main">
            <button type="button" onClick={() => setSelectedFolder('images')}>
              <Icon name="folders" size={32} />
              <p>Images</p>
            </button>
            <button type="button" onClick={() => setSelectedFolder('animations')}>
              <Icon name="folders" size={32} />
              <p>Animations</p>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Data;
