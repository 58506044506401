import { useState } from 'react';
import { validations } from './validations';
import { UPSERT_BRANDED_ASSET_MUTATION } from '../../../global/gql/mutations';
import { Brand, BrandedAssetData, CollectionsData, Refetch } from '../../../global/interfaces';
import { useReneMutation, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import FileUpload from '../../file-upload/file-upload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import Textarea from '../../textarea/textarea';
import placeholder from '../../../global/images/avatar.webp';

import './new-branded-asset-modal.scss';

interface Props {
  brand: Brand | undefined;
  collectionId: string | undefined;
  refetch: Refetch<{
    Collections: CollectionsData;
  }>;
  setCloseModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

interface Variables {
  name: string;
  brandId: string;
  collectionId: string;
  description?: string;
  image?: {
    extension?: string;
  };
}

const NewBrandedAssetModal: React.FC<Props> = ({ brand, collectionId, refetch, setCloseModal }) => {
  const uploadImage = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [file, setFile] = useState<File>();

  const [upsertBrandedAsset] = useReneMutation(UPSERT_BRANDED_ASSET_MUTATION, {
    onCompleted(data: { UpsertBrandedAsset: BrandedAssetData }) {
      if (file) {
        uploadImage(data.UpsertBrandedAsset.image.uploadUrl, file)
          .then(() => {
            refetch();
          })
          .finally(() => {
            handleModal();
            setLoading(false);
          });
      }
    },
  });

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };

  const handleOwnableAssetCreate = () => {
    if (isFormInvalid({ file, name, description }) || !collectionId || !brand?.brandId) return;

    setLoading(true);

    const variables: Variables = {
      name: name.trim(),
      brandId: brand.brandId,
      collectionId,
      description: description.trim() || undefined,
      image: {
        extension: file?.type.split('/')[1],
      },
    };
    upsertBrandedAsset({ variables });
  };

  return (
    <div className="new-branded-asset-modal">
      <div className="new-branded-asset-modal__heading">
        <h2>New Branded Asset</h2>
        <button type="button" onClick={handleModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <form className="new-branded-asset-modal__main">
        <div className="new-branded-asset-modal__main_brand">
          <p>Brand</p>
          <div>
            <img src={brand?.image?.url || placeholder} alt="brand" />
            <p>{brand?.name}</p>
          </div>
        </div>
        <Input
          name="name"
          label="Name"
          placeholder="Enter branded asset name"
          handleInput={(e) => setName(e.target.value)}
          value={name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          value={description}
          handleInput={(e) => setDescription(e.target.value)}
          placeholder="Enter branded asset description"
          showCounter
          maxLength={100}
          errorMessage={errors?.description}
        />
        <FileUpload
          setFiles={(files: File[]) => setFile(files?.[0])}
          description="Upload branded asset picture"
          errorMessage={errors?.file}
        />
        <div className="new-branded-asset-modal__main_actions">
          <button type="button" className="secondary-btn" disabled={loading} onClick={handleModal}>
            Cancel
          </button>
          <button type="button" className="primary-btn" disabled={loading} onClick={handleOwnableAssetCreate}>
            {loading ? <Spinner size="sm" /> : 'Create'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewBrandedAssetModal;
