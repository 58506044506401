import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SETTINGS } from '../../../global/routes';
import { handleError } from '../../../utils';
import { ApolloError } from '@apollo/client';
import { useErrorContext } from '../../../context/error-context';
import { AssetData, Dispatcher, UserData } from '../../../global/interfaces';
import { GET_ASSETS_QUERY } from '../../../global/gql/queries';
import { TRANSFER_ASSET_MUTATION } from '../../../global/gql/mutations';
import { useReneMutation, useReneQuery } from '../../../hooks';
import { SearchUserWithDropdown } from '../../search-user/search-user';
import Icon from '../../Icon/Icon';
import User from '../../user/user';
import Modal from '../modal';
import Spinner from '../../spinner/spinner';
import paperPlane from './images/asset-transfer.png';
import placeholder from '../../../global/images/avatar.webp';

import './asset-transfer-modal.scss';
interface AssetTransferProps {
  assetId: string;
  setCloseModal: Dispatcher<boolean>;
}

interface ConfirmTransferProps extends AssetTransferProps {
  setIsTransferConfirmed: Dispatcher<boolean>;
}

const ConfirmTransfer: React.FC<ConfirmTransferProps> = ({ assetId, setCloseModal, setIsTransferConfirmed }) => {
  const { setError } = useErrorContext();
  const [notEnoughFunds, setNotEnoughFunds] = useState('');
  const [selectedUser, setSelectedUser] = useState<UserData | undefined>();

  const { data, refetch } = useReneQuery<{ Assets: { items: AssetData[] } }>(GET_ASSETS_QUERY, {
    variables: {
      assetId,
    },
  });

  const [transferAsset, { loading: loadingTransfer }] = useReneMutation(TRANSFER_ASSET_MUTATION, {
    variables: {
      assetId: data?.Assets.items[0]?.assetId,
      receiverUserId: selectedUser?.userId,
    },
    onCompleted() {
      setIsTransferConfirmed(true);
      refetch();
    },
    onError(error: ApolloError) {
      if (error?.message === 'User has insufficient balance to make the transaction') {
        return setNotEnoughFunds('Insufficient funds to make a transfer');
      }
      handleError(error, setError);
    },
  });

  const handleModal = () => {
    setCloseModal((prev) => !prev);
  };
  return (
    <div className="asset-transfer__confirm">
      <div className="asset-transfer__confirm_header">
        <h2>Transfer Asset</h2>
        <button type="button" onClick={handleModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="asset-transfer__confirm_main">
        {!selectedUser ? (
          <SearchUserWithDropdown
            gameId={data?.Assets.items[0]?.gameId}
            title="Enter Username"
            description="Please input the name of the user that you want to transfer the Asset"
            setSelectedUser={setSelectedUser}
            excludeCurrent
          />
        ) : (
          <div className="asset-transfer__confirm_main_selected">
            <div>
              <p>Transfer to</p>
              <User user={selectedUser} />
            </div>
            <img src={data?.Assets.items[0]?.metadata?.image || placeholder} alt="asset" />
            <h3>{data?.Assets.items[0]?.metadata?.name}</h3>
            <p>Do you want to transfer the asset to this user?</p>
            <div>
              <button
                className="secondary-btn"
                type="button"
                onClick={() => setSelectedUser(undefined)}
                disabled={loadingTransfer}
              >
                Back
              </button>
              <button className="primary-btn" type="button" onClick={() => transferAsset()}>
                {loadingTransfer ? <Spinner size="md" /> : 'Confirm'}
              </button>
            </div>
            <Modal isOpen={!!notEnoughFunds}>
              <div className="asset-transfer__confirm_main_selected_modal">
                <div>
                  <button type="button" onClick={() => setNotEnoughFunds('')}>
                    <Icon name="close" size={24} />
                  </button>
                </div>
                <h2>Insufficient funds for asset transfer</h2>
                <p>
                  Please <Link to={`/${SETTINGS}/credit`}>topup</Link> up the credit and try again
                </p>
                <button className="primary-btn" type="button" onClick={() => setNotEnoughFunds('')}>
                  Close
                </button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </div>
  );
};

const TransferInitiated: React.FC<{ setCloseModal: Dispatcher<boolean> }> = ({ setCloseModal }) => {
  const handleCloseModal = () => {
    setCloseModal((prev) => !prev);
  };
  return (
    <div className="asset-transfer__transfer_initiated">
      <button type="button" onClick={handleCloseModal}>
        <Icon name="close" size={24} />
      </button>
      <img src={paperPlane} alt="paper plane" />
      <h2>The asset is transferring. You will receive a notification once the transfer is complete.</h2>
      <button type="button" className="primary-btn" onClick={handleCloseModal}>
        Close
      </button>
    </div>
  );
};

const AssetTransferModal: React.FC<AssetTransferProps> = ({ assetId, setCloseModal }) => {
  const [isTransferConfirmed, setIsTransferConfirmed] = useState(false);
  return (
    <div className="asset-transfer">
      {isTransferConfirmed ? (
        <TransferInitiated setCloseModal={setCloseModal} />
      ) : (
        <ConfirmTransfer
          setCloseModal={setCloseModal}
          assetId={assetId}
          setIsTransferConfirmed={setIsTransferConfirmed}
        />
      )}
    </div>
  );
};

export default AssetTransferModal;
