import { AdType, allowedImageTypes, allowedVideoTypes } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const videoValidations = {
  resolutionIab: commonValidations.requiredTrimmed(),
  file: (form: { [k: string]: any }, key: 'file') => {
    if (form?.[key] && form.adType === AdType.BANNER && !allowedImageTypes.includes(form?.[key]?.type as string))
      return 'You must provide a valid image (jpg, png, webp) file';

    if (form?.[key] && form.adType === AdType.VIDEO && !allowedVideoTypes.includes(form?.[key]?.type as string))
      return 'You must provide a valid video (mp4, webm) file';
  },
};
