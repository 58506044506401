import { useEffect, useRef, useState } from 'react';
import { Dispatcher, Event } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import './select.scss';

interface Props {
  value: string;
  label?: string;
  className?: string;
  btnClassName?: string;
  placeholder?: string;
  options: { [key: string]: any };
  changeHandler: Dispatcher<any>;
  showListValueFn?: (val: any) => any;
  showSelectedValueFn?: (val: any) => any;
  disabled?: boolean;
  children?: JSX.Element;
  errorMsg?: string;
  showError?: boolean;
}

const Select: React.FC<Props> = ({
  value,
  label,
  className,
  btnClassName,
  placeholder = 'Select value',
  options,
  changeHandler,
  showListValueFn,
  showSelectedValueFn,
  disabled,
  children,
  errorMsg,
  showError = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleSelectChange = (e: Event['Button']) => {
    if (!isOpen) return;
    setIsOpen(false);
    changeHandler(e.currentTarget.name);
  };

  useEffect(() => {
    if (isOpen && value) {
      const selectedButton = document.querySelector(`button[name="${Object.values(options).indexOf(value)}"]`);
      if (selectedButton) {
        selectedButton.scrollIntoView({
          block: 'nearest',
        });
      }
    }
  }, [isOpen, options, value]);

  useEffect(() => {
    const handleOuterClick = (event: { target: any }) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOuterClick);
    return () => document.removeEventListener('mousedown', handleOuterClick);
  }, [selectRef]);

  return (
    <div className={`select_container ${className || ''}`} ref={selectRef}>
      {label && <label>{label}</label>}
      <div className="select">
        <button
          type="button"
          className={`select__selected ${btnClassName || ''}`}
          onClick={() => setIsOpen((prev) => !prev)}
          disabled={disabled}
        >
          <div>
            {children}
            <p className={`${!value && 'placeholder'}`}>
              {showSelectedValueFn
                ? (value && showSelectedValueFn(options[value])) || placeholder
                : value || placeholder}
            </p>
          </div>
          <Icon name={isOpen ? 'chevron-up' : 'chevron-down'} size={16} />
        </button>
        {isOpen ? (
          <div className="select__options">
            {Object.keys(options).map((key) => {
              const selectedValue = typeof value === 'string' && value.toLowerCase();
              const optionToLowerCase = typeof options[key] === 'string' && options[key].toLowerCase();
              return (
                <button
                  className={`${selectedValue === optionToLowerCase && 'select__options_selected'}`}
                  type="button"
                  name={key}
                  key={key}
                  onClick={handleSelectChange}
                >
                  {showListValueFn ? showListValueFn(options[key]) : options[key]}
                </button>
              );
            })}
          </div>
        ) : null}
      </div>
      {showError && <p className="error-msg">{errorMsg}</p>}
    </div>
  );
};

export default Select;
