import { NavLink } from 'react-router-dom';
import { AssetData, Dispatcher } from '../../global/interfaces';
import { SETTINGS } from '../../global/routes';
import './transfer-button.scss';
// asset is being passed from asset-modal component
const TransferButton = ({
  asset,
  setIsAssetModalOpen,
  setIsAssetTransferModalOpen,
  isTransferAllowed,
}: {
  asset?: AssetData;
  setIsAssetModalOpen: Dispatcher<boolean>;
  setIsAssetTransferModalOpen: Dispatcher<boolean>;
  isTransferAllowed?: boolean;
}) => {
  if (!asset) return null;
  const isMainnet = asset.chain?.mainnet?.isActive;
  return (
    <>
      <div className="transfer-button">
        {!isTransferAllowed && isMainnet ? (
          <div>
            You can't transfer due to insufficient funds. You can top up the credits{' '}
            <NavLink to={`/${SETTINGS}/credit`} className={({ isActive }) => (isActive ? 'active-page' : '')}>
              here
            </NavLink>
          </div>
        ) : (
          ''
        )}
        <button
          type="button"
          className="primary-btn"
          onClick={() => {
            setIsAssetModalOpen(false);
            setIsAssetTransferModalOpen(true);
          }}
          disabled={!!asset?.transactions?.items?.length || !isTransferAllowed}
        >
          {asset?.transactions?.items?.length ? 'Transfer pending' : 'Transfer'}
        </button>
      </div>
    </>
  );
};

export default TransferButton;
