import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_BRANDED_ASSETS_QUERY, GET_OWNABLE_ASSETS_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { OwnableAssetData, Dispatcher, UserData, BrandedAssetData } from '../../../global/interfaces';
import { AssetTypeId, UserRole } from '../../../global/consts';
import { useReneQuery } from '../../../hooks';
import { isRoleAllowed } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import FilesTable from '../../../components/files-table/files-table';
import GameEngineFilesModal from '../../../components/modal/game-engine-files-modal/game-engine-files-modal';

import './game-engine-files.scss';

const NoEngineFilesUploaded = () => {
  return (
    <div className="game-engine-files__main_empty">
      <Icon name="bucket" />
      <p>No data</p>
    </div>
  );
};

const GameEngineFiles = ({
  type,
  disabled,
  setOpenMobileMenu,
}: {
  type: AssetTypeId;
  disabled?: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
}) => {
  const params = useParams();

  const query = {
    ownableAssetId: GET_OWNABLE_ASSETS_QUERY,
    brandedAssetId: GET_BRANDED_ASSETS_QUERY,
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data, refetch } = useReneQuery<
    | {
        OwnableAssets: { items: OwnableAssetData[] };
      }
    | { BrandedAssets: { items: BrandedAssetData[] } }
  >(query[type], {
    variables: { [type]: params.assetId },
  });

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const isUserAllowedToUpsert = isRoleAllowed(user?.User.role as UserRole, [UserRole.DEVELOPER]) && !disabled;
  const gameEngineFiles =
    (data as { OwnableAssets: { items: OwnableAssetData[] } })?.OwnableAssets?.items[0]?.gameEngineFiles ||
    (data as { BrandedAssets: { items: BrandedAssetData[] } })?.BrandedAssets?.items[0]?.gameEngineFiles;

  const handleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <div className="game-engine-files">
      <div className="game-engine-files__heading">
        <div>
          <button onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
          <h1 className="rainbow-btn-text">GAME ENGINE FILES</h1>
        </div>
        <div>
          <button className="secondary-btn">Action</button>
          {isUserAllowedToUpsert && (
            <button className="secondary-btn" onClick={handleModal}>
              Upload
            </button>
          )}
        </div>
      </div>
      <div className="game-engine-files__main">
        {gameEngineFiles?.length ? <FilesTable files={gameEngineFiles} /> : <NoEngineFilesUploaded />}
      </div>
      <Modal isOpen={isModalOpen}>
        <GameEngineFilesModal
          setCloseModal={handleModal}
          id={params.assetId as string}
          type={type}
          refetch={refetch}
          uploadedFiles={gameEngineFiles}
        />
      </Modal>
    </div>
  );
};

export default GameEngineFiles;
