import { useState } from 'react';
import { Refetch, UserData } from '../../../global/interfaces';
import { BrowserProvider, Contract } from 'ethers';
import { useWeb3ModalProvider } from '@web3modal/ethers/react';
import { PRIVACY_POLICY } from '../../../global/routes';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import './staking-modal.scss';

const RENE_KEYS_STAKING_ABI = [
  'function stake(uint256 tokenId) external',
  'function unstake(uint256 tokenId) external',
  'function nft() public view returns (string memory)',
  'function tokenOwner(uint256) public view returns (address)',
  'event Staked(address indexed user, uint256 tokenId, uint256 timestamp)',
  'event Unstaked(address indexed user, uint256 tokenId, uint256 timestamp)',
];
const RENE_KEYS_ABI = [
  'function pause() public',
  'function unpause() public',
  'function _baseURI() internal view returns (string memory)',
  'function baseURI() external view returns (string memory)',
  'function safeMint(address to, bytes32[] calldata _merkleProof) public',
  'function setBaseURI(string memory _tokenURI) external',
  'function setMintStatus(uint8 _status) external',
  'function updateMerkleRoot(bytes32 _merkleRoot) external',
  'function getMerkleRoot() public view returns (bytes32)',
  'function tokenURI(uint256 tokenId) public view  returns (string memory)',
  'function supportsInterface(bytes4 interfaceId) public view returns (bool)',
  'function balanceOf(address owner) public view returns (uint256)',
  'function ownerOf(uint256 tokenId) public view returns (address)',
  'function name() public view returns (string memory)',
  'function symbol() public view returns (string memory)',
  'function burn(uint256 tokenId) public',
  'function approve(address to, uint256 tokenId) public',
  'function getApproved(uint256 tokenId) public view returns (address)',
  'function setApprovalForAll(address operator, bool approved) public',
  'function isApprovedForAll(address owner, address operator) public view returns (bool)',
  'function transferFrom(address from, address to, uint256 tokenId) public',
  'function safeTransferFrom(address from, address to, uint256 tokenId) public',
  'function safeTransferFrom(address from, address to, uint256 tokenId, bytes memory data) public',
];

const stakingContractAddress = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS as string;
const reneKeysContractAddress = process.env.REACT_APP_RENE_KEYS_CONTRACT_ADDRESS as string;

const stakingOptions = {
  proceed: {
    description: (
      <p>
        Once staked, your Founder's Key NFT will be locked and cannot be transferred or sold until you stop staking.{' '}
        <br /> <br /> You can unstake your Founder's Key at anytime, but be aware that upon unstaking, some Points
        you've earned may not have a multiplier applied if the information regarding that Quest was registered by
        ReneVerse after you unstaked the NFT. <br />
        <br /> Please ensure that you've connected the correct wallet and review our{' '}
        <a href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
          Terms and Conditions
        </a>{' '}
        before proceeding.
      </p>
    ),
    btnTitle: 'Confirm',
  },
  stop: {
    description: (
      <p>
        You can only stake one Founder's Key at a time. A Founder's Key can be stacked with a Point Booster [this is
        referring to the Referral code used specifically for the Inferno Labs campaign] for an increased Point
        Multiplier. <br /> <br /> A multiplier is active as long as your NFT is staked, and all Points earned with a
        multiplier will be properly accounted for up until the point the NFT is unstaked. <br />
        <br /> <b>Disclaimer:</b> If you unstake your NFT before ReneVerse receives information about a completed Quest
        from other services such as X, you may not receive the multiplier for those Points.
      </p>
    ),
    btnTitle: 'Stop Staking',
  },
};

const StakingModal = ({
  nftId,
  action,
  refetch,
  closeModal,
}: {
  nftId: number;
  action: 'proceed' | 'stop' | '';
  closeModal: () => void;
  refetch: Refetch<{
    User: UserData;
  }>;
}) => {
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);
  if (!action) return null;
  const handleStake = async () => {
    if (!walletProvider) return closeModal();
    try {
      setLoading(true);
      const ethersProvider = new BrowserProvider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const stakingContract = new Contract(stakingContractAddress, RENE_KEYS_STAKING_ABI, signer);
      const reneKeysContract = new Contract(reneKeysContractAddress, RENE_KEYS_ABI, signer);
      if (action === 'proceed') {
        await reneKeysContract.approve(stakingContract, nftId);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await stakingContract.stake(nftId);
      }
      if (action === 'stop') await stakingContract.unstake(nftId);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await refetch();
      closeModal();
    } catch (error) {
      if (error instanceof Error) {
        if (error.message.includes('user rejected action')) {
          closeModal();
        }
      } else {
        console.log('An unknown error occurred', error);
      }
      console.warn('Failed to stake/unstake:', error);
    }
  };

  return (
    <div className="staking-modal">
      <div className="staking-modal__heading">
        <h2>
          <span>{action === 'proceed' ? 'Proceed with' : 'Stop'}</span> Staking this Founder's Key?
        </h2>
        <button type="button" onClick={closeModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="staking-modal__main">{stakingOptions[action].description}</div>
      <div className="staking-modal__actions">
        <button className="secondary-btn" onClick={closeModal}>
          Cancel
        </button>
        <button className="primary-btn" onClick={handleStake}>
          {loading ? <Spinner size="sm" /> : stakingOptions[action].btnTitle}
        </button>
      </div>
    </div>
  );
};

export default StakingModal;
