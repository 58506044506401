import './template-section.scss';

interface Props {
  title: string;
  children: React.ReactElement | React.ReactElement[] | never[];
}

const TemplateSection: React.FC<Props> = ({ title, children }) => {
  return (
    <div className="template_section">
      <div className="template_section__heading">
        <h3>{title}</h3>
      </div>
      <div className="template_section__main">{children}</div>
    </div>
  );
};

export default TemplateSection;
