import avatar from '../../../global/images/avatar.webp';

import './org-asset-card.scss';

interface Props {
  title: string;
  image: string;
  children?: React.ReactNode | React.ReactNode[];
}

const OrgAssetCard: React.FC<Props> = ({ title, image, children }) => {
  return (
    <div className="org-asset-card">
      <img src={image || avatar} alt="game poster" />
      <div className="org-asset-card__info">
        <p>{title}</p>
      </div>
      {children}
    </div>
  );
};

export default OrgAssetCard;
